@import '../../assets/styles/admin/variables.scss';

.container {
  height: 100%;
  width: 100%;
  padding: 4rem 0;
}

.products {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 1rem;
  max-width: 1400px;
}

.productContainer {
  flex: 0 0 (100% / 5);
  box-sizing: border-box;
  padding: 0 2rem 4rem;
  position: relative;
}

.product {
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
  width: 240px;
  height: 398px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-out, transform 0.2s;

  &:hover {
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
  }
}

.thumbnailContainer {
  position: relative;
  background: #fbfbfb;
  height: 320px;
  overflow: hidden;

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-out;
  }
}

.product:hover .thumbnail {
  transform: scale(1.25);
}

.caption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem;
}

.name {
  font-weight: 500;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 208px;
}

.price {
  font-weight: 400;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}

.spinner {
  position: fixed;
  // top: 33%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  margin: auto;
}
.spinnerbottom {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  margin-top: -50px;
}

.headerRight {
  display: flex;

  .button {
    padding: 0 2rem;
    margin-left: 1.6rem;
    height: 32px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    border: 1px solid $purple;
    border-radius: 4px;
    transition: opacity 0.12s;
    background-color: #fff;
    color: $purple;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}
