.container {
  height: 50px;
  margin: 10px 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999998;

  button {
    background-color: #4040d9;
    color: #f8fafe;
    fill: #f8fafe;
    padding: 1rem;
    border-radius: 999rem;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .needHelp {
    font-weight: 700;
    padding-left: 8px;
    font-size: 16px;
  }
}
