@import '../../../assets/styles/admin/variables.scss';

.PageActionLinks {
  margin-bottom: 6rem;

  p {
    color: $text-color-light;
    font-size: 1.4rem;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  a.confirm {
    color: $danger-color;
    text-decoration: underline;
  }
}
