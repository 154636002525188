@import '../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .error {
    line-height: 0.75em;

    span {
      display: block;
      font-size: 1.2rem;
      background-color: $danger-color;
      color: white;
      padding: 0.8rem;
      border: 1px solid $danger-color;
      border-radius: 2px;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }

    svg {
      width: 16px;
      height: 8px;
      fill: $danger-color;
      margin-left: 8px;
      transform: translateY(1px);
    }
  }

  .success {
    @extend .error;

    span {
      background-color: $success-color;
      border: 1px solid $success-color;
    }

    svg {
      fill: $success-color;
    }
  }
}
