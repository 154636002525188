.close {
    width: 24px;
    height: 24px;
    object-fit: cover;
    display: inline-flex;
    position: fixed;
    top: 0;
    right: 0;
    margin: 32px;
    z-index: 2;
    cursor: pointer;
  }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    -webkit-backdrop-filter: blur(64px);
    backdrop-filter: blur(64px);
    transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    overflow-y: scroll;
    scrollbar-width: none;
    pointer-events: none;
    user-select: none;
}

.overlay::-webkit-scrollbar {
    width: 0;
}

.overlay.active {
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
}

.overlay .unstack-media picture,
.overlay .unstack-media img {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: contain;
    margin: 0 auto;
}

.overlay .swiper-container {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.overlay .swiper-slide {
    text-align: center;
    font-size: 18px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.overlay .swiper-slide img {
    display: block;
    object-fit: cover;
    cursor: pointer;
}

  .overlay .swiper-pagination-bullet {
    background: #FFF;
  }

  /* Image fix */

  .overlay picture {
    padding-bottom: 0 !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 16px;
    font-weight: 600;
    color: #00000080;
    transition: color 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .swiper-button-next:hover::after,
  .swiper-button-prev:hover::after {
    color: #000;
  }
  /* .swiper-slide-active{
    width: inherit !important;
  } */

  .swiperSecondary{
    width: 1000px;
    margin-right: 10px;
  }

  .swiperSecondary .swiper-wrapper{
    flex-direction: column;
    gap: 8px;
  }
  .swiperSecondary .swiper-slide{
    height: initial;
    width: 100% !important;
  } 

  .swiperPrimary {
    width: 100%;
  }
  
  .swiperSecondary {
    box-sizing: border-box;
  }
  
  .swiperSecondary .swiper-wrapper {
    display: flex;
    gap: 8px;
    border-radius: 2px;
    overflow: hidden;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .swiperSecondary .swiper-slide {
    cursor: pointer;
    width: 100% !important;
    display: block;
    flex-shrink: 1;
    transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    border-radius: 2px;
    overflow: hidden;
  }
  
  .swiper-slide picture {
    cursor: pointer;
    padding-bottom: 0 !important;
  }
  
  .swiperSecondary .swiper-slide-thumb-active {
    opacity: .5;
  }
  
  .swiper-container {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background-size: cover;
    background-position: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
  }
  
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 16px;
    font-weight: 600;
    color: #00000080;
    transition: color 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .swiper-button-next:hover::after,
  .swiper-button-prev:hover::after {
    color: #000;
  }

  .gallery-container{
    width: 100%;
    grid-template-columns: 1fr;
    display: grid;
    gap: 32px;
    height: fit-content;
  }
  .gallery-container > .swiperSecondary {
    height: 100%;
    overflow-y: auto;
  }

  @media only screen and (min-width: 768px) {
    .swiperSecondary .swiper-wrapper {
      position: absolute;
    }
  }

  @media only screen and (max-width: 767px) {
    .gallery-container{
      display: flex;
      flex-flow: row wrap;
    }
    .swiperSecondary{
      order: 1
    }
    .swiperSecondary .swiper-wrapper{
      flex-direction: row; 
    }
  }

  .swiper-slide.unstack-media img {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  .swiper-slide.unstack-media img.visible {
    display: block;
  }
  .swiper-slide.unstack-media img.opaque {
    opacity: 1;
    position: inherit;
    top: inherit;
  }

  .swiperOverlay .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }