@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.createFirstPageContent {
  color: rgba(20, 33, 61, 0.8);
  display: flex;
  padding: 20px 48px;
  position: relative;

  justify-content: space-between;

  &.commerce {
    display: block;
    padding: 0;
  }

  @include respond-below(xl) {
    flex-wrap: wrap-reverse;
    & p {
      width: 60%;
      min-width: 390px;
    }
  }
  & p {
    margin-bottom: 8px;
  }

  .navLinkBackground {
    position: absolute;
    right: 50px;
    top: 30px;
    z-index: 0;
    @include respond-below(xl) {
      left: 110px;
      top: 30px;
    }
  }

  .navContainer {
    display: flex;
    flex-flow: column nowrap;
    min-width: 220px;
    position: relative;
    z-index: 2;

    margin-top: 10px;
    margin-right: 2px;
    margin-left: 80px;

    padding-bottom: 20px;

    @include respond-below(xl) {
      margin-left: 62px;
    }

    .navArrowIcon {
      position: absolute;
      left: -60px;
      top: 50px;

      @include respond-below(xl) {
        display: none;
      }
    }
  }

  .commerceTable {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    justify-content: center;

    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 4px;
      border: var(--Space-0, 1px) solid var(--Border-Light, #dee4f3);
      background: var(--Background-Depth-0, #fff);
      padding: 27px 0px;

      color: var(--Text-Default, rgba(20, 33, 61, 0.8));
      text-align: center;
      /* Small/Normal */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */

      .small {
        font-size: 12px;
        line-height: normal;
      }

      svg {
        height: 40px;
        margin-bottom: 12px;
      }
    }
  }
}
.logoContainer,
.clickable {
  cursor: pointer;
  position: relative;
}

.check {
  position: absolute;
  right: 8px;
  bottom: -18px;
}

.selectEcommerceText {
  padding-bottom: 30px;
}

.activeIntegration {
  border-radius: 4px !important;
  border: var(--Space-0, 3px) solid var(--Commerce, #2bcc7e) !important;
  background: var(--Background-Depth-0, #fff) !important;
}

.title {
  display: flex;
  align-items: center;

  svg {
    color: #647fb4;
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  $padding-left: 18px;
  $border-width: 2px;
  margin-left: -$nav-gutter;
  padding-left: $nav-gutter - $border-width;
  border-left: solid $border-width rgba(0, 0, 0, 0);
  padding-top: 8px;
  padding-bottom: 8px;

  a {
    color: #090b45;
    font-size: 1.4rem;
    opacity: 0.6;
  }
}
