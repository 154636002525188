@import '../../../../assets/styles/admin/variables.scss';

.mediaDrawerContainer {
  display: flex;
  margin: 0;
}
.contentContainer {
  padding-left: 200px;
  width: 100%;
  display: flex;

  &.searchEngine {
    display: inline;
  }

  .mediaSearchInput {
    position: absolute;
  }
}

.MediaUploader {
  margin-left: auto !important;
  margin-bottom: 0 !important;
  margin-right: 3rem;
  width: 20px;
  height: 20px;
  transition: all 0.14159s;

  &:hover {
    opacity: 0.8;
  }

  form {
    display: none;
  }
}

.navigation {
  width: 200px;
  height: 100%;
  background: #090b45;
  cursor: pointer;
  position: absolute;
  top: 0;
  label {
    margin: 1.6rem 1.5rem 1.3rem;
    font-weight: 500;
  }

  .option {
    font-size: 14px;
    display: flex;
    align-items: center;
    min-height: 40px;
    border-left: 2px solid rgba(255, 255, 255, 0);
    opacity: 0.6;
    transition: all 0.12s;
    span {
      margin-left: 1rem;
    }
    svg {
      margin-left: 1.5rem;
    }
    &:hover {
      opacity: 1;
    }
  }
  cursor: pointer;
  .active {
    border-left: 2px solid $primary-color;
    opacity: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  }
}
