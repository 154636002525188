@import '../../assets/styles/admin/variables.scss';

.ReactTable.ReactTableList {
  .rt-th {
    &:last-child {
      border-left: none;
    }
    text-transform: capitalize;
  }

  .rt-tbody {
    .rt-tr,
    .rt-td {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      align-items: center;
    }

    .rt-td {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-left: none;

      font-size: 1.4rem;
      // color: $text-color;
    }
  }

  .muted {
    color: $dark-gray-blue;
  }

  .charge {
    color: $money-green;
  }
  .refund {
    color: $danger-color;
  }
}
