@import '../../../../assets/styles/admin/variables.scss';

.container {
  padding: 1.5rem 2rem 2rem;
  border-radius: 2px 2px 0 0;
  background-color: $dark-background-color;
}

.label {
  font-size: 1.3rem;
  color: white;
  opacity: 0.8;
}

.value {
  margin-top: 0.5rem;
  font-size: 2.4rem;
  color: white;
}

.chart {
  display: block;
  width: 100%;
}
