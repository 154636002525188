@import '../../../assets/styles/admin/variables.scss';

.filtersContainer {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem;

  .filter {
    font-size: 14px;

    .filterIcon {
      margin-right: 0.8rem;
      display: inline;
      vertical-align: -0.05rem;
    }
  }

  .searchFieldContainer {
    display: flex;
    align-items: center;
  }
}

.addButton {
  max-width: 150px;
  margin: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 0;
  padding: 1.5rem 2rem;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;

  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0.02rem;
  text-align: center;
  line-height: 3.8rem;

  background: $purple;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  transition: opacity 0.12s;

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.75;
  }

  svg {
    margin-right: 1rem;
    color: #ffffff;
  }
}

.columnMarkButton {
  text-align: center;
  padding: 0 1rem;
  border: 1px solid $purple;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: min-content;
  margin: auto;
  color: $purple;
  font-size: 12px;
  cursor: pointer;
}

.completedText {
  color: $text-color;
  font-size: 14px;
  text-decoration: line-through;
}

.removeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  width: fit-content;
  margin: auto;
  color: $danger-color;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
  color: $blue !important;
}

.warningText {
  color: $orange;
}

.capitalize {
  text-transform: capitalize;
}

.completed {
  text-decoration: line-through;
  color: $text-color-light;
}

.edit {
  color: $blue;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.fieldsetOptionalStyles {
  margin-bottom: 1rem !important;
}

.colorDeleteActionText {
  margin-top: 1rem !important;
  color: $text-color-light !important;
  font-size: 1.4rem;
  span {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  a {
    color: $danger-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.showDetails {
  color: $blue;
  cursor: pointer;
  float: right;
  transition: opacity 200ms;

  &:hover {
    opacity: 0.7;
  }
}

.details {
  white-space: normal !important;
  text-overflow: unset !important;
}
