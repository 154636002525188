@import '../../../../assets/styles/admin/variables.scss';

.fontsStylesHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 23px !important;
  svg {
    color: $blue;
    margin: 0 10px 0 auto;
    cursor: pointer;
    // transition: all 0.14159s;
    //
    // &:hover {
    //   opacity: 0.8;
    // }
  }
}

.fontsContainer {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 3rem;
  }

  .item {
    background-color: #fff;
    margin: 0 20px 20px 0;
    border-radius: 2px;
    display: flex;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    width: 200px;
    height: 160px;
    align-items: center;
    justify-content: center;

    span {
      font-size: 4rem;
      letter-spacing: 0.2rem;
      transition: all 0.14159s;

      &:hover {
        opacity: 0.6;
        cursor: pointer;
        transition: all 0.14159s;
      }
    }

    div {
      color: $dark-gray-blue;
      text-align: center;
      font-size: 1.4rem;
      margin-top: 1rem;
      cursor: default;
    }

    .trash {
      width: 100%;
      text-align: right;
      height: 20px;
      margin-top: -2rem;
      svg {
        margin-right: 2rem;
        cursor: pointer;
      }
    }
    .default {
      width: 100%;
      text-align: left;
      margin-top: -2rem;
      span {
        margin-left: 1.2rem;
        font-size: 10px;
        background-color: $light-gray-blue;
        color: $dark-gray-blue;
        letter-spacing: 0;
        padding: 4px 8px;
        border-radius: 20px;
        text-transform: capitalize;
        cursor: default;
        opacity: 1;
      }
    }
    .label {
      text-transform: capitalize;
    }
  }
}
