.productImage {
  display: flex;
  background: #f7f7f9;
  box-shadow: 0px 0px 2px 0px #b8c8e5 inset;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1.2;

  .productImageIcon {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
}
