@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .addPage {
    $icon-size: 32px;
    float: right;
    margin-right: $table-gutter - $icon-size / 2 + 17 / 2;

    svg {
      height: $icon-size;
      vertical-align: middle;
      width: auto;
    }

    &:not([disabled]) svg circle:global(.background-fill) {
      fill: #fff;
    }
  }

  article.PageListZeroState {
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    padding: 4rem;

    > * {
      margin: 0;
    }
    > * + * {
      margin-top: 1rem !important;
    }

    h2 {
      font-weight: 500;
      font-size: 2.4rem;
      color: $header-color-gray;
    }

    p {
      font-weight: 400;
      font-size: 1.6rem;
      max-width: 32em;
      margin-left: auto;
      margin-right: auto;
      color: $text-color-light;
    }
  }
}

.flexContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.blogsHeader,
.pagesHeader {
  display: flex;
  justify-content: space-between;

  .filter {
    font-size: 14px;

    .filterIcon {
      margin-right: 0.8rem;
      display: inline;
      vertical-align: -0.05rem;
    }
  }

  .blogNavOptions {
    font-size: 1.2rem;
    padding-top: 4px;
    a {
      cursor: pointer;
      display: inline-block;
      position: relative;
      &:not(:last-child)::after {
        content: '•';
        color: $dark-gray-blue;
        margin: 0 5px;
        cursor: default;
      }
    }
  }
  .searchFieldContainer {
    display: flex;
    align-items: center;

    .newContactButton {
      display: flex;
      align-items: center;
      height: 0;
      padding: 1.5rem 2rem;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      box-sizing: border-box;

      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.02rem;
      text-align: center;
      line-height: 3.8rem;

      background: $purple;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      transition: opacity 0.12s;

      &:hover,
      &:focus {
        outline: 0;
        opacity: 0.75;
      }

      svg {
        color: #ffffff;
        margin-right: 1rem;
      }
    }

    .existingTemplatesButton {
      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      background-color: $dark-gray-blue-faded;
    }
  }
  svg {
    margin-top: 0 !important;
  }
}

.options {
  @include unstyled-button;
  box-sizing: content-box;
  height: 20px;
  width: 20px;
  flex: 0 0 auto;
  padding: 0;
  margin: 0 !important;
  vertical-align: middle;
  color: $gray-blue;

  box-shadow: none;
  transition: opacity 0.14159s;

  &,
  &:focus,
  &:active {
    opacity: 1;
  }

  svg {
    height: 20px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.initials {
  display: inline-block;
  line-height: 2rem;
  height: 2rem;
  width: 2rem;
  vertical-align: middle;
  margin: 0 1rem 0 0;
  border-radius: 1rem;
  background: $purple;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  font-size: 1.2rem;
  font-weight: 500;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
  text-transform: uppercase;
}
.editStatus {
  height: 8px;
  width: 8px;
  display: inline-block;
  background-color: $green;
  border-radius: 1rem;
  position: absolute;
  bottom: 25%;
  left: 14%;
  border: 1px solid #fff;
}

.label {
  display: inline-block;
  margin-left: 1rem;
  padding: 0.2rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  background-color: $orange;
  vertical-align: 1px;
  border-radius: 2px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}
