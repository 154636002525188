.container {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;

  &,
  * {
    box-sizing: border-box;
  }

  > * {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
