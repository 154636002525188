@import '../../assets/styles/admin/variables.scss';

:global(.admin) .Elements {
  p {
    font-size: 1.5rem;
    color: $text-color-light;
    margin: 0;
  }

  p span {
    font-size: 1.2rem;
    font-weight: 300;
    margin-left: 0.5rem;
  }
}
