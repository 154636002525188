@import '../../../assets/styles/admin/variables.scss';

.progressBarContainer {
  height: 4px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 24px;
  background-color: #dce4f2;
}

.progressBar {
  background-color: #2ec5b6;
  border-radius: 5px;
  height: inherit;
  transition: width 0.1s ease-in-out;
}
