@import '../../../../../../../assets/styles/admin/variables.scss';

// Jasmine
// ----------------------------------------------------------------------------

.jasmine {
  position: absolute !important;
  top: 4rem;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(41, 50, 65, 0.22);
  pointer-events: none;
  transition: opacity 0.05s;
  opacity: 0;
}

:global(.section:hover) .jasmine {
  opacity: 1;
}

:global(.dark-bg) .jasmine {
  color: rgba(255, 255, 255, 0.6);
}

// Overlay
// ----------------------------------------------------------------------------

.overlay {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  box-shadow: 0 0 0 2px inset $primary-color;
  pointer-events: none;

  &.enter {
    opacity: 0;
  }
  &.enterActive {
    opacity: 1;
    transition: opacity 0.14159s;
  }
  &.exit {
    opacity: 1;
  }
  &.exitActive {
    opacity: 0;
    transition: 0.14159s;
  }
}

// Hit zone
// ----------------------------------------------------------------------------

.hitZone {
  // background: #f0f;

  position: absolute !important;
  z-index: 1;
  // top: -($toolbar-height / 2) - 20px;
  top: -15px;
  left: 0;
  width: 100%;
  // height: 100px + ($toolbar-height / 2);
  height: 100%;
  // padding-top: 20px;

  display: flex;
  justify-content: center;
  // align-items: center;
}

// Toolbar container
// ----------------------------------------------------------------------------

.toolbarContainer {
  // background: #F088;
  // padding-bottom: 10px;

  // position: absolute !important;
  position: relative;
  // top: -$toolbar-height - 10px;
  // left: 50%;
  // transform: translate(-50%, 0px);

  z-index: 4;
  // min-width: 600px;

  :global(.toolbar) {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.12);
  }

  &.extendHeight {
    height: $toolbar-height * 2 !important;
  }

  &.opacity {
    opacity: 0.4;
    z-index: 1;
  }

  &.enter {
    height: 0;
  }
  &.enterActive {
    height: 40px;
    transition: all 0.1s;
  }
  &.enterDone {
    height: 40px;
  }
  &.exit {
    height: 40px;
  }
  &.exitActive {
    opacity: 0;
    height: 0;
    transition: all 0.1s;
  }

  .move {
    display: flex;
    height: auto;
    flex-direction: column;
    .moveUp {
      transform: rotate(90deg);
      margin-top: -2px;
      cursor: pointer;
    }

    .moveDown {
      transform: rotate(270deg);
      cursor: pointer;
    }

    .isDisabled {
      cursor: not-allowed;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .absoluteRight {
    position: absolute;
    height: 100%;
    right: 0;
    padding: 0 !important;

    & > * {
      padding: 0 15px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: thin solid hsla(0, 0%, 100%, 0.14159);
      margin-right: 0;
    }

    button {
      width: 40px;
      padding: 10px !important;
    }
  }

  .absoluteLeft {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    border-right: none !important;
    padding: 0 !important;
    .sectionLabel {
      color: #fff !important;
      top: 9px;
      border-radius: 15px !important;
      border: 1px solid rgba(9, 11, 69, 0.5);
      font-size: 1rem;
      border: 2px solid rgba(255, 255, 255, 0.2);
      font-weight: 500;
      height: fit-content;
      padding: 3px 10px !important;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
      text-shadow: 0 1px 3px rbga(0, 0, 0, 0.2);
      cursor: pointer;
      margin: 0 0 0 15px;
    }
    .deprecated {
      background-color: rgb(113, 118, 222);
      color: white;
      margin-left: 8px;
      padding: 2px 8px;
      border-radius: 2px;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
    }
    div {
      color: rgba(255, 255, 255, 0.9);
      font-size: 11px;
      margin-left: 1.5rem;
      height: 40px;
      line-height: 44px;
      min-width: 60px;
      position: relative;
      cursor: pointer;

      svg {
        padding-left: 6px;
      }

      &::after {
        content: ' ';
        position: absolute;
        top: 93%;
        height: 1px;
        right: 6px;
        bottom: 0;
        left: -8px;
        border-left: solid 2px transparent;
        border-right: solid 2px transparent;
        border-bottom: solid 3px transparent;
        transition: border 0.14159s;
      }

      &.activeSlide::after {
        border-bottom-color: $primary-color;
      }

      &:last-child {
        min-width: 0;
        margin-right: 0;
        svg {
          margin: 0;
        }
      }
    }
    .newSlide {
      color: $blue;
      margin-left: 2rem;
      cursor: pointer;
      min-width: none;
    }
  }
  .TextInput {
    max-width: 40px;
    text-align: center;
  }

  &.abTestInProgress {
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    .container {
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
      width: 550px;
    }

    .progressBar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $success-color;
    }

    .label {
      position: relative;
    }

    .conversionRate {
      margin-left: 0.5rem;
      font-size: 0.9em;
      color: rgba(255, 255, 255, 0.8);
    }

    .winnerIcon {
      margin-left: 0.5rem;
    }
  }
}
.deprecatedToolTip {
  padding: 8px;
  width: 193px;
  height: 80px;
  text-align: left !important;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
}

.trayIcon {
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.14159s;
  cursor: pointer;
  width: 40px;
  padding: 9px !important;
}
.open {
  background-color: #8082b366;
}

.visibilityContainer {
  height: 20px;
}

.visibilityDrawer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  background-color: white;
  width: 191px;
  border-radius: 4px;
  padding: 8px, 0px, 8px, 0px;
  box-shadow: 0px 4px 12px 0px #0000001a;
  box-shadow: 0px 0px 32px 0px #0000000d;

  .menuHeading {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px 5px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #14213d;
  }

  .menuItem {
    display: grid;
    grid-template-columns: 20px 20px 100px;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px 0px 12px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #ffffff;
    font-size: 14px;
    line-height: 17px;
    color: #14213dcc;
    font-weight: 400;
    &:hover {
      background-color: #eff3fb;
    }
  }

  .itemActive {
    color: #14213d;
    font-weight: 500;
  }
}

.componentLabel {
  margin: 0 0 0 35px !important;
}
