@import '../../assets/styles/admin/variables.scss';

.main {
  padding-top: 30vh;
  svg {
    display: block;
    margin: 0 auto 30px;
  }
  .message {
    text-align: center;
    > div {
      margin: 0 auto;
    }
  }
}
