@import '../../../../assets/styles/admin/variables.scss';

.CustomCode {
  div.add {
    position: absolute;
    right: 100%;
    bottom: 0;
  }

  .listItem {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 42px;
  }
  .listItem + .listItem {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .name {
    flex: 1 1 auto;
  }

  .remove {
    color: $danger-color;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 2rem;
  }

  .edit {
    color: $primary-color;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 2rem;
  }

  label {
    display: inline;
    font-size: 0.9em;
    font-weight: normal;
    color: #aaa;
    margin: auto;

    .statusIndicator {
      $size: 7px;
      display: inline-block;
      height: $size;
      width: $size;
      vertical-align: 2px;
      margin-right: 4px;
      border-radius: 999999px;
      background-color: $green;
    }
  }
}
