.editorIFrame {
  box-shadow: 0px 0px 32px rgb(0 0 0 / 5%), 0px 4px 12px rgb(0 0 0 / 10%);
  border: none;
  margin-bottom: 48;
  transition: width ease-out 300ms, height ease-out 300ms, margin-top ease-out 300ms;
  display: block;
}

.noHeader {
  box-shadow: none;
}
