@import '../../../assets/styles/admin/variables.scss';

.container {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 2rem 3rem;

  display: flex;

  .statements {
    color: $dark-gray-blue;
    font-size: 15px;

    ul {
      padding-top: 20px;
      li {
        display: flex;
        align-items: center;
        padding: 3px 0;

        svg {
          margin-right: 1rem;
        }
      }
    }

    .download {
      background-color: $light-background-color;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(0, 0, 0, 0.05);
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem 4rem;
      margin-top: 2rem;
      margin-left: 3rem;

      svg {
        margin-bottom: 1rem;
      }

      p {
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
        color: $dark-gray-blue;
      }
    }
  }

  div.unstack-media {
    flex: 1 1 60%;
  }
}
