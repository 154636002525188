@import '../../../../assets/styles/admin/variables.scss';

.bgColorContent {
  background-color: $toolbar-tray-background;
  padding: 0 8px !important;
}
.bgColorContentHide {
  background-color: $toolbar-tray-background;
  padding: 0 !important;
}

.inputGroup {
  height: 40px;
}

.noPadding {
  padding: 0 !important;
}
