@import '../assets/styles/admin/variables.scss';

.spinnerContainer {
  position: fixed;
  top: 33%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  :global(.spark-spinner) {
    margin-left: auto;
    margin-right: auto;
  }
}

.canvas:global(-enter),
.canvas:global(-appear) {
  opacity: 0;
}

.canvas:global(-enter-active),
.canvas:global(-appear-active) {
  opacity: 1;
  transition: opacity 0.5s;
}

.canvas:global(-exit),
.canvas:global(-exit-active) {
  opacity: 0;
  transition: none;
}

.TableSelector {
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(41, 50, 65, 0.5);

  article {
    width: 450px;
    border-radius: 3px;
    text-align: center;
    background-color: #fff;
    padding: 2rem;
  }
}

.Errors {
  position: fixed;
  z-index: 199;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -100%);
  height: 40px;
  width: 100%;
  max-width: 550px;
  padding: 0 1.5rem;
  background: $danger-color;
  border-radius: 4px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  pointer-events: none;

  display: flex;
  align-items: center;

  opacity: 0;
  transition: opacity 0.25s, transform 0.25s;

  &.show {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.mediaFormatter {
  display: flex;
  align-items: center;

  img {
    height: 22px;
    width: auto;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  small {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
