@import '../../../../assets/styles/admin/variables.scss';

.sourceLink {
  position: absolute;
  right: 6rem;
  top: 2rem;
  font-size: 12px;
  color: $gray-blue;
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.Unsplash {
  display: flex;
  flex-wrap: wrap;

  &.addPadding {
    padding-right: 240px;
  }

  .content {
    border-radius: 2px;
    max-width: 220px;
    min-width: 220px;
    margin: 1rem;
    padding: 1rem;
    transition: all 0.1s;
    border: 2px transparent solid;

    &.selected {
      border: 2px solid $primary-color;
    }

    figure {
      margin: 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      overflow-x: hidden;
      img {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        vertical-align: middle;
        border-radius: 2px;
      }

      figcaption {
        width: -webkit-fill-available;
        a {
          font-size: 1.4rem;
          color: #8499c2;
          overflow: hidden;
          max-width: 180px;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }

    &:hover {
      figcaption {
        a {
          color: white;
        }
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
      cursor: pointer;
      img {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.spinner {
  margin: 2rem auto;
}

.isLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}
