@import '../../../../../../assets/styles/admin/variables.scss';

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  width: 95%;
  height: 90%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
  top: 0;
  bottom: 0;
  margin: auto;

  & > div {
    margin: auto;
    top: 50%;
    transform: translateX(-50%);
  }
}

.editButton,
.editButtonDisabled {
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 44px;
  padding: 16px;
  width: 150px;
  height: 41px;
  font-size: 14px;
  font-weight: 500;
  color: #6f7fe2;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  font-family: 'Inter';
}
.editButtonDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.productItem {
  margin: 12px 0 !important;
  display: flex;
  column-gap: 3rem;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  img {
    height: 114px;
    width: auto;
    margin-bottom: 0;
    max-width: 96px;
  }

  h3 {
    font-weight: 500;
    font-size: 1em;
  }

  svg {
    margin-left: auto;
  }

  small {
    margin-top: 0 !important;
    font-size: 1em;
  }

  &.existingProduct {
    border: 3px solid $purple;
    border-radius: 5px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
  }
}

.productCollection {
  margin-bottom: 50px;
  & [class*='product-item'] {
    margin-top: -2px;
    border-radius: 0px;

    &:focus {
      margin-top: -2px;
      z-index: 9;
    }
  }

  & div:first-child [class*='product-item'] {
    margin-top: 0px;
    border-top: 2px solid $product-border-color;
    border-radius: 4px 4px 0px 0px;

    &:focus {
      margin-top: 0px;
    }
  }

  & div:last-child [class*='product-item'] {
    border-radius: 0px 0px 4px 4px;
  }

  & div:first-child:last-child [class*='product-item'] {
    border-radius: 4px;
  }
}

.clearButton {
  display: flex;
  justify-content: right;
}
