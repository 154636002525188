@import '../../assets/styles/admin/variables.scss';

.tracker {
  height: 5px;
  background-color: $light-blue;
  width: 80%;
  border-radius: 5px;
  margin-top: 10px;

  .progressedTrack {
    background-color: $blue;
    border-radius: 5px;
    height: inherit;
    transition: width 0.1s ease-in-out;
  }

  .progressText {
    text-align: center;
    font-size: 11px;
    margin-top: 5px;
    color: $light-blue;
  }
}
