@import '../../assets/styles/admin/variables.scss';

.PaymentForm {
  padding-bottom: 6rem;

  * + fieldset {
    margin: 2rem 0 0;
  }
}

input.couponCode {
  width: 255px !important;
  margin-right: 10px !important;
  float: left;
}

p.coupon {
  margin: 0;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1.3rem;
  color: white;
  background-color: $success-color;
  svg {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }
}

.comparePlans {
  float: right;
  font-size: 1.4rem;

  svg {
    vertical-align: middle;
  }
}

.tag {
  font-size: 1.2rem;
  padding: 0.4em 0.7em;
  border-radius: 3px;
  vertical-align: middle;
  background: #00943c;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.radioGroup {
  margin-top: 1rem;

  label {
    display: block;
    margin: 0 !important;
    padding: 1.5rem;
    border: 1px solid $border-color;
    transition: background 0.14159s;
    cursor: pointer;

    &.selected {
      background: #f8fafe;
    }

    &:not(:first-child) {
      border-top: none;
    }

    input {
      float: left;
      margin: 0.45rem 0 0 0;
    }

    .right {
      float: right;
      margin-left: 1rem;
    }

    .title {
      margin: 0 0 0 2rem;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.5rem;
    }

    .description {
      margin: 0.5rem 0 0 2rem;
      font-size: 1.4rem;
    }
  }
}
