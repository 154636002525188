@import '../../../assets/styles/admin/variables.scss';

.footer {
  flex: 0 0 auto;
  .brand {
    padding: 2rem 0 2rem $nav-gutter;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    margin: 0;
    height: 43px;
    width: 140px;
    vertical-align: middle;
  }
}

.helpMenuButton {
  width: 20px;
  height: 20px;
  margin-right: 25px;
  cursor: pointer;

  svg {
    color: #7b8595;
  }
}

.helpMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 40px;
  right: -29px;
  width: 242px;
  background-color: #fff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 0;
  justify-content: space-between;

  span,
  a {
    display: flex;
    align-items: center;
    padding: 5px 0 5px 30px;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    cursor: pointer;
    text-decoration: none;
    color: #0e1521;
    opacity: 0.8;
    .linkIcon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      text-align: center;
      color: #7b8595;
    }
    &:hover {
      background-color: #eff3fb;
      opacity: 1;
    }
  }

  .divider {
    width: 90%;
    margin: 8px auto;
    border-bottom: solid thin $light-gray-blue;
  }
}
