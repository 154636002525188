@import '../../../../assets/styles/admin/variables.scss';

.CustomCodeSection {
  position: relative;

  .customCodes {
    padding: 0;
  }

  article.ListZeroState {
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    padding: 4rem;

    > * {
      margin: 0;
    }
    > * + * {
      margin-top: 2rem !important;
    }

    h2 {
      font-weight: 400;
      font-size: 2.2rem;
      color: $header-color-gray;
    }

    small {
      font-size: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      color: $text-color-light;
      padding-bottom: 2rem;
    }

    button {
      margin-top: 2rem;
    }
  }

  .add {
    color: $primary-color;
    height: 28px;
    width: 28px;
    cursor: pointer;
    vertical-align: middle;
    transition: all 0.14159s;

    &:hover {
      color: lighten($primary-color, 10%);
    }
  }

  div.add {
    float: right;
    margin-right: 20px;
    margin-top: 0px;
  }
}
