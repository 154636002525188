@import '../../../assets/styles/admin/variables.scss';

.expansionPanelContainer {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 16px;
  background-color: white;
  &:not(:first-child) {
    margin-top: 12px;
  }

  .caretOpen {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
  .caretClose {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
}
.expansionPanelContainer.isAlert {
  background-color: #fffee0;
}

.expansionPanelTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.expansionPanelTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.6rem;
  position: relative;

  .completeCheckIcon {
    z-index: 1;
    position: absolute;
    & circle {
      transform: scale(0);
      transform-box: fill-box;
      transform-origin: center;
      animation: expand-from-center 0.2s linear 0.2s forwards;
    }

    @keyframes expand-from-center {
      to {
        transform: scale(1);
      }
    }
  }
}

.expansionPanelContent {
  overflow: visible;
  opacity: 1;
  transition: opacity 0.6s ease-in, max-height 0.3s ease-in;
  height: auto;
  max-height: 800px;
  border-radius: 4px;
  > div > div {
    > p {
      font-size: 1.4rem;
      color: rgba(20, 33, 61, 0.8);

      > span {
        font-weight: bold;
      }
    }
  }

  & a {
    font-size: 1.5rem;
  }

  &.collapsed {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.6s ease-out, max-height 0.35s ease-out;
  }
}

.title {
  margin-left: 12px;
}

.completedTitle {
  color: #647fb4;
  text-decoration: line-through;
}
