@import './normalize';

html {
  /* NOTE
  html is set to 62.5% so that all the REM measurements throughout Skeleton
  are based on 10px sizing. So basically 1.5rem = 15px :) */
  font-size: 62.5%;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f8fafe !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body,
.admin,
.sriracha .admin,
.sriracha .admin .sriracha .admin {
  font-family: 'Inter', sans-serif;
  font-size: 1.7rem;
  line-height: 1.6;
  font-weight: 400;
  color: #14213d;
}
