@import '../../assets/styles/admin/variables.scss';

.backdrop {
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 50, 65, 0.5);

  .enter & {
    opacity: 0.1;
  }
  .enterActive & {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .exit & {
    opacity: 1;
  }
  .exitActive & {
    opacity: 0.1;
    transition: opacity 0.1s linear;
  }
}

.avoidBackdrop {
  background: transparent;
  pointer-events: none;
}

.drawer {
  position: fixed;
  z-index: 6;
  top: 0;
  right: 0;
  bottom: 0;
  width: $side-drawer-width;
  background: #fefefe;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  min-width: 300px;
  max-width: 900px;

  &.darkTheme {
    background: #394151;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), -4px 0px 16px rgba(0, 0, 0, 0.15);
  }
  .enter & {
    transform: translateX($side-drawer-width);
  }
  .enterActive & {
    transform: translateX(0);
    transition: transform 0.2s;
  }
  .exit & {
    transform: translateX(0);
  }
  .exitActive & {
    transform: translateX($side-drawer-width);
    transition: transform 0.1s linear;
  }
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $light-background-color;
  padding: 0 2rem;
  height: 45px;
  font-size: 1.5rem;
  border-bottom: solid thin rgba(0, 0, 0, 0.06);
}

.drawerHeaderSuccess {
  background: $success-color;
  color: #fff;
  text-shadow: $text-shadow;

  svg {
    filter: drop-shadow($text-shadow);
  }
}
.darkTheme {
  background: #394151;
  color: white;
  width: 440px;

  &.drawer {
    overflow-y: scroll;
  }
  .drawerHeader {
    background: #394151;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    border: none;
  }
  .drawerClose {
    color: rgba(255, 255, 255, 0.6);
    svg {
      height: 16px;
      margin-bottom: 2px;
    }
  }
  .drawerClose:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

.drawerClose {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.14159s;
  padding: 1rem;
  margin: -1rem;

  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  svg {
    height: 14px;
    vertical-align: middle;
  }
}

.ampDisabledLabel {
  cursor: pointer;
  color: #ffffff;
  background-color: #b5b5b5;
  font-weight: bold;
  font-size: 11px;
  padding: 2px 6px;
  margin-right: 14px;
  border-radius: 3px;
}

.drawerBody {
  box-sizing: border-box;
  min-height: calc(100vh - 45px);
  position: relative;

  &.padding {
    padding: 2rem;
  }
}

.tabs {
  height: 40px;
  width: 400px;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #dee4f3;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & input {
    position: absolute;
    top: -20px;
    opacity: 0;
  }

  & input + label {
    width: 77px;
    height: 40px;
    margin: 0px !important;
    padding: 12px 0px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #14213d;
    opacity: 0.8;
    cursor: pointer;
    text-transform: capitalize;
  }

  & input:checked + label {
    opacity: 1;
    box-shadow: inset 0px -3px 0px #1495cd;
  }
}

.resizableThumb {
  position: absolute;
  top: 0%;
  left: 0;
  margin-left: 3px;
  cursor: col-resize;
  width: 14px;
  height: 100%;
  opacity: 0;
}
.resizableThumbImg {
  -webkit-user-drag: none;
  pointer-events: none;
  opacity: 1;
}
