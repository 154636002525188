@import '../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .container {
    flex: 1;
    margin-right: 2rem;
  }

  .description {
    display: flex;
    align-items: center;

    img {
      height: 1.6rem;
      width: 1.6rem;
      margin: 0 1.2rem 0 0;
    }
  }
  .notes {
    padding: 2rem;
    background-color: #fff;
    border-bottom: solid 1px #dce4f4;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    font-size: 1.5rem;

    label {
      display: flex;
      align-items: center;
      margin: 0 !important;
      svg {
        padding-right: 1rem;
      }
    }

    span {
      color: $text-color;
    }
  }
}

.noActions {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14159);
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  border-radius: 2px;
  justify-content: center;

  p {
    color: $text-color-light;
    font-size: 1.4rem;
    text-align: center;
    width: 30rem;

    label {
      color: $text-color;
      font-size: 2rem;
      margin-top: 1rem;
    }
  }

  input {
    background-color: $purple;
    border-color: #5c6ac4;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    margin: 0;
    padding: 6px 12px;

    &:focus {
      outline: none;
    }
  }
}
