@import '../../../assets/styles/admin/variables.scss';

$container-height: $toolbar-height;

.boxOverlay {
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  transition: box-shadow 0.14159s;
  pointer-events: none;
  border-radius: 0px 5px 5px 5px;

  &.isBorderShown {
    border: 3px solid #7d8fb2;
  }
  &.toolbarActive {
    border: 3px solid #4040d9;
  }

  &.mobile {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
}
.boxOverlayOuter {
  position: absolute;
  left: -6px;
  right: -6px;
  top: -6px;
  bottom: -6px;

  &.mobile {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px 6px 6px 6px;
  pointer-events: none;
}
.boxOverlayInner {
  position: absolute;
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px 2px 2px 2px;
  pointer-events: none;
  &.mobile {
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
  }
}
.label {
  background-clip: padding-box;
  position: absolute;
  top: -25px;
  left: 0px;
  width: fit-content;

  color: white;
  height: 25px;
  background: transparent;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  cursor: default;

  &.toolbarActive {
    .innerContent {
      background: #4040d9;
    }
  }

  &.mobile {
    top: -20px;
    height: 20px;
    .innerContent {
      margin-left: 0px;
      margin-top: 4px;
      overflow: hidden;
    }
  }

  .innerContent {
    background-clip: padding-box !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 0px 0px;
    border-bottom: none;
    margin-left: -6px;

    margin-top: 3px;
    height: 17px;
    width: fit-content;
    background: #7d8fb2;
    padding: 2px 5px 0 5px;
    border-radius: 4px 4px 0px 0px;

    &.mobile {
      margin-left: 0px;
      margin-top: 0px;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: -$container-height - 20px;
  left: 50%;
  height: $container-height;
  width: 548px;
  transform: translateX(-50%);
  min-width: 450px;
  padding: 0 20px 6px;
  pointer-events: none;
  transition: top 0.12s;

  box-sizing: content-box !important;

  &.toolbarActive {
    pointer-events: auto;
  }

  &.isTrayOpen {
    top: -$container-height - 42px;
    transition: top 0.12s;
  }

  .toolbarWrapper {
    width: fit-content;
    opacity: 0;
    transition: opacity 0.14159s;

    & > :global(.admin) > div {
      padding: 0 24px;
    }
  }

  &.isBorderShown .toolbarWrapper {
    opacity: 1;
  }
}
