@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter');

:global(.admin) .Login {
  background-color: #f8fafe;

  display: grid;
  grid-template-columns: 1fr 1fr;

  // Sticky bottom while preventing margin-collapse issues
  min-height: 100vh;
  padding-bottom: 1px;
  box-sizing: border-box;

  :global(.spark-spinner) {
    margin: 4rem auto;
  }

  .signup {
    display: flex;
    padding-bottom: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    flex: 1 0 0;
    align-self: stretch;
    background: #fff;
    overflow: hidden;
  }
  .bgContainer {
    background-image: url('/assets/images/landing-page-bg.jpeg');

    .signup {
      flex: 1;
      background: transparent;
      align-items: flex-start;
      padding: 111.25px;

      .title {
        color: var(--Text-Reversed-Bold, #fff);
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 32px;
      }

      .icon {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 14px;
        justify-content: center;
        align-items: center;
        gap: var(--Space-2, 8px);
        border-radius: 12px;
        background: var(--Green-400, #61dea6);

        svg {
          height: 20px;
          width: 20px;
          flex-shrink: 0;
        }
      }
      .iconTable {
        display: grid;
        grid-template-columns: 40px 1fr;
        gap: 16px;
        color: var(--Text-Reversed, #fff);
        font-size: 18px;
        font-weight: 500;
        align-items: center;
      }
    }
  }

  .commentContainer {
    display: flex;
    flex-direction: column;
    width: 496px;
    padding: var(--Space-6, 24px);

    align-items: flex-start;
    gap: var(--Space-2, 10px);
    border-radius: var(--Space-2, 8px);

    background: rgba(14, 21, 33, 0.52);

    color: var(--Cloud, #fff);
    font-size: 17px;
    font-weight: 400;
    line-height: 160%;
    gap: 16px;

    .profile {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
        border-radius: 40px;
        margin: 0;
      }

      .text {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 17px;
          font-weight: 500;
          line-height: 160%;
        }
      }
    }
  }

  .banner {
    display: flex;
    width: 496px;
    align-items: center;
    gap: var(--Space-6, 24px);
    font-family: Source Sans Pro;
    font-size: 33px;
    font-weight: 600;
    line-height: 41.48px;
    text-align: left;
    gap: 24px;

    .brand {
      width: 144px;
      height: 65px;
    }
  }

  form {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      color: var(--Text-Bold, #0e1521);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }

    .fieldWithErrors {
      background-color: red;
    }

    fieldset {
      margin: 0;
      input {
        border-radius: 4px;
        border: 1px solid var(--Border-Dark, #b5c0dc);
        background: var(--Background-Depth-0, #fff);

        :focus {
          border-radius: 4px;
          border: 1px solid var(--Border-Focus, #07ab59);
          background: var(--Background-Depth-0, #fff);
        }
      }
      input.disabled {
        border: none;
        border-radius: 4px;
        background: var(--Background-Disabled, rgba(230, 233, 237, 0.5));
      }
    }

    small {
      margin-bottom: 6px;
    }

    .terms {
      color: var(--Text-Default, rgba(14, 21, 33, 0.8));
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 25.2px */

      a {
        color: var(--Text-Link, #4040d9);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 180%;
        text-decoration-line: underline;
        transition: all 0.14159s;
        cursor: pointer;
      }

      a:hover {
        color: #6666e1;
        opacity: 1 !important;
      }
    }

    .forgotPassword {
      @extend .terms;
      margin-bottom: 20px;
    }

    .submit {
      display: flex;
      height: 48px;
      width: 100%;
      padding: var(--Space-0, 0px) 32px;
      margin-bottom: 16px;

      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border-radius: 4px;
      border: var(--Space-0, 1px) solid var(--Button-Primary-Border, rgba(255, 255, 255, 0));

      background: var(--Button-Primary-Background, #0e1521);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

      color: var(--Button-Primary-Text, #fff);
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  .centerText {
    margin-top: 4rem;
    text-align: center;
  }

  span.success {
    color: $success-color;
    margin: 0;
    font-size: inherit;
  }

  footer {
    margin: 40px;
    text-align: center;
    color: $text-color-light;
    font-size: 1.5rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 496px;
  margin: 0 auto;
  background: #fff;
}

.shopifyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;

  text-align: center;
  background-color: #eff8dd;
  width: 100%;
  max-width: 420px;
  height: 104px;
  margin: 0 auto;
  border-radius: 0px 0px 8px 8px;

  .shopifyText {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #030d17;
  }

  .shopifyButton {
    border: none;
    border-radius: 4px;
    margin: 0px;
    width: 103px;
    height: 32px;
    background: #00805f;
    font-size: 14px;
    color: #ffffff;
    transition: all 0.14159s;
    &:hover {
      background: #00664c;
    }
    &:focus {
      background: #00664c;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }
    &:active {
      background: #004d39;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.loginFooter {
  a {
    color: #4040d9 !important;
  }
}

.adobe {
  align-items: center;
  display: flex;
  gap: 16px;
  color: var(--Text-Bold, #0e1521);
  font-size: 18px;
  font-weight: 500;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
