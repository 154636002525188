/* AdminBar */

@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .template {
    @include unstyled-button;
  }

  .UnstackMark {
    margin-right: 0.5rem;
    height: 1.6rem;
    vertical-align: -0.4rem;
  }

  .url {
    margin-left: 1rem;
    font-size: 1.4rem;
    color: $dark-gray-blue-faded;
    font-weight: 400;
    transition: all 0.12s;
  }
  .url:hover {
    color: #fff;
    opacity: 1 !important;
  }
  .url[href]:hover {
    // text-decoration: underline;
    color: inherit;
  }
  @include respond-below(md) {
    .url {
      display: none;
    }
  }
  .status,
  .live {
    color: #4040d9;
    font-weight: 500;
    font-size: 12px;
    margin-left: 18px;
    border: 1px solid #4040d9;
    border-radius: 2px;
    vertical-align: middle;
    text-transform: uppercase;
    cursor: pointer;
    padding: 2px 6px;
  }

  .draft {
    @extend .status;
    color: white;
    border: none;
    background: #e14e7f;
  }

  .isChanged {
    @extend .status;

    @include respond-below(lg) {
      display: none;
    }
  }

  %text-button {
    @include unstyled-button;

    padding: 0;
    border: none;
    line-height: 24px;
    margin-bottom: 0;
    vertical-align: middle;
    font-size: 1.3rem;
  }

  .discard {
    @extend %text-button;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 400;
    margin-left: 0.75rem;

    svg {
      vertical-align: middle;
      margin-right: 0.3em;
    }
  }

  .saveLabel {
    display: inline-table;
    cursor: default;
    margin-right: 16px;
    font-weight: 500;
    font-size: 14px;
    -ms-user-select: none;
    user-select: none;
  }
  .published {
    @extend .saveLabel;
    color: #1aa375;
  }

  .discardChanges {
    @extend .saveLabel;
    color: #7d8fb2;
    cursor: pointer;

    span {
      font-family: system-ui;
    }
    -ms-user-select: none;
    user-select: none;
  }
  .discardChanges:hover {
    color: #4040d9;
  }

  @include respond-below(sm) {
    .saveLabel {
      display: none;
    }
  }

  .template {
    height: 20px;
    width: 20px;
    vertical-align: middle;
    box-sizing: content-box;
    transition: opacity 0.14159s;

    svg {
      fill: $blue;
      height: 20px;
      transform: rotate(180deg);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  // .right * + * {
  //   // margin-left: 1.5rem;
  // }

  .successMsg {
    position: relative;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: $green;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.4rem;
    font-weight: 500;
    width: fit-content;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    .url {
      color: inherit;
      text-decoration: underline;
      margin: 0;
      font-size: inherit;
      font-weight: 500;
    }

    svg {
      margin-right: 0.5em;
      vertical-align: -4px;
      height: 20px;
      width: 20px;

      path {
        transition: opacity 0.3s;
        opacity: 0;
      }
    }

    transition: transform 0.25s;
    transform: translateY(-20px);

    &:global(-enter) {
      transform: translateY(-20px);
    }

    &:global(-enter-active),
    &:global(-enter-done) {
      transform: translateY(35px);
    }

    &:global(-enter-done) svg path {
      opacity: 1;
    }
  }
  .publish {
    height: 40px;
    background: #4040d9;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 44px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    padding: 0 24px;
    gap: 8px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 0;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    transition: 0.5s width;
    white-space: nowrap;
    -ms-user-select: none;
    user-select: none;
  }
  .publish:hover {
    background: #292bcf;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
  .publish:focus {
    background: #0606c1;
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
  }
  .publish:disabled {
    background: #dae2f2;
    color: #7d8fb2;
    border: inherit;
    box-shadow: none;
  }
  .publish:active {
    background: #0202a2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
  }
  .preview {
    @extend .publish;
    color: #7373e5;
    background-color: transparent;
    border: 1px solid #dee4f3;
    box-shadow: none;
    margin-right: 0;
    pointer-events: all;
    -ms-user-select: none;
    user-select: none;
  }
  .preview:hover {
    color: rgba(64, 64, 217, 0.8);
    background: inherit;
    border: 1px solid #cfd7ec;
    box-shadow: none;
  }
  .preview:focus {
    color: #4040d9;
    background: rgba(255, 255, 255, 0.001);
    border: 1px solid #cfd7ec;
    box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    outline: none;
  }
  .preview:disabled {
    color: #dae2f2;
    background: inherit;
    border: inherit;
  }
  .preview:active {
    background: rgba(242, 245, 253, 0.5);
    border: 1px solid #bfc8e0;
  }
  .previewAnchor {
    pointer-events: none;
  }
}
.leftText {
  padding-right: 9px;
}
.leftText:hover input:not(.leftText:hover input:focus),
.leftText:hover .widthControl:not(.leftText:hover input:focus) {
  color: #4040d9;
}
.leftText input,
.widthControl {
  caret-color: rgba(64, 64, 217, 1);
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #102040;
  border-style: hidden !important;
  outline: none;
  margin-bottom: 0;
  margin-right: 8px;
  cursor: pointer;
  max-width: 450px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;
  &.errorTitle {
    background: url(data:image/gif;base64,R0lGODlhCgAEAMIEAP9BGP6pl//Wy/7//P///////////////yH5BAEKAAQALAAAAAAKAAQAAAMROCOhK0oA0MIUMmTAZhsWBCYAOw==)
      repeat-x scroll 0 100% transparent;
  }
  &::placeholder {
    color: rgba(88, 118, 181, 0.4);
  }
}
span.widthControl {
  height: 24px;
  color: transparent !important;
}
.titleForm {
  height: 40px;
  position: relative;
  margin-left: 12px;
}
.editIcon.shownIcon {
  opacity: 1;
  margin-left: -7px;
  color: #4040d9;
  width: initial;
}
.leftText input:focus {
  outline: none;
  cursor: text;
}
.editIcon {
  margin-left: -10px;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transition: 0.3s, width 0s;
  width: 0;
}
.editIcon polygon,
.editIcon path {
  fill: #4040d9;
}

.inputWrap {
  position: relative;
  margin-left: 12px;
}
.inputWrap input {
  position: absolute;
  width: 100%;
  left: 0;
  background-color: transparent;
  &:placeholder-shown {
    position: inherit;
    padding: 8px;
    width: 5em;
    height: 29px;
  }
}
.widthControl {
  color: black;
  background: transparent;
  padding-left: 2px;
  padding-top: 1px;
}
.info {
  background-color: transparent;
  border: none;
}
img.spinner {
  animation: spin 1s linear infinite;
  height: 24px;
  width: 24px;
  margin: 0;
}
.iconLink {
  height: 100%;
  display: flex;
  align-items: center;

  margin-left: 19px;
  &:hover path {
    fill: #102040;
  }
}
.helpIcon {
  margin-left: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
