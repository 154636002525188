.container {
  width: 460px;
  transform: translateY(15%);
  margin: auto;
  text-align: center;
  background-color: #fff;
  padding: 4rem 3rem;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

  .tada {
    font-size: 48px;
    padding: 18px 24px;
    background-color: #eff3fb;
    border-radius: 50%;
  }

  .heading {
    display: flex;
    justify-content: center;

    h2 {
      width: 251px;

      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
    }
  }

  p {
    font-size: 1.6rem;
  }

  form {
    padding: 0 1rem;
    margin: 2rem auto 0 !important;
    text-align: left;
    textarea {
      max-width: 100%;
      min-width: 100%;
      background-color: #fff;
      min-height: 100px;
    }
  }
}
