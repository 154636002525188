@import '../../assets/styles/admin/variables.scss';

.container {
  width: 100%;
  margin: 0 auto;

  .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
  .item {
    flex-basis: 30%;
    -ms-flex: auto;
    position: relative;
    padding: 25px;
    box-sizing: border-box;
  }

  @media (max-width: 1333px) {
    .item {
      flex-basis: 33.33%;
    }
  }
  @media (max-width: 1073px) {
    .item {
      flex-basis: 33.33%;
    }
  }
  @media (max-width: 815px) {
    .item {
      flex-basis: 50%;
    }
  }
  @media (max-width: 555px) {
    .item {
      flex-basis: 100%;
    }
  }

  .item {
    .imageHolder {
      height: 200px;
      width: 100%;
      background-color: #ededed;
      border: solid 1px #bbbbbb;
    }
    label {
      color: $medium-gray;
      margin: 5px 0;
    }
    .largeTextSkeleton {
      height: 15px;
      width: 50%;
      background-color: #ededed;
    }
    .smallTextSkeleton {
      height: 15px;
      width: 20%;
      background-color: #ededed;
      margin-top: 13px;
    }
  }

  .firstItem {
    display: flex;
    padding: 25px;
    .imageHolder {
      flex: 2;
      height: 300px;
      width: 80%;
      background-color: #ededed;
      border: solid 1px #bbbbbb;
      margin-right: 2rem;
    }
    .text {
      flex: 1;
      label {
        color: $medium-gray;
        margin: 5px 0;
      }
      .largeTextSkeleton {
        height: 15px;
        width: 50%;
        background-color: #ededed;
      }
      .smallTextSkeleton {
        height: 15px;
        width: 20%;
        background-color: #ededed;
        margin-top: 13px;
      }
    }
  }

  @media (max-width: 555px) {
    .firstItem {
      display: block;
      .imageHolder {
        height: 200px;
        width: 100%;
        background-color: #ededed;
        border: solid 1px #bbbbbb;
        margin-right: 2rem;
      }
    }
  }
}
