.container {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  min-height: 64px;

  &:hover {
    background: #f8fafe;
    cursor: pointer;
  }

  border: 2px solid #dee4f3;

  & > span.itemLabel {
    font-size: 1.6rem;
    line-height: 2.5rem;
    flex: 1;
    color: #14213d;
  }
}

span.itemThumbnail {
  background: #eff3fb;
  border-radius: 0.2rem;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .thumbnailPlaceholderContainer {
    display: flex;
    width: 20px;
    flex-wrap: wrap;
    gap: 0.2rem;

    .thumbnailPlaceholder {
      display: block;
      width: 0.7rem;
      height: 0.7rem;
      background: rgba(100, 127, 180, 0.55);
      border-radius: 2.5px;
    }
  }

  .itemImage {
    width: 100%;
    object-fit: cover;
    margin: 0;
    height: 100%;
    object-position: top;
  }

  &.styleForAdminPanel {
    background: transparent;
    width: 2rem;
    height: 2rem;
  }
}

.emptyState {
  font-size: 16px;
  font-weight: 400;
  margin-left: 16px;
}

.iconContainer {
  display: block;
  width: 3.2rem;
  height: 3.2rem;

  svg {
    transform: rotate(270deg);
    color: #647fb4;
  }
}
