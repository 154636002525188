@import '../../../assets/styles/admin/variables';
@import '../../../assets/styles/admin/mixins';

.Controls {
  flex: 0 0 auto;
  padding: 1rem 2rem;

  display: flex;

  background: $dark-blue;
  color: #fff;

  input,
  button,
  select,
  a {
    margin-bottom: 0 !important;
  }

  input,
  select {
    color: #000;
    height: 30px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  a {
    color: #fff;
  }

  .backButton {
    @include unstyled-button;
    margin-right: 2rem;
    transition: all 0.12s;
    stroke: white;

    svg {
      height: 20px;
      width: 20px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }

  .publish,
  .info,
  .template {
    @include unstyled-button;
  }

  %text-button {
    @include unstyled-button;

    padding: 0;
    border: none;
    line-height: 24px;
    margin-bottom: 0;
    vertical-align: middle;
    font-size: 1.3rem;
  }

  .publish {
    @extend %text-button;
    color: $primary-color;
    margin-left: 0.75rem;
  }
  .publish:hover {
    border: none;
    opacity: 0.8;
  }
  .publish:disabled {
    background-color: transparent !important;
    color: rgb(86 86 86) !important;
  }

  .discard {
    @extend %text-button;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 400;
    margin-left: 0.75rem;

    svg {
      vertical-align: middle;
      margin-right: 0.3em;
    }
  }
}
.rightWrapper {
  display: flex;
  width: 100%;
}

.viewButtons {
  margin-left: auto;

  button {
    width: 30px;
    background: none;
    border: none;
    margin: 0 0 0 7px;
    color: #fff;
    outline: none;

    &.active {
      color: $blue;
    }

    svg {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }
  }
}

.jsxError {
  @extend %text-button;
  padding-top: 5px !important;
  color: $danger-color !important;
  font-weight: bold;
}
