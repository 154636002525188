@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) .Branding {
  padding: 2rem;
  display: flex;
  flex-flow: row nowrap;
  min-height: 280px;

  > div {
    flex: 0 0 25%;
    margin: 0 4rem 0 0;

    &.iconElement {
      flex: 0 0 10%;
      text-align: center;
      p {
        text-align: left;
      }
    }

    &.iconSocial {
      flex: 0 0 28%;

      figure {
        padding: 0;
        width: 19.5vw;
        height: 11vw;
        justify-content: center;
        align-items: center;
        display: flex;

        svg {
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    > .helperText {
      display: none;
      text-align: center;
      font-size: 0.8em;
      padding-top: 10px;
      color: #647fb4;
    }

    &:hover {
      .helperText {
        display: block;
      }
    }
  }

  figure {
    @include gray-border;
    text-align: center;
    margin: 1rem 0 0;
    padding: 2rem 0;
    border-radius: 2px;

    figcaption {
      display: block;
      font-size: 0.7em;
    }

    input[type='text'] {
      @include unstyled-input;
      text-align: center;
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .imgPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem;
    width: 135px;
    height: 36px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.4);
    border: dashed 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.12s;
  }
  .imgPlaceholder:hover {
    opacity: 0.7;
  }

  .logo {
    height: 28px;
    width: auto;
    transition: all 0.14159s;
  }

  .logo:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .darkLogo {
    .imgPlaceholder {
      color: rgba(255, 255, 255, 0.4);
      border-color: rgba(255, 255, 255, 0.4);
    }
    input[type='text'] {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .faviconPlaceholder {
    @extend .imgPlaceholder;
    margin: 1rem 0 0;
    width: 32px;
    height: 32px;
    background: #fff;
  }

  .favicon {
    width: 32px;
    height: 32px;
    margin: 1rem 0 0;
    border-radius: 2px;
    transition: all 0.14159s;
  }
  .favicon:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .social {
    margin-bottom: 0;
  }

  .socialImagePlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    small {
      color: rgba(0, 0, 0, 0.25);
      margin-top: 1rem;
    }
  }
}
