.accordion {
  color: red;
  border: 1px solid purple;
}

.close {
  width: 24px;
  height: 24px;
  object-fit: cover;
  display: inline-flex;
  position: fixed;
  top: 0;
  right: 0;
  margin: 32px;
  z-index: 2;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
  transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: scroll;
  scrollbar-width: none;
  pointer-events: none;
  user-select: none;
}

.overlay::-webkit-scrollbar {
  width: 0;
}

.overlay.active {
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
}

.overlay .unstack-media picture,
.overlay .unstack-media img {
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: contain;
  margin: 0 auto;
}

.overlay .swiper-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay .swiper-slide {
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.overlay .swiper-slide img {
  display: block;
  object-fit: cover;
  cursor: pointer;
}

.overlay .swiper-pagination-bullet {
  background: #FFF;
}

/* Image fix */
.overlay picture {
  padding-bottom: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5019607843);
  transition: color 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.swiper-button-next:hover::after,
.swiper-button-prev:hover::after {
  color: #000;
}

/* .swiper-slide-active{
  width: inherit !important;
} */
.swiperSecondary {
  width: 1000px;
  margin-right: 10px;
}

.swiperSecondary .swiper-wrapper {
  flex-direction: column;
  gap: 8px;
}

.swiperSecondary .swiper-slide {
  height: initial;
  width: 100% !important;
}

.swiperPrimary {
  width: 100%;
}

.swiperSecondary {
  box-sizing: border-box;
}

.swiperSecondary .swiper-wrapper {
  display: flex;
  gap: 8px;
  border-radius: 2px;
  overflow: hidden;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
}

.swiperSecondary .swiper-slide {
  cursor: pointer;
  width: 100% !important;
  display: block;
  flex-shrink: 1;
  transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  border-radius: 2px;
  overflow: hidden;
}

.swiper-slide picture {
  cursor: pointer;
  padding-bottom: 0 !important;
}

.swiperSecondary .swiper-slide-thumb-active {
  opacity: 0.5;
}

.swiper-container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-size: cover;
  background-position: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5019607843);
  transition: color 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.swiper-button-next:hover::after,
.swiper-button-prev:hover::after {
  color: #000;
}

.gallery-container {
  width: 100%;
  grid-template-columns: 1fr;
  display: grid;
  gap: 32px;
  height: fit-content;
}

.gallery-container > .swiperSecondary {
  height: 100%;
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .swiperSecondary .swiper-wrapper {
    position: absolute;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-container {
    display: flex;
    flex-flow: row wrap;
  }
  .swiperSecondary {
    order: 1;
  }
  .swiperSecondary .swiper-wrapper {
    flex-direction: row;
  }
}
.swiper-slide.unstack-media img {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
}

.swiper-slide.unstack-media img.visible {
  display: block;
}

.swiper-slide.unstack-media img.opaque {
  opacity: 1;
  position: inherit;
  top: inherit;
}

.swiperOverlay .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#unstack__modal__content {
  opacity: 0;
  z-index: 9999;
  transition: transform 0.5s, opacity 0.5s;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-width: auto;
  min-height: auto;
}
#unstack__modal__content.productModal {
  min-width: 0;
  min-height: 0;
}

#unstack__modal__padding {
  padding: 2rem;
  max-width: 100%;
}

.is-visible #unstack__modal__content {
  opacity: 1;
  transition-delay: 0.1s;
}

#unstack__modal__overlay {
  position: fixed;
  inset: 0;
  background: rgba(127, 127, 127, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}

.is-visible #unstack__modal__overlay {
  background: rgba(127, 127, 127, 0.5);
}

#unstack__modal {
  pointer-events: none;
  margin: 0;
}

#unstack__modal.is-visible {
  pointer-events: auto;
  z-index: 4;
}

.unstack__modal__footer {
  text-align: center;
  margin-bottom: 10px;
}

.productModalImg {
  max-width: 100%;
  max-height: 100%;
}

.productModalImgContainer {
  text-align: center;
  width: 198px;
  height: 128px;
  margin-right: 40px;
}

a.product-anchor.modalButton {
  cursor: pointer;
}

.modalButton {
  padding: 6px 12px;
  transition: background-color 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  border-radius: 32px !important;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.innerContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
  background-color: white;
  padding: 48px;
}

.innerContent .product-option-values {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  margin: 0 0 8px;
  padding: 0;
}

.innerContent .product-option-value.unavailable {
  opacity: 0.5;
}

.innerContent .product-option-value.unavailable a::before {
  content: "";
  background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M24 0L0 24' stroke='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -1px;
  border-radius: 32px;
  overflow: hidden;
}

#unstack__modal__content .innerContent button.add-to-bag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  width: 100%;
  margin: 24px 0px 0px;
}

.innerContent .price {
  margin-bottom: 32px;
}

.innerContent .close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  margin-left: 10px;
  order: 1;
  height: 40px;
  width: 40px;
  color: lightgrey;
  cursor: pointer;
  z-index: 999;
}

.productModal .innerContent .close-button {
  position: static;
  top: 0;
  right: 0;
}

.innerContent .close-button:hover {
  color: grey;
}

.innerContent .product-modal-title {
  margin-bottom: 8px;
  font-size: 24px;
  margin-top: 5px;
  font-size: 3.2rem !important;
}

.innerContent p.optiongroup-name.label {
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
}

.product-modal-price {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .innerContent {
    display: block;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    width: 336px;
    max-width: 100%;
    padding: 0;
    position: relative;
  }
  #unstack__modal__content {
    transition: none;
    max-height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .productModalImgContainer {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
  }
  .productModalImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .innerContent .price {
    margin-bottom: 0px;
  }
  .innerContent .productModalImgContainer {
    margin: auto;
    margin-right: 0px;
    width: 100% !important;
    height: 350px !important;
  }
  .innerContent .optiongroup-name {
    text-align: left;
  }
  .modal-text-area {
    padding: 30px 30px 30px 30px;
    text-align: left;
  }
  .innerContent .product-modal-title {
    margin-top: 25px;
    max-width: auto;
  }
  .innerContent .close-button {
    position: absolute;
    margin-left: 0;
    height: 40px;
    width: 40px;
    top: 20px;
    right: 20px;
  }
  .productModal .innerContent .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
.formModal .formModalTitle {
  text-align: left;
  padding-right: 48px;
}
.formModal .innerContent {
  padding: 48px 48px 38px;
}

/* Form Modal */
@media only screen and (max-width: 767px) {
  .formModal .formModalTitle {
    text-align: left;
    margin-left: 10px !important;
    margin-top: 10px !important;
  }
  .formModal .innerContent {
    padding: 20px 20px 10px;
  }
}
.unstack-sticky {
  position: sticky;
  bottom: 0px;
  width: 100%;
}

.unstack-fixed {
  position: fixed;
  bottom: -1px;
  transition: background-color 0.5s;
  right: 0;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.unstack-sticky, .unstack-fixed {
  padding: 0px 20px;
}

