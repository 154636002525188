@import '../../shared/styles/main.css';
@import '../../shared/styles/blog.css';

@import '../admin/variables.scss';
@import '../admin/mixins.scss';

@import './toolbars.scss';

.sriracha,
.sriracha .admin {
  @import './toolbars.scss';
  @import './editor-overrides.scss';
}

.sriracha {
  -webkit-font-smoothing: auto;
  -moz-font-smoothing: auto;
  font-smoothing: auto;
}
