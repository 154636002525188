@import '../../../../assets/styles/admin/variables.scss';

$border: solid thin #dce4f4;

.family {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 2px;
  border: $border;
  margin: 0 0 1rem;
  cursor: pointer;

  transition: background-color 0.14159s, border-color 0.14159s, box-shadow 0.14159s;

  &:not(.active):hover {
    background-color: #effaff;
    border-color: $primary-color;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.12);
  }

  &.active {
    cursor: default;
    background: rgba(220, 228, 244, 0.3);
  }
}

.swatch {
  background: rgba(220, 228, 244, 0.2);
  flex: 0 0 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  letter-spacing: 0.1rem;
  border-right: $border;
}

.label {
  flex: 1 1 auto;
  padding: 0 2rem;
  font-size: 1.5rem;
  height: 50px;

  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.checkmark {
  color: $primary-color;
  height: 1.4rem;
  margin-left: auto;
  margin-top: -0.2rem;
}
