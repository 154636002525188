@import '../../../assets/styles/admin/variables.scss';

.banner {
  width: 220px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 16px;
  background-color: white;
  border: solid 1px #7373e5;
  margin: 16px;

  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 16px 0px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    color: #4040d9;
    margin: 0px;
  }

  .icon {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 100px;
    background: #eff3fb;
    font-size: 24px;
    line-height: 24px;
  }
}
