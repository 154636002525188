@import '../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .container {
  }

  .avatar {
    height: 2rem;
    width: 2rem;
    vertical-align: middle;
    margin: 0 1rem 0 0;
  }

  .tag {
    position: absolute;
    background-color: $purple;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: white;
    font-size: 10px;
    font-weight: 500;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 0.8rem;
    margin-top: 3px;
    padding: 3px 5px;
    line-height: 1;
  }
}
