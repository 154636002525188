@import '../../assets/styles/admin/variables';

.toast {
  position: fixed;
  z-index: 9999;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  max-width: 550px;
  padding: 8px 1.5rem;
  border-radius: 4px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;

  opacity: 0;
  transition: opacity 0.25s, transform 0.25s;

  &.show {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.error {
  background: $danger-color;
}

.success {
  background: $success-color;
}
