@import '../../../../assets/styles/admin/variables.scss';
@import '../../../../assets/styles/admin/mixins.scss';

$padding: $toolbar-padding / 2;

:global(.admin) .ColorPicker {
  display: flex;
  align-items: center;

  height: 100%;
  box-sizing: border-box;

  position: relative;

  .IsQuill {
    width: 16px !important;
    margin-right: 8px;
    background-color: transparent !important;
    .ColorArrowIcon {
      position: absolute;
      right: 7px !important;
    }
  }

  .SwatchContainer {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: auto;
    cursor: pointer;

    &:hover {
      background-color: rgba(128, 130, 179, 0.4);
    }

    .ColorArrowIcon {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
  .IsOpen {
    background-color: rgba(128, 130, 179, 0.4);
  }

  .SwatchCheckeredContainer {
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 10px;
    overflow: hidden;

    .ColorSwatch {
      display: block;
      height: 16px;
      width: 16px;
      z-index: 1;
      border-radius: 10px;
      border: solid 1px $toolbar-placeholder-color;
    }
  }

  .SwatchTransparent {
    height: 16px;
    width: 16px;
  }

  .Drawer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(57, 59, 113, 1);
    min-width: 200px;
    padding-bottom: 4px;

    .ColorInput {
      position: relative;
      display: flex;
      padding: 10px 8px;
      align-items: center;
      border-top: solid thin rgba(255, 255, 255, 0.14159);
      border-bottom: solid thin rgba(255, 255, 255, 0.14159);
      background-color: rgba(57, 59, 113, 1);
      z-index: 2;

      .Control {
        margin-left: 0px;
        font-family: inherit;
        font-size: $toolbar-font-size;
        height: 32px;
        width: 127px;
        background-color: rgba(128, 130, 179, 0.25) !important;
        color: white;
        border: none;
        padding-left: 32px;

        &:focus {
          background-color: rgba(128, 130, 179, 0.4) !important;
        }
        &::placeholder {
          color: $toolbar-placeholder-color;
        }
      }

      .NoOpacity {
        width: 184px;
      }

      .Opacity::-webkit-outer-spin-button,
      .Opacity::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .Opacity {
        margin-bottom: 0px;
        margin-left: $padding;
        font-family: inherit;
        font-size: 12px;
        height: 32px;
        width: 49px;
        background-color: rgba(128, 130, 179, 0.25) !important;
        color: white;
        border: none;
        padding-left: 6px;

        &:focus {
          background-color: rgba(128, 130, 179, 0.4) !important;
        }
      }

      .OpacityContainer {
        display: flex;
        align-items: center;

        p {
          position: absolute;
          color: white;
          font-size: 12px;
          right: 18px;
        }
      }

      .SwatchCheckeredContainer {
        position: absolute;
        left: 18px;
      }

      > input[type='text'] {
        margin-bottom: 0;
      }
    }

    .ulIsQuill {
      padding-top: 0px !important;
    }

    > ul {
      max-height: 295px;
      overflow-y: auto;
      list-style: none !important;

      > li.ColorChoiceContainer {
        color: white !important;
        margin-left: unset !important;
        display: flex;
        height: 32px;
        padding: 14px !important;
        cursor: pointer;
        align-items: center;
        background-color: $toolbar-tray-background !important;
        justify-content: space-between;

        span {
          margin-left: $padding;
          &.inSwatchLabel {
            font-size: $toolbar-font-size;
            text-overflow: ellipsis;
          }
        }

        transition: background-color 0.14159s;
        &:hover {
          background-color: rgba(128, 130, 179, 0.25) !important;
        }

        .ColorChoice {
          display: flex;
          align-items: center;
        }
      }
    }

    &.enter {
      opacity: 0;
    }
    &.enterActive {
      opacity: 1;
      transition: opacity 0.14159s;
    }
    &.exit {
      opacity: 1;
    }
    &.exitActive {
      opacity: 0;
      transition: opacity 0.14159s;
    }
  }
}
