@import '../../../assets/styles/admin/variables';

.modal {
  width: 40vw;
  min-width: 450px;
  height: 200px;
  max-width: 484px;
  padding: 24px;
  padding-top: 40px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
  & button {
    margin: 0px !important;
  }
  .contentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & img {
      margin-bottom: 0px;
    }
    .title {
      font-weight: 900;
      font-size: 1.8rem;
    }
    .message {
      color: $dark-gray-blue;
      font-size: 1.4rem;
    }
  }
  & p {
    margin: 0px;
    margin-left: 20px;
  }
}
