@import '../../../../../assets/styles/admin/variables.scss';
@import '../../../../../assets/styles/admin/mixins.scss';
$padding: $toolbar-padding / 2;

.ToolbarContainer {
  display: flex;
  border-radius: 8px !important;
  align-items: center;
  justify-content: space-between;
  background-color: #2d2d73;
  padding-right: 0 !important;
  border: none !important;
}
.ToolbarContainer > :not(:last-child) {
  border-right: solid thin #7c7d9c80;
}

.ToolbarGroup {
  height: 40px;
  padding: 0 6px;
}

.EditButton,
.EditButtonDisabled {
  display: flex;
  justify-content: space-between;
  background: #4040d9 !important;
  width: 128px;
  height: 32px;
  border-radius: 4px !important;
  padding: 8px !important;
}
.EditButton > span,
.EditButtonDisabled > span {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}
.EditButtonDisabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.ValuePicker {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .ValueInput {
    height: 30px;
    border-radius: 4px;
    gap: 6px;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-transform: capitalize;
    }

    &:hover {
      // background-color: rgba(128, 130, 179, 0.4);
      background-color: #8082b366;
      cursor: pointer;
    }

    .ArrowDown {
      height: 14px;
      transform: rotate(-90deg);
    }

    .Drawer {
      position: absolute;
      top: 100%;
      left: 0px;
      box-shadow: 0px 1px 0px 0px #7c7d9c80 inset;
      background-color: #2d2d73;
      max-height: 170px;
      overflow-y: auto;
      width: 180px;
      padding: 8px 0px;

      > ul {
        list-style: none !important;

        > li.PaginationChoiceContainer {
          color: white !important;
          margin-left: unset !important;
          display: flex;
          justify-content: space-between;
          padding: 14px !important;
          cursor: pointer;
          align-items: center;
          height: 32px;

          span {
            margin-left: $padding;
            &.inSwatchLabel {
              font-size: $toolbar-font-size;
              text-overflow: ellipsis;
              text-transform: capitalize;
            }
          }

          transition: background-color 0.14159s;
          &:hover {
            background-color: #8082b366 !important;
          }

          .PaginationChoice {
            display: flex;
            align-items: center;
          }
        }
      }

      &.enter {
        opacity: 0;
      }
      &.enterActive {
        opacity: 1;
        transition: opacity 0.14159s;
      }
      &.exit {
        opacity: 1;
      }
      &.exitActive {
        opacity: 0;
        transition: opacity 0.14159s;
      }
    }
  }
  .IsOpen {
    background-color: #8082b366;
  }
}
