@import '../../assets/styles/admin/variables.scss';

.container {
  position: relative;
  padding: 2.5rem;
  background-image: linear-gradient(225deg, #090b45 0%, #2d2f78 100%);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff !important;
  border-radius: 2px;
  overflow: hidden;
  .progressbar {
    position: absolute;
    top: 0;
    margin-left: -2.5rem;
    height: 4px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px 2px 0 0;
    div {
      height: 4px;
      background-color: $green;
      transition: width 0.14159s ease 0s;
    }
  }
  .content {
    max-width: 45%;
    padding-right: 20px;
    @media (max-width: 992px) {
      max-width: 100%;
    }
    h2 {
      color: $text-color-inverted;
      font-size: 2.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    p {
      color: rgba(255, 255, 255, 0.85);
      font-size: 1.4rem;
      // font-weight: 300;
      margin-bottom: 1.5rem;
    }
    .videobutton {
      width: fit-content;
      padding: 7px 15px;
      margin-bottom: 30px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 18px;
      display: flex;
      cursor: pointer;
      svg {
        margin-right: 10px;
      }
      a {
        color: #dedee6;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
      }
      span {
        font-size: 1.3rem;
        line-height: 1.3rem;
        margin-left: 10px;
        font-weight: lighter;
      }
    }
    .cta {
      margin-right: 20px;
    }
    a.next {
      color: #dedee6;
      font-size: 1.4rem;
      font-weight: lighter;
      white-space: nowrap;

      svg {
        width: 18px;
        height: 18px;
        transform: rotate(180deg);
        position: relative;
        top: 5px;
        left: -5px;
        color: #fff;
      }
    }
  }
  > img {
    max-width: 50%;
    max-height: 240px;
    width: auto;
    height: auto;
    position: absolute;
    right: 2.5rem;
    top: 1rem;
    margin: 0;
    @media (max-width: 992px) {
      display: none;
    }
  }
}
