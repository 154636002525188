@import '../../../assets/styles/admin/variables.scss';

// Page tag

// Intended to look like a text input that holds the text and icon
.tagContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.tag {
  cursor: pointer;
  height: 40px;
  padding: 8px 12px;
  background-color: #f8fafe;
  border: 1px solid #d7dae2;
  border-radius: 4px;
  font-size: 1.5rem;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: -1.8rem;

  transition: color 0.14159s;

  &::after {
    content: '';
    background-image: url('../../../assets/images/icon-chevron-down.svg');
    position: absolute;
    right: 4px;
    top: 0px;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

// Form edit Settings
.formSettingLinks {
  font-size: 14px;

  a {
    color: #647fb4;
    text-decoration: underline;

    &:first-child {
      margin-right: 10px;
    }

    div {
      display: inline-block;
      vertical-align: middle;
      margin-left: 3px;
      font-size: 16px;
    }
  }
}

.tagName {
  margin: 0 8px 0 4px;
}

// Autosuggest styling
.container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.inputContainer {
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: -1.8rem;

  input {
    margin-bottom: 0;
  }

  &::after {
    content: '';
    background-image: url('../../../assets/images/icon-chevron-down.svg');
    position: absolute;
    right: 4px;
    top: 0;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.suggestionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 0 0 2px 2px;
  opacity: 0;
  transition: opacity 0.14159s;
}

.suggestionsContainerOpen {
  opacity: 1;
}

.suggestionsListContainer {
  overflow: auto;
  max-height: 175px;
}

.suggestionsList {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.newFormLinkContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  p {
    margin: 0 35px;
    font-size: 14px;
    color: #647fb4;

    a {
      color: #647fb4;
    }

    svg {
      vertical-align: middle;
      height: 14px;
      width: 14px;
      margin-left: 4px;
    }
  }
}

.suggestion {
  display: table-row;
  width: 100%;
  cursor: pointer;
}

.suggestionHighlighted {
  // background-color: rgba(0,0,0,0.05);
  background-color: #f8f9fb;
}

.suggestionIconContainer {
  width: 1%;
}

.suggestionItem {
  position: relative;
  padding: 7px 35px;

  &.selected {
    background: #eff3fb;

    &::before {
      content: url('../../../assets/images/check-mark.svg');
      position: absolute;
      left: 9px;
    }
  }
}

.suggestionIcon {
  display: block;
  height: 14px;
  width: 14px;
  color: $text-color;
}

.suggestion > * {
  display: table-cell;
  font-size: 1.3rem;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.deleteActionLink {
  margin-bottom: 2.5rem;

  p {
    color: $text-color-light;
    font-size: 1.4rem;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  a.confirm {
    color: $danger-color;
    text-decoration: underline;
  }
}
