@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';
$padding: $toolbar-padding / 2;
.container {
  // background: #08f8;
  padding-bottom: 10px;

  position: absolute;
  z-index: 4;

  &.enter {
    opacity: 0;
    transform: translateY(-5px);
  }

  &.enterActive {
    transition: opacity 0.2s, transform 0.2s ease;
    opacity: 1;
    transform: translateY(0);
  }

  &.enterDone,
  .exit {
    opacity: 1;
  }

  &.exitActive {
    transition: opacity 0.15s;
    opacity: 0;
  }

  &.exitDone {
    opacity: 0;
  }
}

// &:after {
//   content: '';
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//
//   height: 0; width: 0;
//   border-left: 5px solid transparent;
//   border-right: 5px solid transparent;
//   border-top: 5px solid $toolbar-background;
// }

.ArrowDown {
  height: 14px;
  transform: rotate(-90deg);
}

.FontPicker {
  display: flex;
  align-items: center;

  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  min-width: 75px;

  position: relative;
  text-transform: capitalize;

  .Drawer {
    position: absolute;
    top: 100%;
    left: -15px;
    right: 0;
    background-color: $toolbar-tray-label-background;

    max-height: 220px;
    min-width: 135px;
    width: max-content;
    max-width: 400px;
    overflow-y: auto;

    > ul {
      list-style: none !important;

      > li.FontChoice {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: white !important;
          margin: 0px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 1rem;
        }

        margin-left: unset !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $padding !important;
        cursor: pointer;

        background-color: $toolbar-tray-label-background;
        transition: background-color 0.14159s;

        &.lastItem {
          border-top: 1px solid rgba(255, 255, 255, 0.15);
          a {
            &:hover {
              opacity: 1;
            }
          }
        }
        &:hover {
          background-color: $toolbar-tray-background !important;
        }
      }
    }

    &.enter {
      opacity: 0;
    }
    &.enterActive {
      opacity: 1;
      transition: opacity 0.14159s;
    }
    &.exit {
      opacity: 1;
    }
    &.exitActive {
      opacity: 0;
      transition: opacity 0.14159s;
    }
  }
}

.ColorPicker {
  display: flex;
  align-items: center;

  height: 100%;
  box-sizing: border-box;

  position: relative;

  .ColorViewer {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 27px;
    left: 10px;
    border: 1px solid #fff;
    background-color: $dark-blue;
  }

  .ColorSelectIcon {
    position: relative;
    top: 3px;
  }

  .Swatch {
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: solid 1px $toolbar-placeholder-color;
  }

  input.Control {
    margin-left: $padding;
    width: 4.5em;
  }

  .Drawer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $toolbar-tray-label-background;

    max-height: 135px;
    min-width: 135px;
    overflow-y: auto;

    > ul {
      list-style: none !important;

      > li.ColorChoice {
        margin-left: unset !important;
        display: flex;
        padding: $padding !important;
        cursor: pointer;

        span {
          margin-left: $padding;

          &.inSwatchLabel {
            font-size: 10px;
            text-transform: lowercase;
            text-overflow: ellipsis;
          }
        }

        background-color: $toolbar-tray-label-background;
        transition: background-color 0.14159s;
        &:hover {
          background-color: $toolbar-tray-background !important;
        }
      }
    }

    &.enter {
      opacity: 0;
    }
    &.enterActive {
      opacity: 1;
      transition: opacity 0.14159s;
    }
    &.exit {
      opacity: 1;
    }
    &.exitActive {
      opacity: 0;
      transition: opacity 0.14159s;
    }
  }
}

.UpDownCounter {
  display: flex;
  align-items: center;

  button {
    display: flex;
    min-width: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &.inputOpen {
    > div:first-of-type {
      width: 36px;
      > input:first-child {
        font-size: 1.5rem;
      }
    }
  }
}

.TextInput {
  width: 1.5em !important;
  text-align: center;
  padding: 0.2em;
}

.extraPadding {
  padding: 0 1.5rem;
  margin: 0 -1.5rem;
}

.leftZero {
  left: 0 !important;
}
