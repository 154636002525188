@import '../../assets/styles/admin/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .headerContainer {
    align-items: center;
    display: flex;

    .alphaLabel {
      color: white;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      background-color: #ffa847;
      vertical-align: 1px;
      border-radius: 2px;
      margin-left: 1rem;
      padding: 0.1rem 0.5rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .newContactButton {
    display: flex;
    align-items: center;
    height: 0;
    padding: 1.5rem 2rem;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    box-sizing: border-box;

    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    font-size: 1.3rem;
    letter-spacing: 0.02rem;
    text-align: center;
    line-height: 3.8rem;

    background: $purple;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    transition: opacity 0.12s;

    &:hover,
    &:focus {
      outline: 0;
      opacity: 0.75;
    }

    svg {
      color: #ffffff;
      margin-right: 1rem;
    }
  }
}
