#unstack__modal__content {
  opacity: 0;
  z-index: 9999;
  transition: transform 0.5s, opacity 0.5s;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-width: auto;
  min-height: auto;

  &.productModal{
    min-width: 0;
    min-height: 0;
  }
}
#unstack__modal__padding{
padding: 2rem;
max-width: 100%;
}

.is-visible #unstack__modal__content{
  opacity: 1;
  transition-delay: .1s;
}

#unstack__modal__overlay{
position: fixed;
inset: 0;
background: rgba(127,127,127,0);

display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
overflow: auto;
}
.is-visible #unstack__modal__overlay{
background: rgba(127,127,127,0.5);
}
#unstack__modal {
  pointer-events: none;
  margin: 0;
}
#unstack__modal.is-visible{
  pointer-events: auto;
  z-index: 4;
}

.unstack__modal__footer{
  text-align: center;
  margin-bottom: 10px;
}
.productModalImg{
  max-width: 100%;
  max-height: 100%;
}
.productModalImgContainer{
  text-align: center;
  width: 198px;
  height: 128px;
  margin-right: 40px;
}
a.product-anchor.modalButton {
cursor: pointer;
}
.modalButton{
  padding: 6px 12px;
  transition: background-color 0.6s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  border-radius: 32px !important;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.innerContent{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, .05), 0px 4px 16px rgb(0, 0, 0, .15);
  background-color: white;
  padding: 48px;
}

.innerContent .product-option-values{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  margin: 0 0 8px;
  padding: 0;
}
.innerContent .product-option-value.unavailable {
opacity: .5;
}

.innerContent .product-option-value.unavailable a::before {
content: '';
background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M24 0L0 24' stroke='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
background-size: 100% 100%;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: -1px;
border-radius: 32px;
overflow: hidden;
}
#unstack__modal__content .innerContent button.add-to-bag{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  width: 100%;
  margin: 24px 0px 0px;
}
.innerContent .price {
  margin-bottom: 32px;
}

.innerContent .close-button{
  position: absolute;
  top: 40px;
  right: 40px;
  margin-left: 10px;
  order: 1;
  height: 40px;
  width: 40px;
  color: lightgrey;
  cursor: pointer;
  z-index: 999;
}
.productModal .innerContent .close-button{
position: static;
top: 0;
right: 0;
}
.innerContent .close-button:hover{
  color: grey;
}
.innerContent .product-modal-title{
  margin-bottom: 8px;
  font-size: 24px;
  margin-top: 5px;
  font-size: 3.2rem !important;
}
.innerContent p.optiongroup-name.label{
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
}
.product-modal-price{
margin-bottom: 24px;
}

@media only screen and (max-width: 767px){
  .innerContent{
      display: block;
      flex-direction: column;
      text-align: center;
      border-radius: 10px;
      width: 336px;
      max-width: 100%;
      padding: 0;
      position: relative;
  }
  #unstack__modal__content{
      transition: none;
      max-height: 100vh;
      display: flex;
      align-items: center;
      width: 100%;
  }
  .productModalImgContainer{
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
  }
  .productModalImg{
      height: 100%;
      width: 100%;
      object-fit: cover;
  }
  .innerContent .price {
    margin-bottom: 0px;
  }
  .innerContent .productModalImgContainer {
      margin: auto;
      margin-right: 0px;
      width: 100% !important;
      height: 350px !important;
  }
  .innerContent .optiongroup-name{
      text-align: left;
  }
  .modal-text-area{
    padding: 30px 30px 30px 30px;
    text-align: left;
  }
  .innerContent .product-modal-title{
    margin-top: 25px;
    max-width: auto;
  }
  .innerContent .close-button{
    position: absolute;
    margin-left: 0;
    height: 40px;
    width: 40px;
    top: 20px;
    right: 20px;
  }
  .productModal .innerContent .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.formModal{ 
.formModalTitle{
  text-align: left;
  padding-right: 48px;
}
.innerContent{
  padding: 48px 48px 38px;
}
}

/* Form Modal */
@media only screen and (max-width: 767px){
.formModal{
  .formModalTitle{
    text-align: left;
    margin-left: 10px !important;
    margin-top: 10px !important;
  }
  .innerContent{
    padding: 20px 20px 10px;
  }
}
}