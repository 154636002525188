@import '../../../../assets/styles/admin/variables.scss';

$padding: $toolbar-padding / 2;
.container {
  display: flex;
  align-items: center;

  & * {
    cursor: pointer;
  }

  height: 100%;
  box-sizing: border-box;

  position: relative;

  label {
    font-size: 1.2rem !important;
    font-weight: 300 !important;
    margin: 0 0.25rem !important;
  }

  svg {
    margin-left: 2.5px;
  }
}
.capitalize {
  text-transform: capitalize;
  cursor: pointer;
}
.Drawer {
  position: absolute;
  top: 100%;
  left: -11px;
  right: 0;
  background-color: $toolbar-tray-label-background;

  max-height: 135px;
  min-width: 159px;
  overflow-y: auto;

  > ul {
    list-style: none !important;

    > li {
      margin-left: unset !important;
      display: flex;
      align-items: end;
      padding: $padding !important;
      cursor: pointer;
      text-transform: capitalize;

      background-color: $toolbar-tray-label-background;
      transition: background-color 0.14159s;

      svg {
        margin: 0 12px;
      }

      &:hover {
        background-color: $toolbar-tray-background !important;
      }
    }
  }

  &.enter {
    opacity: 0;
  }
  &.enterActive {
    opacity: 1;
    transition: opacity 0.14159s;
  }
  &.exit {
    opacity: 1;
  }
  &.exitActive {
    opacity: 0;
    transition: opacity 0.14159s;
  }
}
