.header {
  text-align: center;
  font-weight: 100;
  padding: 0 1rem;
}

.SiteList {
  margin: 0 auto;
  max-width: 300px;
}
