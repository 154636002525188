@import '../../../../../../../../assets/styles/admin/variables.scss';
@import '../../../../../../../../assets/styles/admin/mixins.scss';

$padding: $toolbar-padding / 2;
$text: rgba(255, 255, 255, 1);
$light-purple: rgba(128, 130, 179, 0.4);

.TextInput {
  padding: 6px !important;
  background-color: $light-purple !important;
  font-family: Inter !important;
  max-width: 100px;
}

.DateTimeContainer {
  display: flex;
  align-items: center;
}
.TimeText {
  margin-left: 14px !important;
  font-family: Inter !important;
  color: rgba(255, 255, 255, 1) !important;
  background-color: transparent !important;
  max-width: 100px;
  width: 85px !important;
}
.DateInput {
  border: none !important;
  border-radius: 4px !important;
  padding: 6px !important;
  color: rgba(255, 255, 255, 1) !important;
  font-family: Inter !important;
  font-size: 12px !important;
  width: 106px !important;
  height: 28px !important;
  background-color: #8082b366 !important;
  margin-bottom: 0px !important;
  &::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
.TimeInput {
  border: none !important;
  padding: 6px !important;
  color: rgba(255, 255, 255, 1) !important;
  font-family: Inter !important;
  font-size: 12px !important;
  width: 86px !important;
  height: 28px !important;
  background-color: #8082b366 !important;
  &::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
  }
}
.InputIcon {
  margin-left: -18px;
  z-index: 2;
}

.ColorLabel {
  width: fit-content;
  white-space: nowrap;
}
.inputGroup {
  height: 40px;
}

.fieldContainer {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  max-width: 215px;
  cursor: pointer;
  .label {
    font-size: 12px;
    text-wrap: nowrap;
    color: rgba(255, 255, 255, 0.6);
  }
  .value {
    margin-left: 6px;
    display: inline-flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  & > svg {
    transform: rotate(-90deg);
    height: 16px;
    min-width: 16px;
  }

  &.isFocused {
    background-color: $light-purple;
  }
}

.Drawer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $toolbar-tray-options;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 2;
  overflow-y: auto;
  min-width: 167px;

  > ul {
    list-style: none !important;
    margin-bottom: 0;
    padding: 8px 0 !important;

    > li {
      padding: 8.5px 16px;
      max-height: 32px;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      color: $text;

      transition: background-color 0.14159s;
      &:hover {
        background-color: $light-purple;
      }
    }
  }

  &.enter {
    opacity: 0;
  }
  &.enterActive {
    opacity: 1;
    transition: opacity 0.14159s;
  }
  &.exit {
    opacity: 1;
  }
  &.exitActive {
    opacity: 0;
    transition: opacity 0.14159s;
  }
}

.grid {
  display: flex;
  min-height: 40px;
  & > span,
  & > div {
    padding: 0 12px;
  }
}

.coverNative {
  position: relative;
  left: -25px;
  margin-right: -25px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 20px;
  height: 28px;
  z-index: 0;
  background-color: #53568c;

  svg {
    width: 12px;
    height: 12px;
  }
}

.helpIcon {
  svg {
    height: 16px;
    width: 16px;
    color: rgba(255, 255, 255, 0.6);
  }

  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.toggleField {
  display: flex;
  align-items: center;
  gap: 6px;
}

.divider {
  border-right: 1px solid rgba(124, 125, 156, 0.5);
  margin: 0 4px !important;
  height: 40px;
  display: block;
}
