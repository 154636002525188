@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.scheduleTimeContainer {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  background: linear-gradient(90.09deg, #5041c1 0.07%, #191c5c 99.91%), linear-gradient(90deg, #282b71 0%, #0b0e47 100%);
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  height: auto;
  width: 320px;
  border-radius: 4px;
  padding: 20px;
  display: flex;

  @include respond-below(1442px) {
    max-width: 535px;
    width: 100%;
  }
  @include respond-below(xl) {
    justify-content: space-between;
    max-width: 100%;
  }
}

.scheduleTimeContent {
  > h3 {
    color: white;
    font-size: 18px;
    margin-bottom: 16px;
  }
  > p {
    color: white;
    opacity: 0.8;
    font-size: 14px;
    margin-bottom: 20px;
  }
  > button {
    margin-bottom: 0 !important;
  }
}

.iconContainer {
  display: flex;
  align-items: baseline;
}

.scheduleTimeModal {
  > span {
    display: none;
  }

  .closeIcon {
    height: 10px;
    width: 10px;
    cursor: pointer;
  }

  .talkToExpertContent {
    width: 387px;

    > h1 {
      font-size: 18px;
      color: $darkest-blue;
      padding: 24px;
      margin-bottom: 0px;
      font-weight: 500 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .scheduleLinkContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 28px;
      color: $dark-gray-blue;
      border-bottom: 1px solid #dee4f3;
      background-color: $light-background-color;

      &:first-of-type {
        border-top: 1px solid #dee4f3;
      }
      &:hover {
        background-color: #ecf1fb;
      }

      .linkLabel {
        display: flex;
        align-items: center;

        > span {
          margin-left: 18px;
          font-weight: 500 !important;
        }
      }

      .caretIcon {
        transform: rotate(90deg);
      }
    }
  }
}
