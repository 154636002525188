/* Inline SVG support */
// FIXME: These should be removed when the react-inlinesvg lib is removed.

.isvg {
  display: inline-block;
  vertical-align: middle;
}

.isvg.color {
  stroke: currentColor;
  fill: currentColor;
}

.isvg svg {
  height: inherit;
}
.isvg.stroke svg,
.isvg.stroke svg * {
  stroke: inherit;
}
.isvg.fill svg,
.isvg.fill svg * {
  fill: inherit;
}
