@import '../../assets/styles/admin/variables.scss';

$primary-color: #090b45;

%axisTick {
  font-size: 11px;
  font-family: $font-family;
  fill: currentColor;
  color: #8499c2;
}

.leftAxisTick text {
  @extend %axisTick;
  transform: translateY(1em);
  text-anchor: start;
}

.bottomAxisTick {
  line {
    stroke: #dce4f4;
  }

  text {
    @extend %axisTick;
    text-anchor: middle;
  }
}

// .tooltip {
//   padding: 0.5em 1em;
//   font-family: $font-family;
//   font-size: 12px;
// }

.iconLegend {
  width: auto !important;
  height: 7px;
  margin: 0 0.5em 0 0 !important;
}

.tooltipDate {
  color: rgba(255, 255, 255, 0.7);
  margin-right: 0.5em;
}

.tooltipValue {
  color: rgba(255, 255, 255, 0.9);
}
