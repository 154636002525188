@import '../../../../../../assets/styles/admin/variables';

.itemContainer {
  position: relative;

  &:not(:hover) .dragOptions {
    display: none;
  }

  .dragOptions {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    & div {
      height: 26px;
      width: 26px;
      color: #fff;
      margin: 0 2px;
      background-color: rgba(9, 11, 69, 0.3);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(9, 11, 69, 0.5);
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.14159s, background-color 0.14159s;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        background-color: rgba(9, 11, 69, 0.5);
      }

      svg {
        color: #fff;
      }
    }

    &.lightBg > div {
      margin: 0 2px;
      background-color: rgba(255, 255, 255, 0.7);
      border: 1px solid $light-gray-blue;

      &:hover {
        background-color: rgba(220, 228, 244, 0.4);
      }

      svg {
        color: $dark-gray-blue-faded;
      }
    }
  }

  &.moveDragItem {
    .dragOptions {
      margin-top: -25px;
    }
  }
}
