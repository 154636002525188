.container {
  color: white;
  position: relative;
  z-index: 2;
  text-align: center;
  width: max-content;
  transition: all 0.14159s;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: auto;
  height: 0;

  .button {
    background-color: #090b45;
    font-size: 13px;
    font-weight: 500;
    padding: 0 12px 0;
    border-radius: 30px;
    margin-bottom: 5px;
    // top: 100%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.2), 0 1px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.14159s;
    width: fit-content;
    height: 30px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #17195f;
    }
  }

  svg.addComponentButton {
    position: relative;
    margin-right: 0.5rem;
  }
}
