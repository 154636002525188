:global(.admin) .onboarding {
  background-color: #f2f4f8;

  min-height: 100vh;
  padding-bottom: 1px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 80px;

  :global(.spark-spinner) {
    margin: 4rem auto;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .iconsContainer {
      display: flex;
      align-items: center;
      gap: 24px;

      .icon {
        height: 80px;
        width: 80px;
      }
      .plus {
        width: 32px;
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      text-align: left;
    }
  }

  .panelContainer {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .panel {
    width: 896px;
    padding: 32px;
    color: #000000;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px #0000000d;
    box-shadow: 0px 0px 32px 0px #0000000d;

    .head {
      h2 {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        margin: 36px 0;
      }
    }

    .titleContainer {
      display: flex;
      align-items: center;

      .completeCheckIcon,
      .incompleteCheckIcon {
        cursor: pointer;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        margin: 0 8px;
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 40px 16px 0 40px;
      p {
        width: 500px;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin: 0;
      }
      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
      }
      button {
        width: 145px;
        height: 48px;
        gap: 12px;
        border-radius: 4px;
        background: #0e1521;
        border: 1px solid #ffffff;
        box-shadow: 0px 1px 3px 0px #0000001f;
        transition: opacity 0.2s ease;

        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        color: #ffffff;

        &:hover {
          opacity: 0.8;
        }
      }

      .textContainer {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        width: 428px;

        .list {
          padding: 20px 0;

          .step {
            display: flex;
            align-items: flex-start;
            margin-bottom: 12px;
            gap: 8px;

            .stepNumber {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 32px;
              border-radius: 16px;
              background: #e6e9ed80;

              font-size: 14px;
              font-weight: 400;
              line-height: 25px;
              text-align: center;
            }
            .stepText {
              font-size: 14px;
              font-weight: 500;
              line-height: 25px;
              text-align: left;
              margin-top: 4px;
              span {
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
        }
      }

      .videoContainer {
        width: 300px;

        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        text-align: center;
        color: #5c6778;

        .video {
          width: 100%;
          height: 170px;
        }
      }
    }

    .templatesBody {
      display: flex;
      flex-direction: column;
      padding: 40px 16px 0 40px;

      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #0e1521cc;
      }

      .templatesContainer {
        padding-top: 16px;
        position: relative;
        width: 100%;

        .templateFilters {
          display: flex;
          margin-bottom: 20px;
          box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
          gap: 32px;

          .templateFilter {
            height: 52px;
            padding: 16px 0px 16px 0px;
            gap: 10px;

            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            text-align: center;

            &:hover {
              cursor: pointer;
            }

            &.isSelected {
              color: #202223;
              box-shadow: 0px -3px 0px 0px #2bcc7e inset;
            }
          }
        }

        .templates {
          display: flex;
          overflow-x: auto;
          gap: 24px;
          // height: 256px;

          .template {
            flex: 0 0 auto;
            width: 154px;
            gap: 10px;

            img {
              margin: 0;
              border-radius: 8px;
              box-shadow: 0px 2px 6px 0px #00000014;
              transition: opacity 0.2s ease;

              &:hover {
                cursor: pointer;
                opacity: 0.8;
              }
            }
          }

          .arrowsContainer {
            position: absolute;
            z-index: 1;
            top: 57%;
            width: 100%;
            max-width: 100vw;
            display: flex;
            left: 50%;
            transform: translate(-50%, -50%);

            .scrollRight,
            .scrollLeft {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              cursor: pointer;
              margin: 0 5px;

              &:hover {
                cursor: pointer;
                opacity: 0.8 !important;
              }
            }
            .scrollLeft {
              left: 0;
              transform: rotate(180deg);
            }
            .scrollRight {
              right: 0;
            }
          }

          .spinner {
            flex-shrink: 0;
            color: #2bcc7e;
            margin: auto 10px;
          }
        }
      }
    }
  }

  .helpChat {
    display: flex;
    gap: 6px;
    width: 896px;

    .chatIcon {
      background: #e5e9f1;
      width: 40px;
      height: 40px;
      gap: 10px;
      border-radius: 30px;
      flex-shrink: 0;
    }

    .chatText {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }
    .chatLink {
      color: inherit;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
