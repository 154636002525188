@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .options {
    @include unstyled-button;
    text-align: right;
    padding: 0.5rem;
    color: #647fb4;
    display: flex;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 14px;
      cursor: pointer;
    }
  }
  .addApiKeyButton {
    float: right;
  }
  .noKeyText {
    color: var(--text-secondary, #647fb4);
  }
}
