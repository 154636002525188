// Stripe card element
// https://stripe.com/docs/stripe-js/reference#element-options

@import '../../assets/styles/admin/variables.scss';

.base {
  max-width: 500px;
  cursor: text;

  // height: 38px;
  padding: 10px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: rgba(0, 0, 0, 0.03);
  border: 0;
  border-radius: 2px;
  font-size: 1.5rem;
  box-shadow: none;
  box-sizing: border-box;
  transition: border 0.12s;
  border-left: 2px solid rgba(0, 0, 0, 0.05);
}

.focus {
  border-left: 2px solid $primary-color;
  outline: 0;
}

.invalid {
  border-left-color: $danger-color;
}

// .empty {}
// .complete {}
// .webkitAutofill {}
