@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

:global(.admin) {
  article.PageListZeroState {
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    padding: 4rem;

    > * {
      margin: 0;
    }
    > * + * {
      margin-top: 1rem !important;
    }

    h2 {
      font-weight: 500;
      font-size: 2.4rem;
      color: $header-color-gray;
    }

    p {
      font-weight: 400;
      font-size: 1.6rem;
      max-width: 32em;
      margin-left: auto;
      margin-right: auto;
      color: $text-color-light;
    }

    .ImagesContainer {
      height: 450px;
      width: 630px;
      position: relative;
      display: inline-block;

      .LandingPageTablet {
        position: absolute;
        width: 523px;
        height: auto;
        left: 0px;
      }

      .LandingPageMobile {
        position: absolute;
        width: auto;
        height: 495px;
        right: -152px;
        top: 60px;
      }

      .ProductTablet,
      .ProductMobile {
        position: absolute;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          object-fit: cover;
          min-width: 100%;
          min-height: 100%;
        }
      }
      .ProductTablet {
        width: 187px;
        height: 218px;
        left: 96px;
        top: 91px;
      }
      .ProductMobile {
        width: 136px;
        height: 158px;
        right: 17px;
        top: 118px;
      }

      .ThumbnailContainerTablet {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 91px;
        left: 73px;
        .ThumbnailTablet {
          width: 17px;
          height: 21px;
          margin-bottom: 4px;
          z-index: 2;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
          }
        }
      }

      .ThumbnailContainerMobile {
        position: absolute;
        display: flex;
        top: 282px;
        left: 495px;
        .ThumbnailMobile {
          width: 20px;
          height: 24px;
          margin-right: 7px;
          z-index: 2;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
          }
        }
      }

      .LandingPageRightTab,
      .LandingPageLeftTab,
      .LandingPageRightMobile,
      .LandingPageLeftMobile {
        position: absolute;
        width: 18px;
        z-index: 2;
      }
      .LandingPageRightTab {
        left: 259px;
        top: 191px;
      }
      .LandingPageLeftTab {
        left: 102px;
        top: 191px;
      }
      .LandingPageRightMobile {
        left: 584px;
        top: 188px;
      }
      .LandingPageLeftMobile {
        left: 488px;
        top: 188px;
      }
    }
  }
}
