.thumbnail {
  width: 65px;
  height: 65px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  flex-direction: column;
  border-radius: 2px;
  font-size: 0.55em;

  & > span {
    flex-basis: calc(50% - 2px);
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    background-size: cover;
    background-position: center;
  }
}

.fixedImageTile {
  width: calc(50% - 1px);
}

.additionalCount {
  color: rgba(224, 225, 229, 255);
  box-shadow: 0px 0px 0px 200px rgba(0, 8, 26, 0.5) inset;
  justify-content: center;
  align-items: center;
}

.defaultImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff3fb;
  border-radius: 2px;

  svg {
    width: 30%;
    height: 30%;
  }
}
