@import '../../../assets/styles/admin/variables.scss';

.container {
  position: relative;
  overflow: hidden;

  font-size: 1.5rem; // This MUST match the input font-size.
}

.prefix,
.postfix,
.valueSizer {
  line-height: 38px;
  color: $text-color-light;
  pointer-events: none;
}

.prefix {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 10px;
}

.postfix {
  position: absolute;
  top: 0;
  right: 0;
}

.valueSizer {
  position: absolute;
  opacity: 0;

  // account for the left border width
  padding-left: 3px;
}
