@use 'sass:math';

// Colors
$darkest-blue: #14213d;
$dark-blue: #090b45;
$blue: #1495cd;
$light-blue: #f4fbff;
$dark-gray-blue: #647fb4;
$dark-gray-blue-faded: #8499c2;
$gray-blue: #b2c1df;
$light-gray-blue: #dce4f4;
$purple: #6f7fe2;
$green: #2ec4b6;
$money-green: #44aa88;
$orange: #ffa847;
$red: #e05281;
$light-gray: #bbbbbb;
$medium-gray: #808284;
$dark-gray: #303438;
$deep-purple: #4040d9;

// Semantic colors
$primary-color: $blue;
$secondary-color: $dark-gray-blue-faded;
$danger-color: $red;
$success-color: $green;
$disabled-color: rgba(0, 0, 0, 0.25);

// Dark background (.dark-bg) colors
$dark-bg-primary-color: lighten($primary-color, 10%);
$dark-bg-danger-color: lighten($danger-color, 10%);
$dark-bg-text-color: rgba(255, 255, 255, 0.85);

// Background colors
$light-background-color: #f8fafe;
$dark-background-color: $dark-blue;
$disabled-background-color: #dce4f4;
$background-color: $light-background-color;
$admin-bar-background-color: $dark-background-color;
$nav-dark-background: rgba(9, 11, 69, 0.05);
$background-medium: #eff3fb;

// Border colors
$gray-line-color: #dddddd;
$gray-border-color: #eee;
$border-color: $gray-border-color;

// Typography colors
$text-color: $darkest-blue;
$text-bold: $darkest-blue;
$text-secondary: $dark-gray-blue;
$text-color-inverted: #fff;
$text-color-light: $dark-gray-blue;
$text-color-disabled: $disabled-color;
$header-color-gray: $text-color;

// Link colors
$link-color: $primary-color;

// Fonts
$font-family: 'Inter', 'Helvetica', sans-serif;

// Text shadows
$text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

// Layout dimensions
$nav-width: 256px;
$admin-bar-height: 72px;
$side-drawer-width: 400px;

// Gutters
$gutter: 2%;
$nav-gutter: 20px;
$table-gutter: 20px;
$admin-panel-gutter: 1.5rem;

// Selectors
$text-field-selectors: "input[type='email'],input[type='number'],input[type='search'],input[type='text'],input[type='phone'],input[type='email'],input[type='tel'],input[type='url'],input[type='date'],input[type='password'],input[type='file'],input[type='time']";

// Toolbars
$toolbar-height: 40px;
$toolbar-background: $dark-background-color;
$toolbar-tray-background: #363971;
$toolbar-tray-options: #393b71;
$toolbar-tray-label-background: #1e2258;
$toolbar-border-radius: 2px;
$toolbar-padding: 1.5rem;
$toolbar-font-size: 1.1rem;
$toolbar-icon-size: 16px;
$toolbar-label-color: rgba(255, 255, 255, 1);
$toolbar-placeholder-color: rgba(255, 255, 255, 0.6);

// Tables
$table-head-border-color: $gray-blue;
$table-head-background-color: $light-gray-blue;
$table-body-border-color: $light-gray-blue;
$table-highlight-color: #f8fafe;
$table-head-text-color: $header-color-gray;
$table-body-text-color: $header-color-gray;

// Fixed header
$fixed-header-height: 70px;

// Responsive layout breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// Grid system column widths
$col-1: percentage(math.div(1, 12));
$col-2: percentage(math.div(2, 12));
$col-3: percentage(math.div(3, 12));
$col-4: percentage(math.div(4, 12));
$col-5: percentage(math.div(5, 12));
$col-6: percentage(math.div(6, 12));
$col-7: percentage(math.div(7, 12));
$col-8: percentage(math.div(8, 12));
$col-9: percentage(math.div(9, 12));
$col-10: percentage(math.div(10, 12));
$col-11: percentage(math.div(11, 12));
$col-12: percentage(math.div(12, 12));

// Unsplash
$unsplash-grid-gutter: 2rem;

// NotificationBar
$notification-bar-height: 4rem;

// Product
$product-border-color: #dee4f3;
