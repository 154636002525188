@import '../../assets/styles/admin/variables.scss';

.NewBlog {
  header {
    background: $admin-bar-background-color;
    color: #fff;
    height: 80px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    // justify-content: center;

    * {
      margin: 0;
    }

    > * {
      display: flex;
      flex: 1;
      justify-content: center;
    }
    > *:first-child > * {
      margin-right: auto;
    }
    > *:last-child > * {
      margin-left: auto;
    }

    .logo {
      display: block;

      img {
        display: block;
        height: 2rem;
        width: auto;
      }
    }

    h1 {
      font-weight: 400;
      font-size: 1.8rem;
      color: #fff;
    }

    .close {
      color: rgba(255, 255, 255, 0.5);
      svg {
        display: block;
        height: 16px;
      }
    }
  }

  :global(.spark-spinner) {
    margin: 8rem auto;
  }

  form {
    margin: 4rem auto 3rem;
    max-width: 50rem;
  }
}

.actionLink {
  a:after {
    font-family: 'fontello';
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e802';
    font-size: 0.8em;
  }
}
