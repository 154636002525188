@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .container {
  }

  .initials {
    display: inline-block;
    line-height: 2rem;
    height: 2rem;
    width: 2rem;
    vertical-align: middle;
    margin: 0 1rem 0 0;
    border-radius: 1rem;
    background: $purple;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    color: white;
    text-align: center;
    text-transform: uppercase;
  }

  .options {
    @include unstyled-button;
    text-align: right;
    margin-right: 14px;
    padding: 0.5rem;
    vertical-align: middle;
    color: $gray-blue;

    svg {
      height: 20px;
    }
  }
  .resendActionLink {
    p {
      color: #647fb4;
      font-size: 1.4rem;
      a {
        color: inherit;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.imageUploader {
  cursor: pointer;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 8px 12px;
    background-color: #f8fafe;
    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border: 1px solid #d7dae2;
    border-radius: 4px;
    font-size: 1.5rem;
    box-sizing: border-box;
    transition: border 0.12s;

    span.imageViewer {
      border-radius: 1rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      height: 2rem;
      width: 2rem;
      border: 1px solid $purple;
      background-color: $gray-blue;
    }
    span.fileName {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  form {
    display: none;
  }
}

.resendActionLink {
  p {
    color: #647fb4;
    font-size: 1.4rem;
    a {
      color: inherit;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
