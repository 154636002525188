@import '../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .container {
    font-size: 1.4rem;
    color: rgba(20, 33, 61, 0.8);
  }

  .iconFilter {
    height: 1.2rem;
    width: auto;
    vertical-align: -0.05rem;
    margin: 0 0.5rem 0 0;
  }

  .control {
    // display: inline-block;
    // margin: 0 0.5rem;
  }
}

.insightsFilterContainer {
  position: sticky;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: $background-color;

  font-size: 1.4rem;
  color: #14213d;
}
