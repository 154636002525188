.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(41, 50, 65, 0.5);

  .enter & {
    opacity: 0.1;
  }
  .enterActive & {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .exit & {
    opacity: 1;
  }
  .exitActive & {
    opacity: 0.1;
    transition: opacity 0.1s linear;
  }
}

.modal {
  .modalClose {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    transition: color 0.14159s;
    position: absolute;
    left: 94%;
    top: 5%;

    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }

    svg {
      height: 14px;
      vertical-align: middle;
    }
  }
  position: fixed;
  z-index: 999;
  background: #fefefe;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  overflow: auto;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
