@import '../../../assets/styles/admin/variables.scss';

.sitemap {
  p {
    font-size: 1.6rem;
    &:last-child {
      margin: 0;
    }
  }

  a {
    margin-right: 2rem;
    font-size: 1.5rem;
    color: $link-color !important;
  }

  .copyLink {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 6px 12px;
    border-radius: 2px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.8);
    user-select: all;
  }

  div.row {
    margin: 0 !important;
    padding: 1.5rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    border-top: 1px solid #eee;

    &.firstRow {
      border-top: none;
    }

    .label {
      display: flex;
      align-items: center;
      flex: 0 0 50%;

      span {
        font-size: 1.5rem;
        margin-left: 1rem;
      }

      &.isDisabled {
        opacity: 0.4;
      }
    }
    .links {
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        cursor: pointer;
      }

      a.disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: #cdcdcd !important;
      }

      .errors {
        color: $danger-color;
        margin-right: 1rem;
      }
    }
  }
}
