@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

.header {
  display: flex;
  align-items: center;

  .searchContainer {
    margin-left: auto;
    display: flex;
    align-items: baseline;
  }

  svg {
    margin-top: 0 !important;
  }

  a {
    cursor: pointer;
  }
}

.productsList,
.collectionList {
  .title {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: fit-content;
    cursor: pointer;

    .epName {
      font-size: 1.4rem;
    }

    & > span.arrowContainer {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > svg {
        transform: rotate(-90deg);
        color: #647fb4;

        &.isOpen {
          transform: rotate(0);
        }
      }

      &.loader {
        animation: rotation 2s infinite linear;

        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }

          to {
            transform: rotate(359deg);
          }
        }
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      object-fit: cover;
      margin-bottom: 0;
      flex-shrink: 0;
    }

    .collectionImages {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 35px;
      gap: 2px;

      img {
        display: flex;
        flex-basis: calc(50% - 40px);
        justify-content: center;
        flex-direction: column;

        width: 40px;
        height: 40px;
        border-radius: 2px;
        object-fit: cover;
        margin-bottom: 0;
      }
    }

    .label {
      text-transform: uppercase;
      font-size: 10px;
      padding: 1px 5px;
      background-color: $green;
      color: #fff;
      border-radius: 2px;
      min-width: min-content;
    }
  }
}

.addNewButton {
  display: flex;
  align-items: center;
  height: 0;
  padding: 1.5rem 2rem;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;

  color: #ffffff !important;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0.02rem;
  text-align: center;
  line-height: 3.8rem;

  background: $purple;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  transition: opacity 0.12s;

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.75;
  }

  svg {
    color: #ffffff;
    margin-right: 1rem;
  }
}

.dropDownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -21px;
  top: 18px;
  background-color: #fff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 180px;
  padding: 8px 0;
  justify-content: space-between;
  border-radius: 4px;

  span,
  a {
    padding: 5px 0 5px 30px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color: #4a4a4a;

    &:hover {
      background-color: $light-gray-blue;
    }
  }

  .divider {
    width: 90%;
    margin: 0 auto;
    border-bottom: solid thin $light-gray-blue;
  }

  .optionsLoader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
    width: 100%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.options {
  @include unstyled-button;
  box-sizing: content-box;
  height: 20px;
  width: 20px;
  flex: 0 0 auto;
  padding: 0.5rem 0;
  vertical-align: middle;
  color: $gray-blue;

  box-shadow: none;
  transition: opacity 0.14159s;

  &,
  &:focus,
  &:active {
    opacity: 1;
  }

  svg {
    height: 20px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.defaultLabel {
  border: 1px solid #7d8fb2;
  padding: 1px 5px;
  color: #7d8fb2;
  border-radius: 2px;
  margin-left: 5px;
}
