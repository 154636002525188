@import '../../assets/styles/admin/variables.scss';

.ZeroState {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: hidden;

  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 5rem;

  > * {
    margin: 0;
  }
  > * + * {
    margin-top: 2rem !important;
  }

  .Image {
    display: block;
    height: 50px;
    margin-bottom: 3rem;
  }

  .Header {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: -1rem !important;
    color: $header-color-gray;
  }

  .Muted {
    font-size: 1.4rem;
    max-width: 28em;
    margin: 0 auto;
    color: $text-color-light;
  }
}
