@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.inviteCollaboratorContent {
  padding: 20px 48px;
  display: flex;
  @include respond-below(xl) {
    flex-wrap: wrap-reverse;
    & p {
      width: 60%;
    }
  }

  & p {
    margin-bottom: 20px;
  }

  .quickstartLink {
    margin-left: 20px;
  }

  .footnote,
  .footnote > a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $dark-gray-blue;

    > a {
      text-decoration: underline;
    }
  }
}
