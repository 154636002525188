.container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  // background: #000;
  // color: #eee;

  * {
    margin: 0;
  }
}

.logginIn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  :global(.spark-spinner) {
    margin-right: 2rem;
  }

  .token {
    font-size: 1.2rem;
    color: #aaa;
    white-space: nowrap;
  }
}
