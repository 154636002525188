@import '../../../assets/styles/admin/variables.scss';

.helpLinksContainer {
  margin-top: 48px;
  margin-left: 20px;
  padding-right: 20px;

  > h4 {
    position: static;
    font-size: 11px;
    text-transform: uppercase;
    color: #647fb4;
    margin-bottom: 8px;
  }

  ul {
    margin-bottom: 3rem;
  }

  > ul li {
    > a {
      $padding-left: 18px;
      $border-width: 2px;
      margin-left: -$nav-gutter;
      padding-left: $nav-gutter - $border-width;
      border-left: solid $border-width rgba(0, 0, 0, 0);

      display: block;
      padding-top: 8px;
      padding-bottom: 8px;

      color: #090b45;
      font-size: 1.4rem;

      opacity: 0.6;

      &:hover {
        color: #090b45;
        border-left-color: $nav-dark-background;
        opacity: 1;
      }

      &:hover,
      &:global(.active) {
        background: linear-gradient(to right, $nav-dark-background, rgba(0, 0, 0, 0));
      }

      &:global(.active) {
        color: #090b45;
        border-color: $blue;
        opacity: 1;
      }

      &[disabled] {
        color: $disabled-color;
        background: none;
        cursor: default;
      }

      svg {
        margin-right: 10px;
        height: 18px;
        width: 18px;
        vertical-align: -4px;
        transition: none;
      }

      &:global(.active) svg {
        opacity: 1;
      }

      &[disabled] svg {
        filter: none;
        transform: none;
        opacity: 0.3;
      }
    }
  }
}
