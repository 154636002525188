.iconContainer {
  svg {
    color: #647fb4;
  }

  &:hover,
  &:active,
  &:focus {
    svg {
      color: #102040;
    }
  }

  &:active {
    background-color: #f7f9fc;
  }

  &:focus {
    border: 2px solid #7373e5;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 2px solid transparent;
  cursor: pointer;
}

.small {
  svg {
    width: 16px;
    height: 16px;
  }

  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.medium {
  svg {
    width: 20px;
    height: 20px;
  }

  width: 32px;
  height: 32px;
  border-radius: 6px;
}

.large {
  svg {
    width: 20px;
    height: 20px;
  }

  width: 40px;
  height: 40px;
  border-radius: 8px;
}
