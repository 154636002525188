@import '../../../assets/styles/admin/variables.scss';

.box {
  position: relative;

  &.selected {
    border: 2px solid $blue;
  }
}

.boxContent {
  transition: box-shadow 0.14159s, border-radius 0.14159s, padding 0.14159s;

  position: relative;
}

.mediaForegroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  pointer-events: none;
  z-index: 2;

  img {
    width: inherit;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
.labelElement {
  cursor: pointer;
}
