@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .toolbar {
    position: relative;
    background-color: $toolbar-background;
    height: $toolbar-height;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: center;

    font-size: $toolbar-font-size;

    transition: border-radius 0.14159s;
  }
  .toolbar.toolbarTopTransparent {
    background: transparent;
  }

  .toolbar.trayIsStyles {
    min-width: 500px;
  }

  // .toolbar.lowerTrayIsOpen {
  // border-radius: $toolbar-border-radius $toolbar-border-radius 0 0;
  // }

  .toolbar > *:not(.toolbar) {
    box-sizing: border-box;
    padding: 0 $toolbar-padding;
    color: #fff;
  }

  .toolbar button {
    @include unstyled-button;
    display: flex;
    align-items: center;
  }

  .toolbar > :first-child {
    border-top-left-radius: $toolbar-border-radius;
  }

  .toolbar > :last-child {
    border-top-right-radius: $toolbar-border-radius;
  }

  .toolbar.extendHeight {
    height: 46px;
    align-items: center;
  }
  .toolbar:not(.borderless) > :not(:last-child),
  .toolbar:not(.borderless) > :last-child {
    // <- Yes. Believe it. It solves a silly specificity issue...
    transition: border-color 0.14159s;
    border-right: solid thin rgba(255, 255, 255, 0.14159);

    &.open {
      border-right-color: transparent;
    }
  }

  .toolbar .Button > svg {
    outline: none;
  }
  .toolbar .Button {
    padding: 0 $toolbar-padding;
    color: #fff;

    &[disabled],
    &[disabled]:hover {
      color: #fff;
      opacity: 0.5;
    }

    > *:not(:last-child) {
      margin-right: $toolbar-padding / 2;
    }
  }

  .toolbar .RadioButtonSelected {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }

  .toolbar .ButtonGroup {
    display: flex;

    .Button {
      padding: 0 ($toolbar-padding / 2);

      &:first-child {
        margin-left: -$toolbar-padding;
        padding-left: $toolbar-padding !important;
      }

      &:last-child {
        margin-right: -$toolbar-padding;
        padding-right: $toolbar-padding;
      }
    }
  }

  .toolbar .ButtonGroupCompact {
    @extend .ButtonGroup;

    .Button {
      padding: 0 ($toolbar-padding / 4);
    }
  }

  .toolbar a {
    @extend .Button;
    color: #fff !important;
    display: flex;
    align-items: center;
  }

  .toolbar .trayIcon {
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: background-color 0.14159s;
    cursor: pointer;
    width: 40px;
    padding: 10px;
  }

  .toolbar .trayIcon.open {
    background-color: $toolbar-tray-background;
  }

  .toolbar .tray {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: $toolbar-height;
    height: $toolbar-height;
    background-color: $toolbar-tray-background;
    border-radius: 0 0 $toolbar-border-radius $toolbar-border-radius;

    display: flex;
    align-items: stretch;
    cursor: default;

    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: opacity 0.14159s, transform 0.14159s;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   z-index: -1;
    //   top: 0;
    //   left: -15px;
    //   right: -15px;
    //   height: calc(100% + 15px);
    //   background: transparent;
    // }
  }
  .toolbar .invisible {
    background-color: transparent;
  }

  .toolbar.trayIsOpen .tray:not(.open) {
    transition: opacity 0.14159s 0.14159s, transform 0.14159s;
  }

  .toolbar.trayIsOpen .tray {
    transform: translateY(0);
  }

  .toolbar.isCollectionToolbar {
    background: none;
  }

  .toolbar .tray.enterActive,
  .toolbar .tray.enterDone {
    opacity: 1;
    pointer-events: auto;
  }

  .TrayContent {
    flex: 1 1 auto;
    // padding: 0 0 0 ($toolbar-padding / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: fit-content;
  }

  .toolbar .TrayContent > * {
    padding: 0 $toolbar-padding / 2;
  }

  .LabeledToolbar {
    width: 100%;
    padding: 0 !important;
    background-color: $toolbar-tray-background;
    display: flex;
    align-items: stretch;
    border-radius: 2px;
    overflow: hidden;
  }

  .TrayLabel {
    position: relative;
    flex: 0 0 auto;
    background-color: $toolbar-tray-label-background;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    padding: 0 $toolbar-padding * 2 0 $toolbar-padding;
    border-radius: 0 0 0 $toolbar-border-radius;

    font-size: 1rem;
    letter-spacing: 0.05em;
    font-weight: 500;
    text-transform: uppercase;

    &:after {
      $border-width: $toolbar-height / 2;

      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-top: $border-width solid $toolbar-tray-background;
      border-bottom: $border-width solid $toolbar-tray-background;
      border-left: 10px solid transparent;
    }
  }

  .Group {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .Label {
    color: $toolbar-label-color;
    margin-right: $toolbar-padding / 2;
    font-size: 12px;

    &.group {
      font-weight: 500;
    }
    &.active {
      color: $primary-color;
    }
  }

  .toolbar input.TextInput {
    @include unstyled-input;

    flex: 1;
    height: $toolbar-height;
    width: 100%;
    font-size: $toolbar-font-size;
    color: #fff;
    font-size: 12px !important;

    &:focus {
      background-color: rgba(128, 130, 179, 0.4) !important;
    }

    &::placeholder {
      color: $toolbar-placeholder-color;
    }

    &.TextInputBorder {
      height: auto;
      // border-bottom: solid thin $toolbar-placeholder-color !important;
      background: rgba(128, 130, 179, 0.25);
      padding: 0.15em;
    }
  }

  // OverTray

  .OverTray {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $toolbar-background;

    &.enter {
      opacity: 0;
    }
    &.enterActive {
      opacity: 1;
      transition: opacity 150ms;
    }
    &.enterDone {
      opacity: 1;
    }
    &.exit {
      opacity: 1;
    }
    &.exitActive {
      opacity: 0;
      transition: opacity 75ms;
    }
  }
}
