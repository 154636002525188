button,
.button,
img {
  margin-bottom: 1rem;
}

// TODO - handle this in forms.scss instead of here?
input,
textarea,
select,
fieldset {
  margin-bottom: 2.5rem;
  // NOTE: fieldset **may** want 1.5rem;
}

pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
  margin-bottom: 2.5rem;
}
