@import '../../../../assets/styles/admin/variables.scss';

.colorsStylesHeader {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 23px !important;
  svg {
    color: $blue;
    margin: 0 10px 0 auto;
    cursor: pointer;
  }
}

.noColorState {
  p {
    margin: 20px auto !important;
  }
}

.colorsContainer {
  display: flex;
  flex-wrap: wrap;

  .colorItem {
    background-color: #fff;
    font-size: 13px;
    margin: 0 20px 20px 0;
    padding-bottom: 10px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

    div.colorViewer {
      height: 100px;
      width: 140px;
      margin: 10px;
      border-radius: 2px;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
      transition: all 0.14159s;

      &:hover {
        cursor: pointer;
        opacity: 0.85;
      }
    }
    div.colorValue {
      display: flex;
      text-align: center;
      height: auto;
      padding: 0.25rem 2rem;
      position: relative;
      span.value {
        color: $text_color;
        flex: 1;
      }

      div {
        margin-right: 10%;
        position: absolute;
        bottom: 95px;
        right: 0;
        border-radius: 50%;
        padding: 6px 8px 3px;
        background-color: rgba(0, 0, 0, 0.1);

        svg {
          g {
            g {
              fill: white;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    div.value {
      color: $text_color_light;
      flex: 1;
      text-align: center;
      background-color: #dce4f4;
      font-size: 10px;
      padding: 2px 8px;
      border-radius: 20px;
      margin: 0.6rem auto;
      width: fit-content;
      cursor: default;
    }
  }
}

.colorDeleteActionText {
  margin-top: 1rem !important;
  color: $text-color-light !important;
  font-size: 1.4rem;
  span {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
}

.colorPicker {
  margin-top: 1rem;
  margin-bottom: 2rem;

  :global(.sketch-picker) {
    box-shadow: none !important;
    & > div:nth-last-of-type(-n + 2) {
      display: none !important;
    }
  }
}

.inputContainer {
  position: relative;
  .colorSwatch {
    border-radius: 50%;
    border: 1px solid rgba(181, 192, 220, 0.5);
    height: 18px;
    width: 18px;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }

  .colorValueInput {
    padding-left: 4rem !important;
  }
}
