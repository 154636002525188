@import '../../assets/styles/admin/variables.scss';

:global(.admin) {
  .integrations {
    padding: 0;
  }

  .integration + .integration {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .integration {
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    .icon {
      flex: 0;
      height: auto;
      width: 20px;
      margin: 0 15px 0 0;
    }

    .title {
      flex: 1 1 auto;
    }

    label {
      display: inline;
      font-size: 0.9em;
      font-weight: normal;
      color: $dark-gray-blue;

      .statusIndicator {
        display: inline-block;
        $size: 6px;
        height: $size;
        width: $size;
        vertical-align: 2px;
        margin-right: 6px;
        border-radius: 999999px;
        background-color: $green;
      }
    }

    .value {
      font-size: 0.9em;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }

    .config {
      flex: 0 0 auto;

      :not(:global(.button)) {
        display: inline-block;
        line-height: normal;
      }

      * {
        margin: 0;
      }

      * + * {
        margin-left: 1rem;
      }

      form {
        input:not([type='submit']) {
          box-sizing: border-box;
          height: 3.2rem;
          width: auto;
        }

        input[type='submit'] {
          margin-left: 10px;
        }
      }
    }

    .comingSoon {
      font-size: 0.9em;
      color: #aaa;
      font-weight: 300;
      vertical-align: middle;
    }

    .add {
      color: $primary-color;
      height: 28px;
      width: 28px;
      vertical-align: middle;
      cursor: pointer;
      transition: all 0.14159s;

      &:hover {
        color: lighten($primary-color, 10%);
      }
    }
    .edit {
      color: $primary-color;
      vertical-align: middle;
      cursor: pointer;
      transition: all 0.14159s;

      &:hover {
        color: lighten($primary-color, 10%);
      }
    }

    .remove {
      color: $danger-color;
      vertical-align: middle;
      cursor: pointer;
    }

    .cancel {
      color: #aaa !important;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .contactUs {
    margin-bottom: 4rem;
    font-size: 1.4rem;
    text-align: center;
    color: $dark-gray-blue;
  }

  .actionLink {
    text-decoration: underline;
    color: inherit;
  }
}

.popup {
  background-color: #ffffff;
  width: 464px;
  height: 377px;
  color: #14213d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  text-align: center;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  .logos {
    position: relative;
    margin: 48px 0px 40px 0px;
    width: 230px;
    align-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .shopify {
      height: 64px;
      width: 64px;
    }
    .elasticpath {
      height: auto;
      width: 64px;
      border-radius: 8px;
    }
    .add {
      color: #647fb4;
      height: 20px;
      width: auto;
      margin: 0px 0px 20px 0px;
    }
  }

  p {
    width: 400px;
  }

  .title {
    color: #202223;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    margin: 0 auto 16px auto;
  }

  .instructions {
    color: #6d7175;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 auto 0 auto;
  }

  .spinner {
    margin: 40px auto 48px auto;
  }
}

.key {
  svg {
    cursor: pointer;
  }
}

.embeddedAppView {
  .bgContainer {
    height: 100vh;
    min-height: 815px;
    img {
      position: absolute;
      width: 100%;
    }
    background-color: #edeeef;
  }

  .contentContainer {
    background-color: transparent;
    position: absolute;
    width: 100%;
    top: 64px;
    padding-bottom: 64px;

    .content {
      width: 640px;
      margin: auto;
      h1 {
        // font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #ffffff;
      }

      .card {
        width: 640px;
        height: 157px;
        display: flex;
        column-gap: 10px;
        padding: 32px;
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        margin: 16px 0px;

        svg {
          flex: 1;
          margin-left: -10px;
        }
        div {
          flex: 6;
          strong {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            margin-bottom: 8px;
          }
          p {
            font-style: normal;
            font-weight: normal;
            line-height: 140%;
            font-size: 15px;
            margin-top: 8px;
            color: #6d7175;
          }
        }
        a {
          flex: 1;
          height: min-content;
        }
        button {
          height: min-content;
        }
      }
    }
  }
}

.failureDialoge {
  width: 95%;
  max-width: 33em;
  margin: 4em auto 0;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

  div {
    font-size: 15px;
    border: 1px solid $dark-blue;
    border-top: $dark-blue solid 4px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: white;
    padding: 7px 12% 0;

    h3 {
      color: $dark-blue;
      font-size: 18px;
    }
  }
  & > p {
    margin: 0 0 1em;
    padding: 1em;
    background-color: #fff;
    border: 1px solid $dark-blue;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 15px;
  }
}

.error {
  color: red !important;
}
