.button {
  background: none;
  border: none;
  // border: solid thin #888;
  // border-radius: 5px;
  font-size: 1.4rem;
  color: #707070;
  margin: 0 2rem 0 0 !important;

  transition: hover 0.14159s;

  &:hover {
    opacity: 0.7;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.spinner {
  margin: 100px auto;
}

.versions {
  font-size: 1.4rem;

  td {
    vertical-align: top;
  }
}

.dateCol {
  width: 100%;
}

.time {
  font-size: 0.8em;
  color: #888;
}
