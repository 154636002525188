.ViewStatistics {
  position: relative;
  margin-bottom: 3rem;
}

.zeroState {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;

  div {
    padding: 1rem 2.2rem;
    background: rgba(9, 11, 69, 0.4);
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 3rem;
  }
}
