@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

$padding: 2rem;

.container {
  max-width: 1200px;
  margin: 0 auto 4rem;
}

.container.fullWidth {
  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;

    * + * {
      margin-left: 2rem;
    }
  }

  .search {
    margin-top: 0;
  }
}

.container:not(.fullWidth) {
  .search {
    position: absolute;
    top: 15px;
    left: 115px;
    width: 180px;
  }
}

.components {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: -$padding;
}

.component {
  box-sizing: border-box;
  padding: $padding;

  flex: 0 0 100%;
}

.container.fullWidth {
  .component {
    @include respond-above(sm) {
      flex: 0 0 50%;
    }
    @include respond-above(md) {
      flex: 0 0 (100% / 3);
    }
    @include respond-above(lg) {
      flex: 0 0 25%;
    }
  }
}

.searchZeroState {
  display: flex;
  height: 165px;

  h3 {
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    width: calc(100vw - 4rem);
    margin-top: 7rem;
  }
}
