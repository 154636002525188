.iconTrayOpened {
  path:nth-child(1) {
    fill: #0099f2;
  }
  path:nth-child(2) {
    fill: #0099f2;
  }
}
.group {
  min-height: 40px;
}
.maxWidthInput {
  width: 50px !important;
  min-width: 25px;
  max-width: 100px;
  cursor: pointer;
}
.popperContainer {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 188px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  isolation: isolate;

  flex: none;
  order: 2;
  flex-grow: 0;
}
.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px 0px 18px;
  gap: 8px;

  width: 188px;
  height: 28px;

  background: #ffffff;

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  z-index: 3;

  cursor: pointer;

  &:hover {
    background: #eff3fb;
  }

  .innerMenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: rgba(20, 33, 61, 0.8);

    &.active {
      color: #14213d;
    }

    .iconCheck {
      width: 20px;
    }
  }
}
.menuItemActive {
  background: #eff3fb;
}
.menuDivider {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 188px;
  height: 17px;

  background: #ffffff;

  flex: none;
  order: 8;
  align-self: stretch;
  flex-grow: 0;

  .line {
    width: 172px;
    height: 1px;

    background: #dee4f3;
  }
}

.renderTray {
  padding-bottom: 0px !important;
}

.inputsContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.toolTip,
.toolTipDefault,
.toolTipMaxWidth,
.toolTipMaxWidthDefault,
.toolTipMaxWidth100 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 16px;
  width: 194px;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  gap: 4px;

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #14213d;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .arrow {
    position: absolute;
    top: calc(50% - 11px / 2 - 4px);
  }
}

.toolTip,
.toolTipDefault {
  right: 219px;
  .arrow {
    right: -11px;
  }
}

.toolTip {
  bottom: 55px;
  height: 103px;
}

.toolTipDefault {
  bottom: -21px;
  height: 86px;
}

.toolTipMaxWidth,
.toolTipMaxWidthDefault,
.toolTipMaxWidth100 {
  height: 86px;
  left: 219px;
  .arrow {
    left: -11px;
    transform: rotate(180deg);
  }
  .icon {
    width: 20px;
    height: 20px;
    path:nth-child(1) {
      fill: #4040d9;
    }
    path:nth-child(2) {
      fill: #4040d9;
    }
  }
}

.toolTipMaxWidth {
  top: calc(50% - 82px / 2 - 4px);
}

.toolTipMaxWidth100 {
  bottom: -21px;
}

.groupPadding {
  width: 80px;
  margin-left: 16px;
}

.divider {
  min-height: 40px;
  min-width: 16px;
  border-right: solid thin rgba(255, 255, 255, 0.14159);
}

.input {
  cursor: pointer;
  background: rgba(128, 130, 179, 0.25) !important;
  padding: 7px 7px !important;
  min-width: 25px;
  max-width: 100px;
  border-radius: 4px;
  height: 28px !important;
  margin-right: 4px !important;
  text-align: center;
  padding: 0.2em;
  font-family: inherit !important;
  border-radius: 4px;
  margin: 0 !important;
  border: 0 !important;
  width: auto;
  box-shadow: none !important;
  flex: 1 !important;
  font-size: 1.1rem !important;
  color: #fff !important;
  font-size: 12px !important;
  &:focus {
    background-color: rgba(128, 130, 179, 0.4) !important;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}

.paddingInput {
  cursor: pointer;
  background: rgba(128, 130, 179, 0.25) !important;
  padding: 0.15em !important;
  min-width: 25px;
  max-width: 100px;
}

.TextInput {
  height: 28px !important;
  margin-right: 4px !important;
  text-align: center;
  padding: 0.2em;
  font-family: inherit !important;
  border-radius: 4px;
  margin: 0 !important;
  border: 0 !important;
  width: auto;
  box-shadow: none !important;
  flex: 1 !important;
  font-size: 1.1rem !important;
  color: #fff !important;
  font-size: 12px !important;
  &:focus {
    background-color: rgba(128, 130, 179, 0.4) !important;
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}

.paddingPopperContainer {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  isolation: isolate;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.paddingDrawer {
  background: #ffffff;
  width: 200px;
  height: 152px;
  position: relative;
}

.inputContainer {
  display: flex;
  align-items: center;
  position: absolute;
}
.paddingTop {
  top: 8px;
  left: 76px;
}
.paddingBottom {
  bottom: -15px;
  left: 76px;
}
.paddingLeft {
  top: 60px;
  left: 16px;
}
.paddingRight {
  top: 60px;
  left: 136px;
}

.upDownContainer {
  margin-left: -20px;
  margin-top: -25px;
  button {
    width: 18px;
    height: 13px;
    border-radius: 2px !important;
    svg {
      margin: auto;
      color: #647fb4;
    }
    &:hover {
      background: #dee8f680 !important;
      svg {
        color: #090b45;
      }
    }
    &:active {
      background: #b8c8e580 !important;
      svg {
        color: #090b45;
      }
    }
  }
}

.inputPadding {
  width: 48px !important;
  height: 32px !important;
  background: #f7f9fc !important;
  color: #475266 !important;
  border: 1px solid #dee4f3 !important;
  border-radius: 4px !important;
  margin-bottom: 2.5rem !important;

  font-family: Inter !important;
  font-size: 14px !important;
  line-height: 17px !important;

  &:focus {
    background-color: #ffffff !important;
    border-color: #4040d9 !important;
  }
  -moz-appearance: textfield !important;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
}

.bounds {
  position: absolute;
  left: 72px;
  top: 48px;
  border: 2px solid #4040d9;
  border-radius: 2px;
  height: 56px;
  width: 56px;

  display: flex;
  text-align: center;
  align-items: center;
}

.menuDividier {
  width: 184px;
  height: 1px;
  background: #dee4f3;
  margin: 8px;
}

.innerRefContainer {
  background-color: #ffffff;
}

.toggleContainer {
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #eff3fb;
  }
  &:focus {
    background-color: #eff3fb;
  }
  &:focus-within {
    background-color: #eff3fb;
  }
}

.toggleDefault {
  display: flex;
  align-items: center;
  margin-left: 24px;
  height: 20px;
  width: 120px;
  color: #14213dcc;
  font-size: 12px;
  line-height: 15px;
}

.switch input {
  opacity: 0 !important;
}

.switch {
  display: inline-block;
  width: 20px;
  height: 14px;
  margin: 4px;
  position: relative;
}

.slider {
  margin: 0 !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 100px;
  cursor: pointer;
  border: 2px solid #647fb48c;
  overflow: hidden;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  background-color: #647fb48c;
  border-radius: 30px;
  transform: translate(1px, 1px);
  transition: 0.2s;
}

input:checked + .slider:before {
  transform: translate(7px, 1px);
  background-color: #33cc98;
}

input:checked + .slider {
  border: 2px solid #33cc98;
}
