.spinner {
  margin: 0 auto;
}

.message {
  max-width: 250px;
  margin: 2rem auto 0;
  padding: 0 2rem;
}

.SimplePage {
  .centeredContent {
    justify-content: revert;
  }
}
.data {
  background: white;
  border: 1px solid #eee;
  border-radius: 2rem;
  padding: 2rem;
  margin: 0 auto;
  width: 80%;
  max-width: 500px;

  .infoHeader {
    text-align: left;
  }

  table {
    box-sizing: border-box;
    width: fit-content;
    margin: 0 auto;
  }

  td {
    padding: 0.5rem;
    border: none;
  }

  td:nth-child(1) {
    font-weight: 500;
    text-align: right;
    font-size: 0.9em;
  }
}

.form {
  margin: 2rem auto;
  padding: 0 2rem;

  input.password {
    box-sizing: border-box;
    width: 36%;
    max-width: 200px;
    margin: 0 2%;
    padding: 0 2%;
  }
  input.button {
    box-sizing: border-box;
    width: 36%;
    max-width: 200px;
    margin: 0 2%;
    padding: 0 2%;
  }
}
