.container {
  h4 {
    margin: 0;
  }
  p {
    font-size: 0.8em;
    margin-bottom: 2em;
  }

  .reloadPageButton {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 0;
    padding: 1.5rem 2rem;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    box-sizing: border-box;
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    font-size: 0.8em;
    letter-spacing: 1px;
    text-align: center;
    line-height: 1.2;
    background: #6f7fe2;
    border: none;
    border-radius: 4px;
    transition: all 0.12s ease-in-out;

    svg {
      width: 19px;
      fill: #fff;
      margin-right: 0.75em;
    }
  }
}
