@import '../../../../assets/styles/admin/variables.scss';
@import '../../../../assets/styles/admin/mixins.scss';

:global(.rdg-cell-frozen) {
  box-shadow: none;
  border-color: $light-gray-blue;
  border-right-color: $gray-blue;
}

.cellHeaders {
  border-right: 1px solid $gray-blue;
  border-color: $gray-blue;
  background-color: $light-gray-blue;
}

.gridRow {
  & > div {
    &:not(:first-child) {
      border-color: $light-gray-blue;
    }
    &:last-child {
      border-right-color: $gray-blue;
    }
  }
}

.dataGrid {
  border-color: $gray-blue;
  background-color: #f2f5fd;
}

.sheet {
  table-layout: inherit;
  width: 95%;
  position: relative;
  font-size: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  svg.newColumn {
    position: absolute;
    left: 101%;
    top: 10px;
    color: $blue;
  }
  svg.newRow {
    position: absolute;
    bottom: -35px;
    left: 30px;
    color: $blue;
  }
}
.sheetHeaderContainer {
  color: #14213d;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  background-color: $light-gray-blue;
  min-height: 45px;

  th.sheetHeader {
    padding: 0;
    height: 45px;
    text-align: center;
    border-bottom: 1px solid #b2c1df;
    &:not(:first-child) {
      border-left: 1px solid #b2c1df;
    }

    &:not(:first-child):hover {
      background-color: rgba(220, 228, 244, 0.7) !important;
      cursor: pointer;
    }

    input {
      margin-bottom: 0;
      height: 100%;
      width: 100%;
      padding-left: 1rem;
      background-color: #f8fafe;
      box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
      border: 1px solid #d7dae2;
    }
  }
}

.sheetBody {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.02);

  & tr {
    & td {
      vertical-align: middle !important;
      text-align: left !important;
      padding: 0 15px;
      cursor: pointer;

      &:hover {
        background-color: $light-blue;
      }

      input {
        margin-bottom: 0;
        height: 100% !important;
        width: 100% !important;
        padding-left: 1rem;
        background-color: #f8fafe !important;
        box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
        border: 1px solid #d7dae2 !important;
        text-align: left !important;
      }
    }
  }
}

.dateTimeInput {
  height: 276px;
  width: 245px;

  > div {
    height: 100%;
  }

  table {
    table-layout: fixed;
    width: 100%;
    text-align: center !important;

    thead {
      tr {
        &:first-child {
          th {
            width: 155px;
          }
          & th:first-child {
            width: 35px;
            span {
              padding: 0;
            }
          }
          & th:last-child {
            width: 35px;
            span {
              padding: 0;
            }
          }
        }

        &:last-child {
          th {
            width: 35px;
          }
        }
      }
      th {
        padding: 0;
      }
    }

    tbody {
      tr {
        td {
          padding: 0;
          text-align: center !important;
        }
      }
    }
  }
}

.mediaColumnStyles {
  img {
    height: 40px;
    width: auto;
    display: block;
    margin: auto;
  }
  cursor: pointer;

  &.videoMedia {
    height: 40px;
    width: auto;
    display: block;
    margin: auto;
    padding: 0;
    background-color: transparent;

    svg {
      margin: 3% 0px !important;
      z-index: 1;
      height: 50%;
    }
  }
}

.mediaPicker {
  color: $blue;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: $dark-gray-blue-faded;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      transition: all 0.14159s;
    }
  }

  span {
    display: flex;

    small:first-child {
      font-size: 1rem !important;
      text-transform: uppercase;
    }

    small:last-child {
      margin-top: -5px;
    }
  }
}

.rowSubmitButtons {
  display: flex;
  justify-content: center;

  span {
    padding: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child {
      background-color: $dark-gray-blue-faded;
      svg {
        color: #fff;
      }
    }

    &:last-child {
      background-color: $green;
      margin-left: 10px;
      svg {
        color: #fff;
      }
    }
  }
}
.textInput {
  width: 100% !important;
  height: 100% !important;
  padding: 0px 6px 0 6px !important;
  border: 2px solid #ccc !important;
  vertical-align: top !important;
  color: var(--color) !important;
  background-color: var(--background-color) !important;
  font-family: inherit;
  font-size: 14px !important;
}

svg.newColumn {
  position: absolute;
  top: 3px;
  right: -4%;
  color: $blue;
  cursor: pointer;
}

svg.newRow {
  position: absolute;
  bottom: -40px;
  left: 30px;
  color: $blue;
  cursor: pointer;
}

.datePicker {
  border: none;
  font-size: 1.5rem;

  &:focus {
    border: none;
    outline: none;
  }
}

.popperInput {
  border: none;
  font-size: 1.5rem;
  height: 30px;

  &:focus {
    border: none;
    outline: none;
  }
}

.pageTagContainer {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.pageTag {
  background: $primary-color;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 1px 5px;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
}

.pageTagIcon {
  height: 10px;
  width: 10px;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  * {
    stroke: #fff;
    fill: #fff;
  }
}

.pageTagName {
  margin: 0 8px 0 4px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 40ch;
}

a.pageTagClose {
  margin: 0px -2px 0 -2px;
  padding: 2px;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  transition: color 0.14159s;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }

  svg {
    vertical-align: middle;
    height: 8px;
    width: 8px;
  }
}

.EditingBar {
  position: fixed;
  z-index: 200;
  top: 12px;
  left: 50%;
  transform: translate(-50%, -100%);
  height: $admin-bar-height;
  width: 100%;
  max-width: 550px;
  padding: 0 1.5rem;
  background: $admin-bar-background-color;
  border-radius: 4px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;

  opacity: 0;
  transition: opacity 0.25s, transform 0.25s;

  &.show {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  &.notificationBarIsOut {
    top: calc(12px + #{$notification-bar-height});
  }
}

.discard,
.publish {
  @include unstyled-button;

  flex: 0 0 auto;
  display: block;
  margin-left: 1.5rem;
  cursor: pointer;

  transition: opacity 0.14159s;
  &:hover {
    opacity: 0.8;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  display: flex;
  align-items: center;
}

.publish {
  height: 100%;
  margin-right: -1.5rem;
  padding: 0 1.5rem;
  background-color: $primary-color;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.rowButtons {
  display: flex;
  height: 100%;
  margin-left: auto;
}

.Errors {
  position: fixed;
  z-index: 199;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -100%);
  height: $admin-bar-height;
  width: 100%;
  max-width: 550px;
  padding: 0 1.5rem;
  background: $danger-color;
  border-radius: 4px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;

  opacity: 0;
  transition: opacity 0.25s, transform 0.25s;

  &.show {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  &.notificationBarIsOut {
    top: calc(60px + #{$notification-bar-height});
  }
}

.mediaFormatter {
  display: flex;
  align-items: center;

  img {
    height: auto;
    width: auto;
    max-height: 22px;
    max-width: 50px;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  small {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.slugInput {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .options {
    @include unstyled-button;
    line-height: 0;
    width: 20px;
    flex: 0 0 auto;
    color: $gray-blue;

    box-shadow: none;
    transition: opacity 0.14159s;

    &.isActive,
    &:hover {
      color: #000;
    }

    svg {
      height: 20px;
    }
  }
}
.dropDownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 180px;
  padding: 8px 0;
  justify-content: space-between;
  border-radius: 4px;

  span,
  button {
    padding: 5px 0 5px 30px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color: #4a4a4a;
    &:hover {
      background-color: $light-gray-blue;
    }
  }
}
