.container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  &:hover {
    background-color: #eff3fb;
    cursor: pointer;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}

.check {
  display: flex;
  align-items: center;
  margin-left: 2px;
  margin-right: -4px;
  width: 20px;
  height: 20px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
