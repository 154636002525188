a {
  color: $link-color;
  text-decoration: none;
  transition: opacity 0.12s;

  &.action {
    font-size: 1.4rem;
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
}

a:not([disabled]):hover {
  opacity: 0.75;
}

a[disabled] {
  color: $disabled-color;
  cursor: not-allowed;
  &:hover {
    color: $disabled-color;
  }
}

.link {
  @extend a;
}
