@import '../../../assets/styles/admin/variables.scss';

.SmartUrlToolbar {
  position: relative;

  &.isRichText {
    flex: 1 1;
    height: 40px;
    width: calc(100% - 15rem);

    div.container {
      width: 100%;
      input.input {
        background-color: transparent;
        font-size: 12px;
        width: inherit;
        &::placeholder {
          color: #fffddd;
        }
        &:focus {
          background-color: rgba(128, 130, 179, 0.4) !important;
        }
      }
    }

    .pageTagContainer {
      width: calc(100% - 15rem);
    }
    .couponTagContainer {
      width: 100%;
    }
  }

  &.ignorePadding {
    padding: 0;
  }
}

// Page tag

.pageTag {
  background-color: #6f7fe2;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 2px 5px 2px 2px;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  width: fit-content;
  max-width: 100%;
}
.pageTagContainer,
.couponTagContainer {
  width: 340px;
  background: rgba(128, 130, 179, 0.25);
  padding: 5px;
  padding-left: 6px;
  border-radius: 3px;
}

.pageTagIcon {
  height: 10px;
  width: 10px;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  * {
    stroke: #fff;
    fill: #fff;
  }
}

.pageTagName {
  margin: 0 8px 0 4px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.pageTagClose {
  margin: 0px -2px 0 -2px;
  padding: 2px !important;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  transition: color 0.14159s;

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }

  svg {
    vertical-align: middle;
  }
}

// Autosuggest styling

.container {
  width: 340px;
}

.suggestionsContainer {
  position: absolute;
  top: 100%;
  width: 410px;
  background: white;
  overflow: auto;
  max-height: 450px;
  opacity: 0;
  transition: opacity 0.14159s;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.openOption {
  color: white;
  font-size: 12px;
  margin-left: 15px;
  height: 100%;
  padding: 0 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  // width: 85px;
}
.openOption:hover,
.openOption.menuFocused {
  background: rgba(128, 130, 179, 0.24);
}

.openOption svg path {
  fill: white;
}

.suggestionsContainer .sectionTitle {
  color: #14213d;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 16px 0 8px 24px;
}

.suggestionsContainerOpen {
  opacity: 1;
  padding-top: 15px;
  padding-bottom: 20px;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.suggestion,
.suggestionRow {
  width: 100%;
  background-color: transparent;
  transition: background-color 0.14159s;
  cursor: pointer;
  min-height: 32px;
}

.suggestion + .suggestion > * {
  border-top: solid thin rgba(255, 255, 255, 0.08);
}

.suggestionIconContainer {
  padding: 2.5px 0px 2.5px 20px;
  margin-left: 10px;
}
.suggestionIconImgContainer,
.suggestionCollectionImgContainer {
  margin: 4px 0px 0px 30px;
}
.suggestionCollectionImgContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2px;
  gap: 2px;
  flex-direction: row;
  border-radius: 2px;
  font-size: 0.55em;
  height: 30px;
  width: 30px;
}

.suggestionIcon {
  height: 20px;
  width: 20px;
  color: $dark-gray-blue;
}

.suggestionShowMore {
  font-size: 12px;
  color: #7373e5;
  padding-left: 20px;
  height: 31px;
  display: flex;
  align-items: center;
}

.suggestionHighlighted {
  background: #eff3fb;
}

.suggestionRow:not(.suggestionShowMore) {
  font-size: 1.2rem;
  vertical-align: middle;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.suggestionName {
  padding: 6px 12px 6px 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(20, 33, 61, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  font-size: 14px;
}

.suggestionImgIcon {
  height: 30px;
  width: 30px !important;
  object-fit: cover;
  border: 1px solid #ffffff;
  border-radius: 2px;
  margin-bottom: 0 !important;
}

.suggestionCollectionIcon {
  flex-basis: calc(50% - 2px);
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

input.input {
  width: 340px;
  margin: 2px;
  background-color: rgba(128, 130, 179, 0.25);
  padding-left: 6px;
  border-radius: 3px;
  // margin: 0 !important;
}

input.input:focus {
  background-color: rgba(128, 130, 179, 0.4);
}

.inputIcon {
  height: 20px;
  width: 20px;
  color: #fff;
  margin-right: 5px;
}

.removeButton {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.openAsContainer {
  position: absolute;
  background-color: #393b71;
  font-size: 12px;
  width: 200px;
  top: 100%;
  display: grid;
  grid-template-columns: 1fr;
  left: 0;
}
.openAsOption {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 20px;
}
.openAsOption:hover {
  background: rgba(128, 130, 179, 0.24);
}
