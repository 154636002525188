@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .domain {
    padding: 0;

    form,
    fieldset:last-child {
      margin-bottom: 0;
      padding: 0 !important;
    }

    fieldset > * {
      margin: 0 !important;
      padding: 1.5rem;

      font-size: 1.5rem;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-top: 1px solid #eee;
        margin-top: 2rem;
        padding-top: 2rem;
      }

      * {
        margin-top: 0;
        margin-bottom: 0;
      }

      * + * {
        margin-left: 1rem;
      }
    }

    label {
      cursor: pointer;
    }
    small {
      vertical-align: middle;
    }
  }

  .dns {
    background-color: #f8fafe;
    border-left: 2px solid #1495cd;
    padding: 2rem;
    min-width: 600px;

    label {
      margin-left: 0;
    }

    p,
    a {
      margin-bottom: 1rem;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      p {
        flex-grow: 1;
      }
    }
  }

  .status {
    display: inline-block;
    margin-left: 1rem;
    margin-top: 0.1rem;
    padding: 0.2rem 0.6rem 0;

    font-size: 1rem !important;
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: 1px;

    border: 1px solid;
    border-radius: 2px;
    background-color: white;
    border-color: $dark-gray-blue;
    color: $dark-gray-blue;

    &.active {
      background-color: $success-color;
      border-color: $success-color;
      color: white;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    &.pending {
      background-color: white;
      border-color: $primary-color;
      color: $primary-color;
    }

    &.unavailable {
      background-color: white;
      border-color: $danger-color;
      color: $danger-color;
    }
  }

  .siteMapLinks {
    margin: -6px 10px 0 auto !important;
    display: flex;
    font-size: 1.4rem;
    line-height: 16px;
    a {
      padding: 0 10px;
    }
  }

  .options {
    @include unstyled-button;
    display: inline-block;
    color: $gray-blue;
    width: 30px;
    cursor: pointer;
    svg {
      height: 20px;
    }
  }
  .toggleDns {
    @include unstyled-button;
    display: block;
    color: $dark-gray-blue;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 1rem;
  }

  .copyStatement {
    cursor: pointer;
    width: fit-content;
    &:hover {
      background-color: $gray-line-color;
    }
  }
}
