@import '../../../assets/styles/admin/variables.scss';

// Active state
.active {
  color: $primary-color;
}

// Disabled state
// [disabled] .icon,
// .icon[disabled] {
//   color: rgba(255,255,255,0.5);
// }

// Standardize height and width
.iconHeight {
  height: $toolbar-icon-size;
}
.iconWidth {
  width: $toolbar-icon-size;
}

// Icon-specific styles

.brightnessTheme {
  height: 16px;
  width: 16px;
  border-radius: 2px;

  &.active {
    box-shadow: 0 0 0 1px $dark-background-color, 0 0 0 2px $primary-color;
  }
}

// Background image align buttons
.backgroundAlignButton.active :global(.secondary-color) {
  fill: #0e103d;
}

// Animation buttons
.animationSlideButton {
  // margin: 0 -2px;
  height: 20px;
  width: 20px;
}
