@import '../../assets/styles/admin/variables.scss';

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 72rem;

  margin: 0 auto 5rem;
  padding: 0 2rem;

  .snippet {
    display: block;
    color: unset;
    padding-bottom: 4rem;
    .imageHolder {
      height: 340px;
      width: 100%;
      background-color: #ededed;
      border: solid 1px #bbbbbb;
      margin: 2rem 0;
    }
    label {
      font-size: 3rem;
      color: $medium-gray;
      margin: 5px 0;
    }
    .largeTextSkeleton {
      height: 15px;
      width: 75%;
      background-color: #ededed;
    }
    .mediumTextSkeleton {
      height: 15px;
      width: 50%;
      background-color: #ededed;
      margin-top: 13px;
    }
    .smallTextSkeleton {
      height: 15px;
      width: 25%;
      background-color: #ededed;
      margin-top: 13px;
    }
  }
}
