@import '../../assets/styles/admin/variables.scss';

.container {
  display: flex;
  background-color: $dark-blue;
  padding: 0 3rem;
  height: 4rem;
  align-items: center;
  color: $primary-color;
  font-size: 12px;

  .hidden {
    display: none;
  }

  .layoutOptions {
    display: flex;
    flex: 9;
    justify-content: space-between;
    height: 100%;

    & > :last-child {
      padding-right: 2rem;
    }
  }

  .tabs {
    display: flex;

    span {
      cursor: pointer;
      color: $toolbar-label-color;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;

      &:last-child {
        margin-left: 2rem;
      }

      &.active::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        background-color: $primary-color;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
    }
  }

  .rightTabs {
    display: flex;

    span {
      cursor: pointer;
      color: $primary-color;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;

      &:last-child {
        margin-left: 2rem;
      }

      &.active {
        color: $toolbar-label-color;
      }
    }
  }

  .numberOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding-left: 5px;
    height: 100%;
    color: #fff;

    span {
      padding: 0 1rem;
    }

    svg {
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .active {
  }
}
