$slider-height: 2px;
$thumb-diameter: 14px;

@mixin styledRange($color, $slider-height: 2px, $thumb-diameter: 14px, $width: 100%) {
  $thumb-offset: ($thumb-diameter - $slider-height) / 2;

  & {
    -webkit-appearance: none;
    width: $width;
    // margin: $thumb-offset 0;
  }
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: $width;
    height: $slider-height;
    cursor: pointer;
    // box-shadow: 5.1px 5.1px 0px rgba(0, 0, 0, 0), 0px 0px 5.1px rgba(13, 13, 13, 0);
    background: $color;
    // border-radius: 2.4px;
    // border: 0px solid rgba(1, 1, 1, 0);
  }
  &::-webkit-slider-thumb {
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    // border: 0px solid $color;
    height: $thumb-diameter;
    width: $thumb-diameter;
    border-radius: $thumb-diameter;
    background: $color;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -$thumb-offset;
  }
  &:focus::-webkit-slider-runnable-track {
    background: $color;
  }
  &::-moz-range-track {
    width: $width;
    height: $slider-height;
    cursor: pointer;
    // box-shadow: 5.1px 5.1px 0px rgba(0, 0, 0, 0), 0px 0px 5.1px rgba(13, 13, 13, 0);
    background: $color;
    // border-radius: 2.4px;
    // border: 0px solid rgba(1, 1, 1, 0);
  }
  &::-moz-range-thumb {
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    // border: 0px solid $color;
    height: $thumb-diameter;
    width: $thumb-diameter;
    border-radius: $thumb-diameter;
    background: $color;
    cursor: pointer;
  }
  &::-ms-track {
    width: $width;
    height: $slider-height;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: $color;
    // border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 4.8px;
    // box-shadow: 5.1px 5.1px 0px rgba(0, 0, 0, 0), 0px 0px 5.1px rgba(13, 13, 13, 0);
  }
  &::-ms-fill-upper {
    background: $color;
    // border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 4.8px;
    // box-shadow: 5.1px 5.1px 0px rgba(0, 0, 0, 0), 0px 0px 5.1px rgba(13, 13, 13, 0);
  }
  &::-ms-thumb {
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    // border: 0px solid $color;
    height: $thumb-diameter;
    width: $thumb-diameter;
    border-radius: $thumb-diameter;
    background: $color;
    cursor: pointer;
    height: $slider-height;
  }
  &:focus::-ms-fill-lower {
    background: $color;
  }
  &:focus::-ms-fill-upper {
    background: $color;
  }
}
