@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.aside {
  margin: 0;
  padding: 3rem;

  background-color: $purple;
  background-image: url(../../../assets/images/signup/wiggle.svg);
  background-repeat: repeat;
}

@include respond-below(md) {
  .aside {
    display: none;
  }
}

.firstLine {
  font-size: 1.5rem;
  font-weight: 500;
  color: #0e1e82;
  text-align: center;
}

.secondLine {
  margin-top: 2.5rem;
  font-size: 2rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.figure {
  position: relative;
  margin: 2rem 0 0;

  img {
    position: absolute;
    display: block;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;

    &.appear {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    &.enter {
      opacity: 0;
      transform: translate(-50%, 0) scale(0.8);
    }

    &.enterActive {
      opacity: 1;
      transform: translate(-50%, 0);
      transition: opacity 800ms, transform 800ms;
    }

    &.enterDone {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    &.exit {
      z-index: 2;
      opacity: 1;
      transform: translate(-50%, 0);
    }

    &.exitActive {
      z-index: 2;
      opacity: 0;
      transform: translate(-50%, 0);
      transition: opacity 400ms;
    }

    &.exitDone {
      opacity: 0;
    }
  }
}
