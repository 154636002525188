@import '../variables';

// ----------------------------------------------------------------------------
// Form Controls
// ----------------------------------------------------------------------------

#{$text-field-selectors},
textarea,
select {
  height: 40px;
  padding: 8px 12px; /* The 8px vertically centers text on FF, ignored by Webkit */

  background-color: $light-background-color;
  box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid #d7dae2;
  border-radius: 4px;

  font-size: 1.5rem;
  box-sizing: border-box;
  transition: border 0.12s;

  &:focus,
  &.active {
    border-color: $primary-color;
    outline: 0;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &.fieldWithErrors,
  &.fieldWithErrors:focus {
    border-color: $danger-color !important;
  }

  &[disabled] {
    background-color: $disabled-background-color;
  }
}

// Offer option to make input backgrounds white for pages with similar
// background color
form.white-inputs {
  #{$text-field-selectors},
  textarea,
  select {
    background-color: #fff;
  }
}

.disabled {
  cursor: not-allowed !important;

  a svg {
    opacity: 0.5;
  }
}

/* Removes awkward default styles on some inputs for iOS */
#{$text-field-selectors},
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

select {
  border: 1px solid #d7dae2;
  border-radius: 4px;
  transition: 0.12s all ease;

  &:focus {
    border-color: $primary-color;
  }

  &[disabled] {
    background-color: $disabled-background-color;
  }
}

label.checkbox {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  color: $darkest-blue;
  cursor: pointer;
  display: block;
  margin-bottom: 12px;
  padding-left: 25px;
  position: relative;
  user-select: none;
  width: fit-content;
  padding-left: 0;

  svg {
    margin-left: 0;
    margin-bottom: -3px;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

input[type='radio'] {
  display: inline;
}

// Ranges
// ----------------------------------------------------------------------------

input[type='range'] {
  @include styledRange($primary-color);
}

// ----------------------------------------------------------------------------
// Form labels
// ----------------------------------------------------------------------------

label,
legend {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  margin-left: 0.5rem;
  color: $dark-gray-blue;
}

fieldset {
  // fieldset elements set `min-inline-size` to `fit-content` by default
  min-inline-size: auto;

  padding: 0;
  border-width: 0;
}

label > .label-body {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: normal;
  color: $text-color;
  font-size: 1.5rem;
}

form,
fieldset {
  small {
    color: $text-color-light;
    font-size: 1.4rem;
    display: block;
  }

  small span {
    font-size: 1.2rem;
    opacity: 0.8;
  }

  small a {
    color: $primary-color;
  }
}
.errorMessage {
  color: $danger-color;
}
.successMessage {
  color: $success-color;
}

label {
  > :not(:first-child) {
    margin-left: 1rem;
  }

  small {
    display: inline;
  }

  input {
    margin-bottom: 0;
  }
}

// ----------------------------------------------------------------------------
// Sloppy fieldset overrides
// FIXME: should be more thoughtfully incorporated into base styles
// ----------------------------------------------------------------------------

fieldset {
  display: block;
  margin-bottom: 2rem;
  margin-left: 0;

  label {
    margin: 0 0 0.5rem;
    font-weight: normal;
    font-size: 1.5rem;

    &:last-child {
      margin: 0.5rem 0 0;
    }
  }

  #{$text-field-selectors},
  select,
  textarea {
    display: block;
    width: 100%;
    margin: 0 0 0.5rem;
  }

  small {
    margin-top: 1rem;
  }

  input[type='submit']:first-child {
    margin-top: 0;
  }
}

// ----------------------------------------------------------------------------
// Inline forms
// ----------------------------------------------------------------------------

form.form-inline {
  display: flex;
  flex-flow: row wrap;

  fieldset:not(:last-child) {
    margin-right: 2rem;
  }
}

// ----------------------------------------------------------------------------
// Bordered fieldsets
// ----------------------------------------------------------------------------

// .PaymentForm {
//   padding-bottom: 6rem;
//
//   * + fieldset {
//     margin: 2rem 0 0;
//   }
// }

.bordered-group {
  // margin-top: 1rem;

  label {
    display: block;
    margin: 0 !important;
    padding: 1.5rem;
    border: 1px solid $border-color;
    transition: background 0.14159s;

    &.selected {
      background: $light-background-color;
    }

    &:not(:first-child) {
      border-top: none;
    }

    .label-block {
      flex-basis: 100%;
      margin-left: 0;
    }
  }
}

label.label-right-text {
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  .title {
    flex: 1;
    margin: 0 2rem 0 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.5rem;
  }

  input {
    flex: 0;
    width: 60px;
    height: 30px;
    // padding-top: 0.3rem;
    // padding-bottom: 0.3rem;
    margin: 0 !important;
    border: none !important;
  }

  .description {
    margin: 0.5rem 0 0 2rem;
    font-size: 1.4rem;
  }

  .label-block {
    flex-basis: 100%;
    margin-left: 0;
  }

  .right {
    float: right;
    margin-left: 1rem;
  }

  input {
  }
}

.radio-group {
  > label {
    cursor: pointer;
  }

  > * {
    display: block;
    margin: 0 !important;
    padding: 1.5rem;
    border: 1px solid $border-color;
    transition: background 0.14159s;

    &.selected {
      background: $light-background-color;
    }

    &:not(:first-child) {
      border-top: none;
    }

    input {
      float: left;
      margin: 0.45rem 0 0 0;
    }

    .title {
      margin: 0 0 0 2rem;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.5rem;
    }

    .description {
      margin: 0.5rem 0 0 2rem;
      font-size: 1.4rem;
    }

    .right {
      float: right;
      margin-left: 1rem;
    }

    .label-block {
      flex-basis: 100%;
      margin-left: 0;

      input {
        float: none;
        margin-bottom: 0;
      }
    }
  }
}
