@import 'assets/styles/admin/variables';

$overlay-icon-height: 38px;
$overlay-icon-width: 3rem;

.container {
  display: flex;
}

.formContainer {
  padding: 7rem 0;
  flex: 1 1 auto;
  background-color: #a9b3c9;
  background-image: url('/assets/images/dots.svg');
}

.sideBar {
  flex: 0 0 368px;
  background: #fff;
  min-height: calc(100vh - 92px);
  max-height: calc(100vh - 92px);
  position: sticky;
  top: 90px;
  overflow: scroll;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  padding-bottom: 60px;
  &.notificationBarIsOut {
    min-height: calc(100vh - 131px);
    max-height: calc(100vh - 131px);
    top: 130px;
  }

  .fieldLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    box-shadow: 0px 1px 0px #dee4f3, inset 0px 1px 0px #dee4f3;
    position: sticky;
    top: 0;
    background-color: $light-background-color;
    z-index: 1;
    & span {
      padding: 13px 16px;
      font-size: 14px;

      &:last-child {
        cursor: pointer;
      }
    }
  }

  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 0px #dee4f3, inset 0px 1px 0px #dee4f3;
    position: sticky;
    top: 0;
    background-color: $light-background-color;
    z-index: 1;

    & > span {
      padding: 13px 16px;
      font-size: 14px;
      position: relative;
      color: rgba(20, 33, 61, 0.8);
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: solid 3px transparent;
        transition: all 0.14159s;
      }
      &.active {
        &::after {
          border-bottom-color: $primary-color;
        }
        color: rgba(20, 33, 61, 1);
        font-weight: 500;
      }
    }
  }

  form {
    position: sticky;
    overflow: scroll;
    height: calc(70vh);
    background-color: #fff;
  }
}

.formEmbed {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.droppable {
  min-height: $overlay-icon-height;
  transition: 500ms all;
}

.fieldset {
  margin-bottom: 0 !important;
}

.previewfieldset {
  background-color: whitesmoke;
  box-shadow: 1px 2px 3px 2px gainsboro;
}

.row {
  position: relative;
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  display: flex;
  flex-flow: row nowrap;
}

.fieldContainer {
  position: relative;
  flex: 1;
}
.fieldContainer:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.placeholder {
  flex: 1;
  background: rgba(137, 161, 207, 0.6);
  border-radius: 4px;
}

%rowIcon {
  height: 100%;
  width: $overlay-icon-width;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.14159s;
}

.row:hover %rowIcon {
  opacity: 1;
}

.leftIcon {
  @extend %rowIcon;
  left: -1.5rem;
}
.rightIcon {
  @extend %rowIcon;
  right: -1.5rem;
}

.moveIcon {
  height: 1.5rem;
  cursor: grab;
  color: $light-gray;
}

.removeButton {
  display: inline-block;
}

.removeIcon {
  display: block;
  height: 1.5rem;
  width: auto;
  color: $light-gray;
}

// Side bar

.sideBarHeader {
  padding: 2rem;
  font-size: 1.5rem;
}

$new-field-padding: 0.5rem;

.newFields {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1.5rem;
  padding: 0 2rem;
}

.newField {
  position: relative;
  padding-bottom: 100%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  cursor: grab;
  background-color: #fff;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.2rem;
  }

  transition-property: background-color color box-shadow;
  transition-duration: 0.15s;

  &.newFieldDisabled {
    background-color: #ebf0fa;
    color: #747a83;
    box-shadow: none;
    cursor: default;
  }
}

.hiddenField {
  border: 1px dashed #bbb !important;
  background: white !important;
  color: #999 !important;
}

.dropping {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.previewStyles {
  input,
  textarea,
  select {
    background: #ffffff !important;
    border: 2px solid #ffffff !important;
    box-shadow: 0px 2px 2px rgba(86, 97, 123, 0.25), 0px 4px 16px rgba(86, 97, 123, 0.8) !important;
    border-radius: 4px !important;
  }
}

.selected {
  input,
  textarea,
  select {
    background: #ffffff !important;
    border: 2px solid #ffffff !important;
    margin: -1px !important;
    box-shadow: 0px 2px 2px rgba(86, 97, 123, 0.25), 0px 4px 16px rgba(86, 97, 123, 0.8) !important;
    border-radius: 2px !important;
  }
  button {
    background-color: #4a90e2;
    border-color: #4a90e2;
    box-shadow: 0px 2px 2px rgba(86, 97, 123, 0.25), 0px 4px 16px rgba(86, 97, 123, 0.8) !important;
  }
}

.hideField {
  display: none;
}

.fieldPadding {
  padding-top: 15px;
  padding-bottom: 15px;
}

.placeholderPadding {
  margin-top: 15px;
  margin-bottom: 15px;
}
