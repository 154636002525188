.video-embed {
  width: 100%;
  padding: 0 0 (100% * 9 / 16);
  position: relative;
  background: #0001;
  box-sizing: border-box;

  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  iframe {
    border: none;
  }

  .video-embed-overlay {
    cursor: pointer;
  }
}
