@import '../../../assets/styles/admin/variables.scss';

.productInfo {
  padding: 12px !important;
  background-color: $light-background-color;

  small {
    margin-top: 0 !important;
    color: #000 !important;
    font-weight: 500;
  }
}
:global(.admin) {
  .remove {
    font-size: 1.4rem;
    color: $danger-color;
  }
}

.paymentEmbedsContainer {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: max-content !important;
  .paymentEmbeds {
    width: min-content;

    code {
      font-size: 85%;
    }
  }
}
