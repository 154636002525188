@import '../../../../assets/styles/admin/variables.scss';
@import '../../../../assets/styles/admin/mixins.scss';

$padding: $toolbar-padding / 2;

:global(.admin) .colorPicker {
  max-width: 120px;
  position: relative;
  margin-top: -3px;

  .colorSwatch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 2px;
    border: 1px solid rgba(9, 11, 69, 0.1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  .Swatch {
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid rgba(9, 11, 69, 0.1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  input {
    padding: 0 0 0 2.25rem !important;
    height: auto !important;
    border: none;
    // text-transform: uppercase;
    width: -webkit-fill-available;
    max-width: 180px;
    text-overflow: ellipsis;
  }

  .Drawer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f8fafe;
    border: 1px solid #dce4f4;
    border-radius: 2px;
    width: 170px;
    margin-left: -1.1rem;
    margin-top: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    z-index: 1;

    max-height: 135px;
    overflow-y: auto;

    > ul {
      list-style: none !important;

      > li.ColorChoice {
        display: flex;
        align-items: center;
        margin-left: unset !important;
        display: flex;
        padding: 0.4rem 1rem !important;
        cursor: pointer;

        span {
          margin-left: 0.65rem !important;
          font-size: 1.4rem !important;
          color: #14213d !important;
          font-weight: 400 !important;
        }

        span.inSwatchLabel {
          font-size: 13px !important;
          text-transform: none;
          margin-top: 5px;
          letter-spacing: 0;
        }

        background-color: #f8fafe;
        transition: background-color 0.14159s;
        &:hover {
          background-color: rgba(220, 228, 244, 0.4) !important;
        }
      }
    }
  }
}
