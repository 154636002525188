.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  background-color: #14213d;
  opacity: 0;
  transition: opacity 0.14159s;

  &:hover {
    opacity: 0.4;
  }
}

.copy {
  color: #fff;
  max-width: 400px;
  text-align: center;
}
