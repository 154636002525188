@import '../../../assets/styles/admin/variables.scss';

.fullOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  box-shadow: 0 0 0 2px inset $primary-color;
  pointer-events: none;
  transition: opacity 0.2s;
}

.layoutToolbar {
  position: absolute;
  z-index: 1;
  left: 50%;
  padding: 0 8rem;
  box-sizing: border-box;
  min-width: 360px;
  transform: translate(-50%, 0);

  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: visibility 0.2s, opacity 0.2s, transform 0.2s;

  &.hide {
    opacity: 0;
    transform: translate(-50%, -16px);
  }
}

.jasmine {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  padding: 1rem 4rem;
  color: rgba(41, 50, 65, 0.22);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  span {
    margin-left: 0.5rem;
    font-size: 1.5rem;
  }
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.header.jasmine,
.header.layoutToolbar {
  top: 100%;
}

.footer.jasmine,
.footer.layoutToolbar {
  bottom: 100%;
  z-index: 10;
}

@media screen and (max-width: 799px) {
  .layout-toolbar {
    /* height must match module top padding in Sriracha.css */
    height: 5rem;
  }
}
