@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

$padding: 1.5rem;

.EditingBar {
  position: fixed;
  z-index: 200;
  top: 12px;
  left: 50%;
  transform: translate(-50%, -100%);
  height: $admin-bar-height;
  width: 100%;
  max-width: 550px;
  padding: 0 $padding;
  background: $admin-bar-background-color;
  border-radius: 4px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;

  opacity: 0;
  transition: opacity 0.25s, transform 0.25s;

  &.show {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  &.notificationBarIsOut {
    top: calc(12px + #{$notification-bar-height});
  }
}

.message {
  flex: 1 1 auto;
  margin-right: 2rem;
}

.message b {
  font-weight: 500;
  margin-right: 0.5rem;
  color: #80f6b0;
}

.discard,
.publish {
  @include unstyled-button;

  flex: 0 0 auto;
  display: block;
  margin-left: 1.5rem;
  cursor: pointer;

  transition: opacity 0.14159s;
  &:hover {
    opacity: 0.8;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  display: flex;
  align-items: center;
}

.discard {
  // color: $light-gray;
}

.publish {
  height: 100%;
  margin-right: -$padding;
  padding: 0 $padding;
  background-color: $primary-color;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.iconInfo {
  margin-right: 1rem;
}

.iconDiscard {
  margin-right: 1rem;
}

.iconPublish {
  height: 1em;
  margin-right: 1rem;
}

.successMessage {
  flex: 1 1 auto;
  margin: 0 -$padding;
  height: 100%;
  background-color: $success-color;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 1.4rem;
  border-radius: 4px;

  .iconUnstackles,
  strong {
    margin-right: 0.5em;
    margin-right: 0.5em;
  }
}
