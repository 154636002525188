@import '../../../../../assets/styles/admin/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .urlInputContainer {
    background-color: rgba(255, 255, 255, 0.12);
    border: none;
    width: 330px;
    padding: 0 1rem;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;

    &.addMargin {
      margin-left: 250px;
    }

    .inputField {
      flex: 1;
      background-color: transparent;
      border: none;
      margin: 0 1rem;
      color: #fff;
      font-weight: normal;
      font-size: 13px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #b3b3b3;
      }
    }

    svg {
      height: 20px;
      border-radius: 50%;
    }
  }
}

// .contentContainer {
//   display: flex;
//   flex-wrap: wrap;
//   margin: 0;
//   padding: 1.5rem;
//   background-color: #1f2258;
//   flex: 4;

//   .videoItem {
//     border-radius: 2px;
//     max-width: 220px;
//     min-width: 220px;
//     padding: 1rem;
//     margin: 0.5rem;
//     transition: all 0.1s;
//     border: 2px transparent solid;

//     &.selected {
//       border: 2px solid $primary-color;
//     }

//     video {
//       width: 100%;
//       box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
//       margin: 0;
//       vertical-align: middle;
//       border-radius: 2px;
//       background: #fff;
//     }

//     figcaption {
//       font-size: 1.4rem;
//       color: #8499c2;
//       overflow: hidden;
//       max-width: 180px;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//     }

//     .caption {
//       margin-top: 1rem;
//       display: flex;
//       width: -webkit-fill-available;
//       justify-content: space-between;
//       align-items: center;

//       span {
//         font-size: 13px;
//       }

//       div {
//         display: flex;

//         input {
//           padding: 0;
//           margin: 0;
//           width: 4rem;

//           &:first-child {
//             margin-right: 1rem;
//           }
//         }
//       }
//     }
//     &:hover {
//       figcaption {
//         color: white;
//       }
//     }
//     &:hover {
//       background-color: rgba(255, 255, 255, 0.08);
//       cursor: pointer;
//       img {
//         box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
//       }
//     }
//   }
// }

.isLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px auto;
  width: 220px;
}
