.unstack-sticky{
  position: sticky;
  bottom: 0px;
  width: 100%;
}
.unstack-fixed{
  position: fixed;
  bottom: -1px;
  transition: background-color .5s;
  right: 0;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.unstack-sticky, .unstack-fixed {
  padding: 0px 20px;
}