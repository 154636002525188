@import '../../../assets/styles/admin/variables.scss';

.container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  color: $blue;

  input {
    margin-bottom: 0.5rem;
    margin-top: -1.5rem;
  }
}

.suggestionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  background: #f0f2f6;
  border-radius: 0 0 2px 2px;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.14159s;
  max-height: 200px;
}

.suggestionsContainerOpen {
  opacity: 1;
}

.suggestionsList {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.suggestion {
  // width: 100%;
  background-color: transparent;
  transition: background-color 0.14159s;
  cursor: pointer;
  padding: 10px 5px 10px 10px;
  border-top: solid thin rgba(0, 0, 0, 0.05);
}

.suggestionHighlighted {
  background-color: #f8f9fb;
}

.suggestion > * {
  display: table-cell;
  font-size: 1.2rem;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  width: 100%;
}
