.modal {
  display: none;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;
  //background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  .modalMain {
    background: white;
    text-align: center;
    width: 20%;
    padding: 20px;
    border-radius: 10px;
    transform: translate3d(0, -50%, 0);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }

  &.visible {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;

    .modalMain {
      transform: translate3d(0, 0, 0);
    }
  }
}
