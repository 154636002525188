.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-self: center;
  margin-top: 60px;

  svg {
    margin-bottom: 2rem;
  }

  h2 {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  p {
    color: #8499c2;
    width: 300px;
    text-align: center;
    font-size: 1.4rem;
  }
}
