@import '../assets/styles/admin/variables.scss';
@import '../assets/styles/admin/mixins.scss';

.AdminBar {
  height: $admin-bar-height;
  position: relative;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);

  &.fixed {
    @extend .AdminBar;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
  }

  .left,
  .center,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .left {
    left: $admin-panel-gutter;
    height: 40px;
    display: flex;
    align-items: center;
  }
  .center {
    left: 0;
    right: 0;
    text-align: center;

    font-size: 1.4rem;
    font-weight: 400;

    display: flex;
    justify-content: center;
  }
  .right {
    right: $admin-panel-gutter;
    height: 100%;

    svg {
      margin-top: -2px;
    }
    section {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .backButton {
    display: block;
    transition: all 0.12s;

    svg {
      height: 20px;
      width: 20px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}
.circleIcon {
  @include unstyled-button;

  vertical-align: middle;
  margin-bottom: 0px !important;
  cursor: pointer;

  width: 40px;
  height: 40px;

  background: rgba(242, 245, 253, 0.5);
  border-radius: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: 3px solid transparent;
  border: 1px solid transparent;

  cursor: pointer;
  svg {
    stroke: rgb(125, 143, 178);
    fill: rgb(125, 143, 178);
  }
}
.circleIcon.active {
  svg {
    fill: rgba(64, 64, 217, 1);
  }
}
.circleIcon:hover {
  background: rgba(222, 232, 246, 0.5);
}
.circleIcon:focus {
  outline: 2px solid #4040d9;
  border: 1px solid white;
  background: rgba(222, 232, 246, 0.5);
}
.circleIcon:disabled {
  background: rgba(242, 245, 253, 0.5);

  svg {
    stroke: rgba(20, 33, 61, 0.4);
    fill: rgba(20, 33, 61, 0.4);
  }
}
.helpIcon {
  margin-left: 8px;
}
.deviceSection {
  display: grid;
  grid-template-columns: 40px 40px 40px;
  align-items: flex-start;
  padding: 0px;

  width: 120px;
  height: 40px;

  background: rgba(242, 245, 253, 0.5);
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;

  .deviceButton {
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    span {
      height: 32px;
      width: 32px;
      top: 4px;
      left: 4px;
      justify-content: center;
      align-items: center;
      display: flex;
      cursor: pointer;

      &.active {
        background: white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        path {
          fill: #4040d9;
        }
        &.mobile {
          path {
            fill: #4040d9;
            stroke: #4040d9;
          }
          rect {
            stroke: #4040d9;
          }
        }
      }
    }
  }
}
