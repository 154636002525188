.notificationPermContainer {
  align-items: center;
  display: flex;
  height: 3rem;
  margin-bottom: 2rem;

  svg {
    height: inherit;
  }

  p {
    font-weight: 500;
    font-size: 1.6rem;
    color: #14213d;
    margin: 0 0 0 1rem;
  }

  div {
    margin-left: auto;

    :first-child {
      margin-right: 1rem;
    }

    input {
      padding: inherit !important;
      height: 3rem !important;
      margin-bottom: 0 !important;
    }
  }
}
