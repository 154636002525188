@import '../../../assets/styles/admin/variables.scss';

.upgrade {
  > :last-child {
    margin-bottom: 0;
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  a:global(.button) {
    margin-bottom: 0;
    line-height: 2;
    box-shadow: none;
  }
}

.processing {
  display: flex;
  align-items: center;

  :global(.spark-spinner) {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2rem;
  }
}

.successMessage {
  padding: 1rem;
  background: lighten($green, 35%);
  border: solid thin $green;
  color: darken($green, 10%);
  font-size: 1.5rem;
  margin: 2rem 0;
}

.paymentHistory {
  font-size: 1.4rem;

  article {
    padding: 0 !important;
  }

  table {
    margin: 0;
    width: 100%;
  }

  td {
    padding: 2rem !important;
  }

  td small {
    font-size: 0.9em;
    color: $text-color-light;
    margin-right: 0.5em;
  }

  td.textRight {
    text-align: right;
  }

  tr:last-child td {
    border-bottom: none;
  }
}
