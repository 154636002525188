.contentContainer {
  width: 542px;
  height: 575px;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
}

.container {
  margin: 48px auto;
  text-align: center;
  height: 433px;
  width: 480px;
  border-radius: 8px;
  padding: 32px;
  background-color: white;
  box-shadow: 0px 2px 6px 0px #00000014;

  .icon {
    width: 96px;
    height: 96px;
    margin: auto;
    padding: 24px;
    border-radius: 100px;
    background: #eff3fb;
    font-size: 48px;
    line-height: 48px;
  }

  .header {
    height: 144px;
    text-align: center;

    .price {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #647fb4;
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      color: #14213d;
      margin-top: 24px;
    }

    .description {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      margin: 32px 0px;

      p {
        font-weight: 400;
        margin-top: 8px;
        margin-bottom: 0px;
        color: #14213dcc;
      }

      b {
        font-weight: 500;
        color: #14213d;
      }
    }

    .extend {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: center;
      color: #14213dcc;
      margin: 28px 0px;

      a {
        font-weight: 600;
        color: #4040d9;
        cursor: pointer;
      }
    }

    button {
      background: #4040d9;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      width: 100%;
      height: 40px;
      color: white;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
    button:hover {
      background: #6666e1;
    }
    button:focus {
      background: #4040d9;
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }
    button:disabled {
      background: rgba(204, 212, 226, 0.5);
      color: rgba(20, 33, 61, 0.4);
      border: none;
    }
  }
}
