@import '../assets/styles/admin/variables.scss';
@import '../assets/styles/admin/mixins.scss';

.Notification {
  z-index: 3;
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: $notification-bar-height;
  padding: 0 $gutter;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;

  transition: all 0.14159s;

  // Default = info
  background-color: $primary-color;

  &.info {
    background-color: $purple;
  }
  &.warning {
    background-color: $danger-color;
  }
  &.success {
    background-color: $success-color;
  }

  &.Link {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  &.Call {
    cursor: pointer;
  }

  &:hover {
    filter: brightness(1.06);
  }

  .icon {
    margin-right: 1rem;
  }

  .chevron {
    height: 1.2em;
    transform: rotate(180deg);
    margin-left: 0.1em;
  }

  &:not(.Link):not(.Call) .chevron {
    display: none;
  }
}
.NotificationSubstrate {
  display: block;
  height: $notification-bar-height;
}

.eventButton {
  background-color: #fff;
  border: none;
  border-radius: 4px;
  color: $primary-color;
  padding: 5px;
  font-size: 11px;
  margin-left: 1rem;
}
