@import '../../assets/styles/admin/variables.scss';

// Products
$default-background-color: #ffffff;
$hover-background-color: $light-background-color;
$focus-border-color: $purple;
$price-text-color: $dark-gray-blue;
$title-text-color: $darkest-blue;

.productContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $default-background-color;
  border: 2px solid $product-border-color;
  border-radius: 4px;
  width: 100%;

  &:hover {
    background: $hover-background-color;
  }

  &:focus {
    border: 2px solid $focus-border-color !important;
  }
}

.productDetails {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.productPrice {
  color: $price-text-color;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.tooltip {
  max-width: 280px !important;
  color: #ffffff !important;
  padding: 8px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px !important;
}

.productInfoContainer,
.selectedProductInfoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.productInfoContainer {
  width: -webkit-fill-available;
}
.selectedProductInfoContainer {
  width: 268px;
}

.tagLabel {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  &.product {
    color: #ca0b4a;
    border: 1px solid #ca0b4a;
  }
  &.parent {
    color: #0065c1;
    border: 1px solid #0065c1;
  }
  &.child {
    color: #0065c1;
    border: 1px solid #0065c1;
  }
  &.bundle {
    color: #b85c00;
    border: 1px solid #b85c00;
  }
  &.none {
    display: none;
  }
}

.productText {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  max-width: 212px;
  color: $title-text-color;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 1rem;
  }
}

.actionContainer {
  margin-right: 4px;
}

.clickable {
  cursor: pointer;
}

.imageContainer {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin: 2px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 2px;
    object-fit: cover;
    margin-bottom: 0;
  }
}

.root {
  pointer-events: auto !important;

  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  & .productContainer {
    flex-grow: 1;
  }

  .dragHandle {
    position: absolute;
    left: 8px;
    svg {
      opacity: 0;
      transition: 0.2s all ease-in;
    }
  }

  &:hover {
    .dragHandle svg {
      cursor: grab;
      opacity: 1;
    }
  }
}

.sortable {
  margin-right: 12px;
}

:global(body) {
  & > .root {
    cursor: grabbing !important;

    &:hover {
      .dragHandle svg {
        display: none;
      }
    }

    .productContainer {
      &:hover {
        background-color: white;
      }
    }
  }
}
