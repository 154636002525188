.unstackUniversity {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  position: relative;
}
.iconVideo {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  margin-right: 25px;
}
.iconVideo:hover {
  color: rgba(255, 255, 255, 0.8);
}
.videoContainer {
  width: 100%;
  height: fit-content;
  position: relative;

  video[poster] {
    object-fit: fill;
  }
}
.autoHideContainer {
  position: fixed;
  bottom: 20px;
  background: #394151;
  font-size: 16px;
  line-height: 25px;

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;

    rect {
      fill: #7d8fb2;
    }
    g {
      stroke-width: 0;
    }
  }
  span {
    position: absolute;
    bottom: 4px;
    width: 200px;
    left: 26px;
    display: inline-block;
    cursor: pointer;
  }
}
.videoList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-top: 20px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 12px;
  color: rgba(255, 255, 255, 0.6);
  min-height: 48px;
  width: 100%;
  height: fit-content;

  svg {
    fill: white;
  }
  .videoTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    flex-grow: 1;
  }
  &.unwatched .videoTitle {
    color: white;
  }
  .videoLength {
    font-size: 14px;
    line-height: 17px;
    width: 50px;
    text-align: right;
  }
  &:hover,
  &.active {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    cursor: pointer;
  }
  .check {
    width: 20px;
  }
}
.pausedVideo {
  background: rgba(64, 64, 217, 1);
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  position: absolute;
}
.videoControls {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.videoControlsOverlay {
  width: 100%;
  height: calc(100% - 6px);
  position: absolute;
  top: 3px;
  left: 0;

  // background: #4040D9;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
}

button.controlIcon {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93px;
  height: 92px;
  cursor: pointer;
  border: none;
  margin-bottom: 0;

  svg {
    fill: #4040d9;
    height: 40px;
    width: 40px;
    z-index: 1;
  }
  &:hover {
    background: #dce4f2;
  }
  &:focus {
    background: #bac9e3;

    svg {
      fill: #647fb4;
    }
  }
}

button.controlIconMini {
  height: 40px;
  width: 40px;
  margin: 0px 16px;

  svg {
    height: 20px;
    width: 20px;
  }
}
.videoIconContainer {
  height: 32px;
  width: 32px;
  position: absolute;
  bottom: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    fill: white;
  }
}
.videoIconContainer:hover {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.videoIconContainer:active {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}
.soundIcon {
  left: 11px;
}
.smallPlayIcon,
.smallPauseIcon {
  left: 50%;
}
.smallPauseIcon {
  path {
    fill: white;
    stroke: white;
    fill-opacity: 1 !important;
  }
}
.smallPrevIcon {
  left: calc(50% - 50px);
}
.smallNextIcon {
  left: calc(50% + 50px);
}
.smallPopoutIcon {
  right: 11px;
}
.scrubber {
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  height: 4px;
  bottom: 40px;
  padding-top: 5px;
  padding-bottom: 10px;
  position: absolute;
  .scrubberBG {
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 4px;
    .scrubberInner {
      background-color: #5454ff;
      height: 4px;
    }
  }
}
