@import '../../../../assets/styles/admin/variables.scss';

.modal {
  padding: 2rem 0;
  width: 60vw;
  max-width: 586px;
  min-width: 425px;
  height: 300px;

  h3 {
    border-bottom: 1px solid #dce4f4;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .contentContainer {
    .inputContainer {
      display: flex;
      justify-content: space-between;

      & :first-child {
        flex: 0 0 60%;
      }
      & :last-child {
        flex: 0 0 35%;
      }

      .disconnectStatement {
        color: $text-color;
        font-size: 14px;
      }
    }
    .disconnectInput {
      margin-top: 10px;
      margin-bottom: 5px;

      & span {
        color: $text-color;
        font-size: 14px;
        max-width: 60%;
      }
    }
    padding: 0 2rem;
    height: 195px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & div:last-child {
      margin-left: auto;

      & button:first-child {
        margin-right: 1rem;
      }
    }
  }
}
.syncContainer {
  display: flex;
  small,
  > div {
    margin-left: 30px;
  }
}
