@import '../../assets/styles/admin/variables.scss';

.popover {
  overflow: visible !important;
  z-index: 100;
  padding-bottom: 10px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding-bottom: 10px;
  transition: all 0.14159s;
}

.arrow {
  position: absolute;
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $toolbar-background;
}

.enter {
  opacity: 0;
}
.enterActive,
.enterDone {
  opacity: 1;
  transition: opacity 0.14159s;
}

.exit {
  opacity: 1;
}
.exitActive,
.exitDone {
  opacity: 0;
  transition: opacity 0.14159s;
}
