/* Toolbars */

.toolbar {
  position: relative;
  background-color: $dark-background-color;
  border-radius: 2px;
  height: 46px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  flex-flow: row nowrap;
  align-items: center;
}

.toolbar > span {
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 0.25rem;
  white-space: nowrap;
}

.toolbar label {
  @extend span;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  // margin: 0;
  // font-size: 1.4rem;
}

.toolbar button {
  margin: 0 4px;
  border-radius: 3px;
  border: none;
  padding: 0;
  height: auto;
  color: #fff;
  line-height: normal;
  background-color: transparent;
  outline: none;
  transition: opacity 0.14159s;

  &:focus {
    opacity: 1;
  }

  &:hover,
  &:focus:hover {
    opacity: 0.75;
  }

  &.active {
    color: $primary-color;
  }

  // NOTE: [@pmlord]: Not sure why this was here, but it was causing issues with
  // specificity, so I commented it out. After a cursory check around the app,
  // everything seems to be fine without it...
  //
  // &:focus:not(:hover) {
  //   color: #fff;
  //   opacity: 1;
  // }
}

.toolbar button[disabled] {
  opacity: 0.4;
  cursor: default;
}

.toolbar svg {
  display: block;
}

.toolbar input {
  height: 32px;
  margin: 0;
  padding: 0;
  background: rgba(128, 130, 179, 0.25);
  color: #fff;
  border: none !important;
  font-size: 12px;
  font-weight: 300;
}

.toolbar .toolbar-takover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: $admin-bar-background-color;
}

.toolbar .color-picker-group {
  display: flex;
  align-items: center;

  input {
    min-width: 4.7em;
  }
}
.toolbar .color-swatch {
  height: 14px;
  width: 14px;
  border-radius: 1px;
  /* cursor: pointer; */
}
.toolbar input.color-control {
  margin-left: 5px;
  width: 48px;

  // Unset styles from `.sriracha .dark-bg input`
  color: unset;
  padding: unset;
  border-radius: unset;
  background-color: unset;
  margin-bottom: unset;
}

.toolbar .background-image svg {
  height: 19px;
  fill: currentColor;
  stroke: currentColor;
}

.toolbar .remove-background-image svg {
  height: 10px;
}

.toolbar .add-remove-child svg {
  height: 14px;
  stroke: none;
  fill: none;
}

.toolbar .separator {
  width: 1.5rem;
  height: 32px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 45%,
    rgba(255, 255, 255, 0.14159) 50%,
    rgba(255, 255, 255, 0) 55%
  );
}

.toolbar input:focus {
  outline: none;
}
.toolbar ::placeholder {
  color: #ddd;
}

/* Toolbar buttons */

.toolbar .isvg {
  display: flex;
  align-items: center;
}

.brightnessThemeButton {
  /* border: solid 1px transparent; */
}
.brightnessThemeButton,
.brightnessThemeButton svg {
  height: 16px;
  width: 16px;
}

.active .brightnessThemeButton {
  /* border-color: $primary-color; */
  box-shadow: 0 0 0 1px $dark-background-color, 0 0 0 2px $primary-color;
  border-radius: 2px;
}

.calloutLayoutButton,
.iconAlignLeft,
.iconAlignCenter,
.iconHeaderOption,
.removeButton,
.anchorLinkButton {
  height: 14px;
  width: 14px;
}

.iconQuoteLayout {
  height: 14px;
  width: auto;
}

// .ql-toolbar {
//   @extend .toolbar;
// }

@import './quill';

.ql-bubble {
  .ql-tooltip {
    z-index: 2;
  }

  .ql-tooltip,
  .ql-tooltip * {
    margin-top: 0;
  }

  .ql-toolbar {
  }

  .ql-formats {
    margin: 4px !important;
  }

  .ql-toolbar button {
    margin: 0;

    color: #fff;
    .ql-stroke {
      stroke: #fff;
    }
  }
}
