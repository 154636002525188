@import '../assets/styles/admin/variables';

.fixedSectionIndicator {
  position: sticky;
  background: #f8fafe;
  box-shadow: 6px 0 0 0 #f8fafe, -6px 0 0 0 #f8fafe, 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 50px;
  margin: -1px 0 30px;
  z-index: 2;
  display: flex;
  border-bottom: 2px solid #f8fafe;
  top: 0;

  span.label {
    cursor: default;
    text-transform: capitalize;
    font-size: 1.3rem;
    padding: 0 2.5rem;
    font-weight: 500;
    color: rgba(20, 33, 61, 0.6);
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.14159s;

    &:hover {
      color: rgba(20, 33, 61, 1);
    }

    &.activeLabel {
      border-bottom: 2px solid $primary-color;
      color: rgba(20, 33, 61, 1);
      background-image: url('../assets/images/icon-header-current-section.svg');
      background-size: 10px 4px;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }

  &.notificationBarIsOut {
    margin-top: 40px;
  }
}
