.container {
  background: #ffffff;
  /* Shadow/XX Large */

  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  isolation: isolate;

  width: 322px;

  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  color: rgba(20, 33, 61, 0.8);
}

.triangle {
  transform: rotate(180deg);
  top: -10px;
  left: 16px;
  position: absolute;
  z-index: 10;
}

.errorText {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #14213d;
}
.errorIcon {
  display: inline;
}
.errorHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}
.errorPath {
  color: rgba(202, 11, 74, 1);
}
