@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.header {
  display: flex;
  justify-content: space-between;

  small {
    margin-left: 1rem;
  }

  .actions {
    @include respond-above(xl) {
      float: right;
    }

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    * + * {
      margin-left: 1rem;
    }
  }

  .search {
    svg {
      margin-top: 0 !important;
    }
  }

  a.newButton {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin: auto 5px auto 0 !important;
    }
  }
}

.components {
  margin: 0 -2rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: justify-start;
}

.component {
  box-sizing: border-box;
  padding: 2rem;
  flex: 0 0 100%;

  @include respond-above(md) {
    flex: 0 0 350px;
    flex-basis: 33.33%;
  }

  @include respond-above(xl) {
    flex: 0 0 350px;
    flex-basis: 25%;
  }
}
