.notificationContainer {
  margin: 0 !important;
  padding: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  span {
    margin-left: 1rem;
  }

  svg {
    cursor: pointer;
  }

  &.secondaryNotification {
    border-top: 1px solid #dce4f4;

    svg {
      cursor: default;
      height: 20px;
      width: auto;
    }

    input {
      padding: inherit !important;
      height: 3rem !important;
      margin-bottom: 0 !important;
      margin-left: auto;
    }
  }
}
