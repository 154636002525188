@import '../../../../assets/styles/admin/variables.scss';

.shadowsStylesHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 23px !important;

  svg {
    color: $blue;
    margin: 0 10px 0 auto;
    cursor: pointer;
  }
}

.shadowsContainer {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 3rem;
  }

  .item {
    background-color: #eff3fb;
    margin: 0 20px 20px 0;
    border-radius: 2px;
    display: flex;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    width: 200px;
    height: 200px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
      transition: all 0.14159s;
    }

    .circleContainer {
      background-color: #eff3fb;
      width: 200px;
      height: 144px;
      text-align: center;

      .circle {
        background-color: #fff;
        margin: 24px;
        height: 96px;
        width: 96px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .labelContainer {
      background-color: #fff;
      width: 200px;
      height: 56px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .label {
      text-transform: capitalize;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: $dark-gray-blue;
    }
  }
}

.drawerShadowContainer {
  background-color: #eff3fb;
  width: 100%;
  height: 192px;
  margin-bottom: 20px;
  // margin: 0 20px 20px 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .circle {
    background-color: #fff;
    margin-bottom: 16px;
    height: 96px;
    width: 96px;
    border-radius: 50%;
    display: inline-block;
  }

  .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #647fb4;
    text-transform: capitalize;
  }
}

.tabs {
  height: 40px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #dee4f3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;

  & input {
    position: absolute;
    top: -20px;
    opacity: 0;
  }

  & input + label {
    height: 40px;
    margin: 0px !important;
    padding: 12px 16px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #14213d;
    opacity: 0.8;
    cursor: pointer;
    text-transform: capitalize;
  }

  & input:checked + label {
    opacity: 1;
    box-shadow: inset 0px -3px 0px #1495cd;
  }
}

.basic {
  label {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 25px;
    color: #7d8fb2;
  }

  input {
    background: #f7f9fc;
  }

  .inputRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .inputContainer {
      width: 170px;
    }
  }

  .inputContainer {
    position: relative;

    .SwatchCheckeredContainer {
      position: absolute;
      display: flex;
      height: 18px;
      width: 18px;
      border-radius: 10px;
      top: 12px;
      left: 9px;
      overflow: hidden;

      .CheckeredIcon {
        position: absolute;
      }

      .ColorSwatch {
        display: block;
        height: 18px;
        width: 18px;
        z-index: 1;
        border-radius: 10px;
        border: solid 1px $toolbar-placeholder-color;
      }
    }

    .colorValueInput {
      padding-left: 32px !important;
    }
  }
}

.advanced {
  .inputRow {
    .inputContainer {
      .shadowCssInput {
        padding: 8px;
        height: 216px;
        resize: none;
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #7d8fb2;
    }
  }
}
