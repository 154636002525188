@import '../../../assets/styles/admin/variables.scss';

@function prefix($name) {
  @return '.prefix:global(__#{$name})';
}

:global(.admin) .StyleGuideEditor {
  margin-bottom: 2rem;
  overflow: inherit !important;
  > section {
    &:not(:first-child) {
      padding-top: 2rem;
      border-top: solid thin rgba(0, 0, 0, 0.08);
    }
    &:not(:last-child) {
      padding-bottom: 2rem;
    }
  }

  .styleSection {
    .controls {
      flex: 0 0 200px;
    }

    .example {
      flex: 1 1 auto;
      margin-left: 100px;
    }

    .label {
      margin-bottom: 1rem;
    }

    .styleSectionHeader {
      display: flex;
      flex: 1;
      justify-content: space-between;
      p {
        color: #14213d;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        // text-transform: capitalize;

        small {
          color: $dark-gray-blue-faded;
          font-weight: 500;
          font-size: 12px;
          margin-left: 10px;
          text-transform: lowercase;
        }
      }

      .bgToggles {
        display: flex;
        gap: 8px;
      }

      .background {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 2px;
        width: 124px;
        height: 40px;
        background: rgba(242, 245, 253, 0.5);
        border-radius: 4px;

        span {
          cursor: pointer;
          display: flex;
          align-items: center;
          border-radius: 4px;

          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: rgba(20, 33, 61, 0.8);

          padding-left: 12px;
          padding-right: 12px;
          margin: 4px;
          height: 28px;

          &.lightBackground {
            &.active {
              background: #ffffff;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
            }
          }

          &.darkBackground {
            &.active {
              color: #ffffff;
              background: #090b45;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
            }
          }
        }
      }

      .device {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;

        width: 80px;
        height: 40px;

        background: rgba(242, 245, 253, 0.5);
        border-radius: 4px;

        > span {
          cursor: pointer;
          display: flex;
          align-items: center;
          border-radius: 4px;

          display: flex;
          padding: 6px;
          margin: 4px;
          cursor: pointer;

          color: #647fb4;

          > svg {
            opacity: 0.8;
          }

          &.active {
            background: #ffffff;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
            color: #4040d9;
            > svg {
              opacity: 1;
            }
          }
        }
      }

      .layout {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        background-color: rgba(242, 245, 253, 0.5);
        border-radius: 4px;

        > span {
          display: flex;
          padding: 6px;
          cursor: pointer;

          &.active {
            border-radius: 4px;
            background: #ffffff;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
          }
        }
      }
    }

    .optionsHeader {
      background-color: $light-background-color;
      border: 1px solid $light-gray-blue;
      border-bottom: none;
      height: 40px;
      line-height: 2;
      border-radius: 2px 2px 0 0;

      span {
        border-radius: 20px;
        text-transform: uppercase;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 1rem;
        cursor: pointer;
        color: #647fb4;
        letter-spacing: 0.05rem;
        transition: all 0.14159s;

        &:first-child {
          margin-left: 10px;
        }

        &:hover {
          color: #14213d;
        }

        &.activeStyleTab {
          background-color: rgba(220, 228, 244, 0.6);
          color: #14213d;
        }
      }
    }

    .styleSectionOptions {
      display: flex;
      border: 1px solid $light-gray-blue;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
      flex-wrap: wrap;
      gap: 1px;

      .styleOption {
        background-color: white;
        box-shadow: $light-gray-blue 1px 0 0 0, $light-gray-blue 0 1px 0, $light-gray-blue 1px 1px 0;

        span {
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.05rem;
          text-transform: uppercase;
          margin: 0.2rem 0 0.4rem;
          color: $dark-gray-blue-faded;
        }

        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 240px;
        min-width: 100px;

        padding: 5px 10px;
        transition: background-color 0.14159s;

        &:hover {
          background-color: $light-background-color;
        }

        &.extendWidth {
          width: 200px;
        }

        &:last-child {
          flex-grow: 0;
        }

        input {
          background-color: transparent;
          border: none;
          box-shadow: none;
          padding: 3px 0 0;
          font-size: 1.4rem;
          height: fit-content;
          margin-bottom: 0;
        }

        .multipleFields {
          display: grid;
          grid-auto-flow: column;
          & > input {
            max-width: 42px;
          }
        }
      }

      .Select {
        //  TO UPDATE THE CONTAINER STYLE
        #{prefix(control)} {
          background-color: transparent;
          border: none;
          // border-bottom: solid thin $primary-color;

          background-position: bottom;
          background-size: 3px 1px;
          background-repeat: repeat-x;

          box-shadow: none;
          border-radius: 0px;
          margin-left: -2.5px;
          min-height: auto;
          cursor: pointer;

          // opacity: 1;
          // transition: opacity 0.14159s;
          // &:hover {
          //   opacity: 0.6;
          // }
        }

        //  TO UPDATE THE SELECT VALUE CONTAINER STYLE
        #{prefix(value-container)} {
          flex: 0 0 auto;
          align-items: center;
          padding: 0;
        }

        #{prefix(indicator-separator)} {
          display: none;
        }

        #{prefix(dropdown-indicator)} {
          padding: 0;
          color: #b2c1df;

          svg {
            height: 14px;
            width: 14px;
          }
        }

        //  TO UPDATE THE STYLE SELECTED VALUE IN THE CONTAINER
        #{prefix(single-value)} {
          text-overflow: unset;
          max-width: none;
          position: static;
          top: unset;
          transform: unset;
          color: #14213d;
          margin-right: 2rem;
          font-size: 1.4rem;
          font-weight: 400;
          cursor: pointer !important;
        }

        div[class*='Input'] {
          margin: 0 2px;
          padding: 0;
        }

        #{prefix(single-value)} + div[class*='Input'] {
          position: absolute;
          left: 0;
        }

        input {
          margin: 0;
          height: unset;
          color: $primary-color !important;
          box-shadow: none;
        }

        //  TO UPDATE THE STYLES FOR MENU LIST
        #{prefix(menu)} {
          margin: 15px 0 0 -12.5px;
          border: 1px solid #dce4f4;
          border-radius: 5px;
          min-width: 230px;
          overflow: hidden;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
          background-color: $light-background-color;
        }

        #{prefix(menu-list)} {
          padding: 0;
        }

        #{prefix(option)} {
          white-space: nowrap;
          font-size: 1.4rem;
          color: #14213d;
          cursor: pointer !important;
        }

        #{prefix(option--is-selected)} {
          color: #fff;
        }

        display: inline-block;
      }
    }

    .prototype {
      display: flex;
      justify-content: center;
    }

    .layoutContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;

      .svgContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        gap: 18px;

        .line1,
        .line2,
        .line3 {
          width: 100%;
          height: 8px;
          border-radius: 4px;
          background-color: #c4c4c4;
        }
        .line3 {
          width: 50%;
        }

        .buttonContainer {
          display: flex;
          width: 80px;
          background: #707fe3;
          height: 30px;
          border-radius: 15px;
          align-items: center;
          .buttonLine {
            background: white;
            height: 8px;
            width: 60px;
            border-radius: 4px;
            margin: auto;
          }
        }
      }

      &.isMobile {
        flex-direction: column;
        align-items: center;

        svg {
          height: 102px;
          width: min-content;
          background-color: #fff;
        }
      }
      svg {
        background-color: #fff;
      }

      &.boxLayoutContainer:not(.isMobile) {
        padding: 10px;
      }
      &.boxLayoutContainer.isMobile {
        svg {
          height: 102px;
          width: 146px;
          background-color: #fff;
        }
      }
    }
  }
}

.fontsStylesHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 23px !important;
  svg {
    color: $blue;
    margin: 0 10px 0 auto;
    cursor: pointer;
  }
}

.controlsContainer {
  display: flex;
}

.deleteIcon {
  cursor: pointer;
  border-radius: 2px;
  font-size: 1.2rem;
  transition: all 0.14159s;
  height: 40px;
  margin-left: 16px;
  // margin-top: -5px;
  // margin-left: 24px;
  &:hover {
    opacity: 0.8;
  }
}

// Example overrides
// --------------------------------------------------------------------------

.StyleGuideEditor :global .sriracha {
  margin: 1rem 0 2rem;
  border-radius: 2px;

  p {
    max-width: 36em;
  }

  // Blog content
  .blog-content {
    margin: 0;
    padding: 0;
  }

  // Header menu
  header.primary-header {
    padding: 2rem 3rem;
    box-shadow: none;
    border-radius: 2px;
    border: solid thin rgba(0, 0, 0, 0.08);
  }

  // Footer
  footer.main-footer {
    padding: 2rem 3rem 0;
    border-radius: 2px;
    border: solid thin rgba(0, 0, 0, 0.08);

    .primary-footer {
      padding: 0;

      .menu li a {
        font-weight: 400;
      }
    }
  }

  // Big quotes
  section.quotes-section {
    blockquote {
      width: auto;
    }

    .quotes {
      display: block;
    }
  }
}

.layoutPaddingTop {
  position: absolute !important;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  color: $dark-gray-blue;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.05em;
}

.layoutPaddingLeft {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translate(-100%, -50%);
  color: #647fb4;
  font-weight: 500;
  font-size: 11px;
}

.layoutPaddingRight {
  position: absolute !important;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  color: #647fb4;
  font-weight: 500;
  font-size: 11px;
}

.layoutPaddingBottom {
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
  color: $dark-gray-blue;
  font-weight: 500;
  font-size: 11px;
  top: 100%;
}

.topSpacing {
  margin-top: 4rem !important;
}

.textWithSeparater {
  position: relative;
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 2.3rem;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #647fb4;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }

  &.textWithSeparaterBox {
    top: -25%;
    width: 100%;
    position: absolute;
  }

  & span {
    height: 11px;
    display: inline;
    border-left: 1px solid #647fb4;
    position: absolute;
    top: 2px;

    &:last-of-type {
      right: 0;
    }
  }
}

.sectionContainer {
  display: flex;
  justify-content: center;
  background: rgba(248, 250, 254, 1);
  padding: 4rem;

  .prototype > div {
    background-color: #c0f3bf;

    & > div {
      margin: auto;
    }
  }
}

.boxContainer {
  & > main {
    background: rgba(248, 250, 254, 1);
    padding: 4rem;

    .prototype {
      & > div {
        padding: 0;

        .boxLayoutContainer {
          & div {
            padding: 0 !important;
            display: flex;
            background-color: rgb(192, 243, 191);

            &:last-child svg {
              border-color: rgb(192, 243, 191);
              border-style: solid;
            }
          }
        }
      }
    }
  }
}
