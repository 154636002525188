@import '../../../../../../assets/styles/admin/variables.scss';

.listContainer {
  padding-bottom: 20px;
}

.container {
  cursor: pointer;

  a {
    pointer-events: none;
  }
}

.container:hover > .overlay,
.container:hover > .overlayDisabled {
  opacity: 1;
}

.overlay,
.overlayDisabled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  opacity: 0;
  transition: opacity 0.14159s;

  // pointer-events: none;
}

.overlayDisabled {
  cursor: not-allowed;
  svg,
  span {
    opacity: 50%;
  }
}

.copy {
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem 2rem;
  background: rgba(20, 33, 61, 0.8);
  border-radius: 30px;
  pointer-events: all;

  span {
    font-weight: 500;
    font-size: 18px;
  }
}

.selectedProductCollection {
  border: 2px solid #dee4f3;
  border-radius: 4px;

  .selectedItemProduct {
    border: none !important;
    border-bottom: 1px solid #dee4f3 !important;
    border-radius: 0 !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

.itemContainer {
  margin: 24px 0 !important;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  border: 2px solid #dee4f3;
  border-radius: 5px;

  &.selectedItem {
    margin-top: 0 !important;
    cursor: default;
  }

  &.selectedItemProduct {
    margin: 0 !important;
    cursor: default;

    img {
      display: flex;
      margin-bottom: 0;
      flex-basis: calc(50% - 40px);
      justify-content: center;
      flex-direction: column;
      max-width: 40px;
      max-height: 49px;
      height: auto;
      border-radius: 2px;
    }
  }

  img {
    max-width: 82px;
    height: auto;
    max-height: 98px;
    margin-bottom: 0;
    border-radius: 4px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
  }

  .collectionImages {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 81px;
    gap: 2px;
    border-radius: 4px;

    img {
      display: flex;
      margin-bottom: 0;
      flex-basis: calc(50% - 40px);
      justify-content: center;
      flex-direction: column;
      max-width: 40px;
      max-height: 49px;
      height: auto;
    }
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-right: 1rem;

    .tag {
      background-color: $dark-blue;
      padding: 4px 8px;
      font-size: 12px;
      color: #fff;
      border-radius: 20px;
    }
  }
}

.productsList {
  border-top: 1px solid #dee4f3;
}

.newProductButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  column-gap: 1rem;
  color: $purple;
  margin: 1rem 0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.productCollection {
  & [class*='product-item'] {
    margin-top: -2px;
    border-radius: 0px;

    &:focus {
      margin-top: -2px;
      z-index: 9;
    }
  }

  & div:first-child [class*='product-item'] {
    margin-top: 0px;
    border-top: 2px solid $product-border-color;
    border-radius: 4px 4px 0px 0px;

    &:focus {
      margin-top: 0px;
    }
  }

  & div:last-child [class*='product-item'] {
    border-radius: 0px 0px 4px 4px;
  }

  & div:first-child:last-child [class*='product-item'] {
    border-radius: 4px;
  }
}

div.moreResults {
  display: flex;
  justify-content: center;
  padding: 12px;
}

.resultsStatement {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
}

.collectionItem {
  flex: 1;
  margin-bottom: 2rem;

  .iconContainer {
    display: block;
    width: 3.2rem;
    height: 3.2rem;

    svg {
      transform: rotate(270deg);
      color: #647fb4;
    }
  }
}

.label {
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #647fb4;
}

ul.itemsList {
  border: 2px solid #dee4f3;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  margin-top: 2rem;

  & > li.item {
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;

    &:hover {
      background: #f8fafe;
    }

    &:not(:last-child) {
      border-bottom: 2px solid #dee4f3;
    }

    & > span.itemLabel {
      font-size: 1.6rem;
      line-height: 2.5rem;
      flex: 1;
      color: #14213d;
    }
  }
}

.tooltip {
  max-width: 280px !important;
  color: #ffffff !important;
  padding: 8px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px !important;
}

span.tagLabel {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  &.product {
    color: #ca0b4a;
    border: 1px solid #ca0b4a;
  }
  &.parent {
    color: #0065c1;
    border: 1px solid #0065c1;
  }
  &.child {
    color: #0065c1;
    border: 1px solid #0065c1;
  }
  &.bundle {
    color: #b85c00;
    border: 1px solid #b85c00;
  }
  &.none {
    display: none;
  }
}

span.itemThumbnail {
  background: #eff3fb;
  border-radius: 0.2rem;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .thumbnailPlaceholderContainer {
    display: flex;
    width: 20px;
    flex-wrap: wrap;
    gap: 0.2rem;

    .thumbnailPlaceholder {
      display: block;
      width: 0.7rem;
      height: 0.7rem;
      background: rgba(100, 127, 180, 0.55);
      border-radius: 2.5px;
    }
  }

  .itemImage {
    width: 100%;
    object-fit: cover;
    margin: 0;
    height: 100%;
    object-position: top;
  }

  &.styleForAdminPanel {
    background: transparent;
    width: 2rem;
    height: 2rem;
  }
}

span.itemLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.labelElement {
  cursor: pointer;
}
