@import '../assets/styles/admin/variables.scss';

@function prefix($name) {
  @return '.prefix:global(__#{$name})';
}

:global(.admin) {
  .TagSelect {
    border: 1px solid #d7dae2;
    border-radius: 4px;
    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);

    #{prefix(container)} {
      border-color: #1495cd;
      outline: 0;
    }
    #{prefix(placeholder)} {
      font-size: 15px;
    }
    #{prefix(control)} {
      padding: 0 2px;
      border: none;
      background-color: #f8fafe;
    }

    #{prefix(indicator-separator)} {
      display: none;
    }

    #{prefix(multi-value)} {
      text-overflow: unset;
      max-width: none;
      position: static;
      top: unset;
      transform: unset;
      color: #fff;
      font-weight: 500;
      cursor: pointer !important;
      background: #1495cd;
      font-size: 1.4rem;
    }

    #{prefix(multi-value__label)} {
      color: #fff;
    }

    #{prefix(multi-value__remove)} {
      &:hover {
        background-color: #1495cd;
        color: #fff;
      }
    }

    div[class*='Input'] {
      margin: 0 2px;
      padding: 0;
    }

    input {
      margin: 0;
      height: unset;
      color: #000;
      box-shadow: none;
    }

    #{prefix(menu)} {
      margin: 4px 0 0;
      border: none;
      border-radius: 5px;
      min-width: 230px;
      box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.12);
    }

    #{prefix(menu-list)} {
      padding: 0;
    }

    #{prefix(option)} {
      white-space: nowrap;
      cursor: pointer !important;
    }
  }
}
