.container {
  position: absolute;
  width: 100%;
  margin: auto;
  border-radius: 3px;
  z-index: 2;
  border: none;
  margin-top: -2px;

  .buttonLineContainer {
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .button {
    position: absolute;
    z-index: 6;
    top: -20px;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    background: #4040d9;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 44px;
    color: #ffffff;
    height: 40px;
    font-family: 'Inter';

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
    }

    &:hover {
      background: #292bcf;
    }
  }

  .line {
    background: #4040d9;
    width: 100%;
    height: 3px;
  }

  .showSectionButton {
    visibility: visible;
    opacity: 1;
  }

  .tooltipWrapper {
    text-align: center;
    margin: auto;
    z-index: 998;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    visibility: hidden;

    .icon {
      cursor: pointer;
    }
  }

  .showTooltip {
    transition: opacity 300ms ease-in-out;
    opacity: 1;
    visibility: visible;
  }

  .tooltipWrapper .tooltip {
    bottom: 100%;
    display: block;
    left: -84px;
    margin-bottom: 38px;
    opacity: 0;
    padding: 20px;
    pointer-events: none;
    position: absolute;
    width: 168px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.07s ease-out;
    -moz-transition: all 0.05s ease-out;
    -ms-transition: all 0.05s ease-out;
    -o-transition: all 0.05s ease-out;
    transition: all 0.05s ease-out;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px 16px;
    gap: 4px;
    isolation: isolate;
    background: #ffffff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .tooltipWrapper .tooltip:before {
    bottom: -20px;
    content: ' ';
    height: 20px;
    left: 0;
    position: absolute;
  }

  .tooltipWrapper .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #ffffff 10px;
    bottom: -10px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    width: 0;
  }

  .tooltipWrapper .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  /* IE can just show/hide with no transition */
  .lte8 .tooltipWrapper .tooltip {
    display: none;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .emptyDiv {
      width: 10px;
    }
  }

  .tooltipText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #14213d;
  }
}
