@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

.flexContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  text-transform: capitalize;
}

:global(.admin) {
  ul.PageList {
    width: 100%;

    li {
      border-top: 1px solid $light-gray-blue;
      display: flex;
      align-items: center;
    }

    li:first-child {
      border-top: none;
    }

    li:not([disabled]) {
      cursor: pointer;

      &:hover {
        background-color: rgba(111, 127, 226, 0.08);
      }
    }

    li > div {
      padding: 1.25rem 0.75rem;
      font-size: 1.4rem;
      border: none;
    }

    li > div:first-child {
      padding-left: $table-gutter;
    }

    li > div:last-child {
      padding-right: $table-gutter;
    }

    li > div small {
      font-size: 1em;
      color: $dark-gray-blue;
    }
  }

  .label {
    display: inline-block;
    margin-left: 1rem;
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: $orange;
    vertical-align: 1px;
    border-radius: 2px;
  }

  %statusIndicator {
    $size: 6px;
    display: inline-block;
    height: $size;
    width: $size;
    vertical-align: 2px;
    margin-right: 7px;
    border-radius: 999999px;
  }

  .statusIndicatorDraft {
    @extend %statusIndicator;
    background-color: #b2c1df;
  }

  .statusIndicatorLive {
    @extend %statusIndicator;
    background-color: $green;
  }

  .name {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .status {
    flex: 0 0 auto;
    width: 12%;
  }

  .sessions {
    flex: 0 0 auto;
    width: 15%;
  }

  .lastUpdated {
    flex: 0 0 auto;
    width: 25%;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > *:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  .options {
    @include unstyled-button;
    box-sizing: content-box;
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
    padding: 0.5rem 0;
    vertical-align: middle;
    color: $gray-blue;

    box-shadow: none;
    transition: opacity 0.14159s;

    &,
    &:focus,
    &:active {
      opacity: 1;
    }

    svg {
      height: 20px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .viewAll {
    div {
      flex: 1;
      text-align: center;
      color: $link-color;
      font-size: 1.25rem !important;
      padding: 1.5rem 0 !important;
      font-weight: 500;
    }

    background-color: $light-background-color;
    transition: opacity 0.14159s;
    &:hover {
      opacity: 0.7;
    }
  }
}
