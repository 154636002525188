@import '../../../../assets/styles/admin/variables.scss';

.MediaLibrary {
  .content {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 1.5rem;
    background-color: #1f2258;
    flex: 4;

    > p {
      font-size: 1.5rem;
      color: $text-color;
    }

    .imageContainer {
      border-radius: 2px;
      max-width: 220px;
      min-width: 220px;
      padding: 1rem;
      margin: 0.5rem;
      transition: all 0.1s;
      border: 2px transparent solid;

      .error {
        color: $red;
        font-size: 12px;
      }

      &.selected {
        border: 2px solid $primary-color;
      }

      .icon {
        height: 80px;
        width: inherit;
        background-color: #fff;
        padding: 10px;
      }

      .altEditHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-size: 1.4rem;
          color: #ffffff;
          overflow: hidden;
          width: 190px;
          white-space: nowrap;
        }
      }
      figure {
        margin: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        overflow-x: hidden;

        img {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
          margin: 0;
          vertical-align: middle;
          border-radius: 2px;
          background: #fff;
        }

        figcaption {
          font-size: 1.4rem;
          color: #8499c2;
          overflow: hidden;
          max-width: 180px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .caption {
          margin-top: 1rem;
          display: flex;
          width: -webkit-fill-available;
          justify-content: space-between;
          align-items: center;
          height: 30px;

          span {
            font-size: 14px;
          }

          div {
            display: flex;

            input {
              padding: 0;
              margin: 0;
              width: 4rem;

              &:first-child {
                margin-right: 1rem;
              }
            }
          }
        }
        &:hover {
          figcaption {
            color: white;
          }
        }
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
        cursor: pointer;
        img {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .spinner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 220px;
      min-width: 220px;
    }
  }

  .Loader {
    display: block;
    position: absolute;

    z-index: 1000;
    bottom: 50px;
    left: 50%;
    margin-left: -40px;

    background: rgba(9, 11, 69, 0.8);
    padding: 2rem 3rem;
    border-radius: 4px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
    text-align: center;

    div {
      display: inline-block !important;
    }

    h3 {
      font-weight: normal;
      font-size: 1.4rem;
      margin: 10px 0 0;
      text-align: center;
      color: #8499c2;
    }

    &.dangerText {
      left: 35%;
      margin-left: 0;
      h3 {
        margin: 0;
        color: $red;
      }
    }
  }

  &.addPadding {
    padding-right: 250px;

    .Loader {
      margin-left: -160px;
    }
  }

  &.mediaNoResult {
    margin: auto;
  }
}
