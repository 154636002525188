:global(.admin) .HeaderFooterEditor {
  padding: 0;
  border-radius: 2px !important;
  overflow: visible !important;

  :global .sriracha {
    header {
      position: relative;
      padding-left: 3rem;
      padding-right: 3rem;
      border-radius: 2px 2px 0 0;
      z-index: 1;

      .logo img {
        margin: 0;
        vertical-align: middle;
      }

      nav a {
        > span {
          display: inline-block;
        }

        [empty='true'] + svg {
          color: #ccc;
        }
      }

      .cart-icon {
        cursor: pointer !important;
        margin-top: 5px;
      }

      :global(.secondary-navigation) {
        top: 25px;
        // display: initial !important;
      }
    }

    footer {
      border-radius: 0 0 2px 2px;

      position: relative;
      [contenteditable] {
        caret-color: rgba(255, 255, 255, 0.8);
      }
      [empty='true'] [contenteditable] {
        color: rgba(255, 255, 255, 0.35);
      }
      &.light-bg {
        [contenteditable] {
          caret-color: rgba(0, 0, 0, 0.8);
        }
        [empty='true'] [contenteditable] {
          color: rgba(0, 0, 0, 0.35);
        }
      }

      .primary-footer {
        padding: 3rem;
      }
      .secondary-footer {
        padding: 2rem 3rem;
        border-radius: 0 0 2px 2px;
      }

      .social-icons {
        a:not(.active) {
          opacity: 0.3;
          &:hover {
            opacity: 0.7;
          }
        }

        a.active {
          opacity: 1;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.AddHeaderMenuItem {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.2);
  transition: color 0.14159s;

  &:hover {
    color: rgba(0, 0, 0, 0.35);
  }
}

:global(.dark-bg) .AddHeaderMenuItem {
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: rgba(255, 255, 255, 0.55);
  }
}

:global(.secondary-navigation) .AddHeaderMenuItem {
  color: rgba(0, 0, 0, 0.2);

  &:hover {
    color: rgba(0, 0, 0, 0.35);
  }
}

.AddFooterColumnWrapper {
  flex: 0 0 auto;
  margin-right: 10%;

  &:last-child {
    margin-right: 0;
  }
}

.AddFooterColumn,
.AddFooterColumnItem {
  color: rgba(255, 255, 255, 0.3);
  transition: color 0.14159s;
  height: 30px;

  &:hover {
    color: rgba(255, 255, 255, 0.45);
  }
}

:global(.light-bg) .AddFooterColumn,
:global(.light-bg) .AddFooterColumnItem {
  color: rgba(0, 0, 0, 0.3);

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
}

.AddFooterColumn {
  margin-top: -5px;
}

.AddFooterColumnItem {
  margin-top: 1.5rem;
}

.pageContent {
  background: #ffffff;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.3);
}

.header-links {
  padding: 0 30px;
}

.headerIndexing {
  display: flex;
  position: relative;
  z-index: 2 !important;
  .containerIndexing {
    position: relative;
    z-index: 2;
  }
}
.footerIndexing {
  position: relative;
  z-index: 1;
  .containerIndexing {
    position: relative;
    z-index: 2;
  }
}
