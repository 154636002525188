.ErrorWrapper {
  text-align: center;
  max-width: 600px;
  padding: 2rem;
  margin: 0 auto;
}

.BorderedError {
  border: 2px solid red;
  position: relative;
}

.ErrorHelper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: red;
  cursor: help;
  font-size: 24px;
}
