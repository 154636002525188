@import '../../../../../../assets/styles/admin/variables.scss';
@import '../../../../../../assets/styles/admin/mixins.scss';

$padding: $toolbar-padding / 2;

$text: rgba($text-color, 0.8);

.container {
  background-color: $background-medium;
  padding: 16px;
  text-align: left;

  & > div {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  .logicalOperator {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    /* Text / Default */

    color: $text-color;

    span {
      color: $blue;
      margin: 0 5px;
      cursor: pointer;
      display: flex;
    }
  }

  .conditions {
    display: flex;
    gap: 12px;
    align-items: center;

    .conditionContainer {
      min-width: 55px;

      .condition {
        padding: 6px 12px;
        background-color: $dark-gray-blue;
        border-radius: 20px;
        color: #fff;
        font-size: 16px;
        line-height: 25px;
        text-transform: capitalize;
      }
    }

    &:only-child {
      .conditionContainer {
        min-width: auto;
      }
    }

    svg {
      color: $dark-gray-blue;
      cursor: pointer;
    }

    .inputContainer {
      position: relative;
      display: flex;
      gap: 12px;
      input {
        background-color: #ffffff;
        border: 1px solid #dee4f3;
        border-radius: 4px;
        font-size: 16px;
        line-height: 25px;
        font-family: Inter;
        color: #475266;
        min-width: 240px;
        height: 36px;
        padding-right: 15px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.paramContainer {
          min-width: 160px;
          max-width: 160px;
        }

        &:focus {
          border: 1px solid #00aaff;
          caret-color: #00aaff;
        }

        &.operator {
          max-width: 126px;
          min-width: 126px;
          cursor: pointer;
          caret-color: transparent !important;
        }

        &.utm {
          cursor: pointer;
          caret-color: transparent !important;
        }
      }
      &.isDropdown {
        & > svg {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
        &::after {
          content: '';
          background-image: url('../../../../../../assets/images/icon-chevron-down.svg');
          position: absolute;
          right: 9.38px;
          top: 15px;
          width: 8px;
          height: 5px;
          background-repeat: no-repeat;
          background-size: 100%;
        }

        .isSmartInput {
          padding-left: 35px;
        }
      }
    }
  }
}

.ListActive {
  background-color: $background-medium;
}

.Drawer {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 2;

  min-width: 310px;
  max-height: 350px;
  overflow-y: auto;

  &.operator {
    min-width: 195px;
    max-width: 195px;

    .divider {
      width: calc(100% - 16px);
      margin: 8px auto;
      background-color: #dee4f3;
      height: 1px;
    }
  }

  > ul {
    list-style: none !important;
    margin-bottom: 0;
    padding: 8px 0 !important;

    .GroupTitle {
      padding: 10px 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      text-transform: capitalize;
      color: $darkest-blue;
    }

    > li {
      padding: 10px 32px;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 9.25px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      color: $text;

      transition: background-color 0.14159s;
    }

    &.removePadding {
      padding: 0 !important;
    }
  }

  &.enter {
    opacity: 0;
  }
  &.enterActive {
    opacity: 1;
    transition: opacity 0.14159s;
  }
  &.exit {
    opacity: 1;
  }
  &.exitActive {
    opacity: 0;
    transition: opacity 0.14159s;
  }
}
