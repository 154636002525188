@import '../assets/styles/admin/variables.scss';

.HeaderBar {
  height: 72px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

  &.fixed {
    @extend .HeaderBar;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 998;
  }

  .left,
  .center,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .left {
    left: 24px;
  }
  .center {
    left: 0;
    right: 0;
    left: 76px;
    font-size: 2.4rem;
    font-weight: 700;
    color: #14213d;
  }
  .right {
    right: 24px;

    svg {
      margin-top: -2px;
    }
  }

  .backButton,
  .backButton2 {
    display: block;
    transition: all 0.12s;
    cursor: pointer;

    svg {
      height: 40px;
      width: 40px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .backButton2:hover svg {
    opacity: 0.8;
  }
}
