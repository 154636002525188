// Unstyle

// FIXME: Ideally these would be incorporated into base styles, and
// any additional styling (e.g. primary-button) would be opt in.

@mixin unstyled-button {
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  line-height: normal;
  font: inherit;
  color: inherit;
  text-transform: inherit;
}

@mixin unstyled-input {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0 !important;
  width: auto;
  height: auto;
  box-shadow: none;
}
