@import '../../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .lgIcon {
    display: block;

    height: 30px;
    width: 30px;
    margin: 0 1rem 0 0;
  }

  .name {
    font-weight: 500;
  }

  .backButton {
    img {
      @extend .lgIcon;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
  }

  .headerOptionContainer {
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;

    .iconHolder {
      display: flex;
      margin-left: auto;
      .headerOptions {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px solid $blue;
        align-items: center;
        display: flex;
        align-content: center;
        text-align: center;
        justify-content: center;
        margin-left: 10px;
        // &.icoTrash {
        //   border-color: $dark-gray-blue-faded;
        // }

        svg {
          color: $blue;
        }
        &:hover {
          cursor: pointer;
        }
        a {
          display: block;
          padding: 0.3rem 0.8rem;
          img {
            max-width: 100%;
            margin-left: 1px;
            height: 12px;
            display: block;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .navBarContainer {
    height: 50px;
    display: flex;
    width: 100%;

    span {
      cursor: default;
      text-transform: capitalize;
      font-size: 1.3rem;
      padding: 0 2.5rem;
      font-weight: 500;
      color: rgba(20, 33, 61, 0.6);
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: color 0.14159s;

      &:hover {
        color: rgba(20, 33, 61, 1);
      }
      &.activeLabel {
        border-bottom: 2px solid $primary-color;
        color: rgba(20, 33, 61, 1);
        background-image: url('../../../../assets/images/icon-header-current-section.svg');
        background-size: 10px 4px;
        background-repeat: no-repeat;
        background-position: bottom center;
      }

      &.count {
        background-color: $purple;
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        color: white;
        font-size: 10px;
        font-weight: 500;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        margin-left: 0.8rem;
        padding: 2px 3px;
        height: auto;
      }
    }
  }
}

.tasksListContainer {
  width: 100%;
  .description {
    display: flex;
    flex-direction: column;
    text-overflow: inherit !important;
    white-space: normal !important;
    padding: 0 1rem;

    strong {
      display: flex;
      align-items: center;

      svg.edit {
        color: $blue;
        margin-left: 5px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      svg.heading {
        color: #000;
        margin-right: 5px;
      }
    }

    span {
      margin-left: 20px;
    }

    &.completedTask {
      text-decoration: line-through;
    }

    .details {
      white-space: normal !important;
      text-overflow: unset !important;
    }
  }
  .markButtonContainer {
    div {
      color: $blue;
      font-weight: bold;
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        opacity: 0.7;
      }
    }

    .completed {
      color: $green;
    }
  }

  .linkText {
    color: $blue;
    cursor: pointer;
  }
}

.fieldsetOptionalStyles {
  margin-bottom: 1rem !important;
}

.colorDeleteActionText {
  margin-top: 1rem !important;
  color: $text-color-light !important;
  font-size: 1.4rem;
  span {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  a {
    color: $danger-color;
    text-decoration: underline;
    cursor: pointer;
  }
}
