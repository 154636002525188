[role='button'] {
  cursor: pointer;
}
[role='button'][disabled] {
  cursor: default;
}

button.unstyled {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  text-transform: none;
  /* font-style: inherit;
  font-size: inherit;
  font-family: inherit; */
  font: inherit;
}

a[disabled],
a[disabled] img {
  cursor: not-allowed;
}

input.chameleon,
textarea.chameleon {
  background: none !important;
  border: none !important;
  font: inherit !important;
  text-align: inherit !important;
  line-height: inherit !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 1em !important; // Or maybe inherit?
  width: 100%;
  color: inherit !important;
  text-transform: inherit !important;
  height: inherit !important;
  white-space: inherit !important;
  text-decoration: inherit !important;
  text-indent: inherit !important;
  text-overflow: inherit !important;
}

// Old inline-svg support
// TODO: remove when the react-inlinesvg library is replaced

.isvg {
  display: inline-block;
  vertical-align: middle;
}

.isvg.color {
  stroke: currentColor;
  fill: currentColor;
}

.isvg svg {
  height: inherit;
}
.isvg.stroke svg,
.isvg.stroke svg * {
  stroke: inherit;
}
.isvg.fill svg,
.isvg.fill svg * {
  fill: inherit;
}

/* Header */
header.primary-header {
  /**
   * FIXME: These styles are overrides for sriracha.css within the Editor
   * component. As the project evolves, this may need to be rethought.
   */
  position: relative !important;
  margin-top: 0;
  z-index: 0;
  // border-radius: 2px 2px 0 0;

  nav a[disabled],
  nav a[disabled]:hover {
    opacity: 0.3;
  }
}

footer .primary-footer .menu li a:focus {
  opacity: 1;
}

/* Sections */

section.is-not-editable .section-box,
section.is-not-editable .section-box [data-aos].aos-animate {
  pointer-events: none;

  .add-actions {
    display: none;
  }
}

section.section {
  position: relative;
  overflow: visible;

  &.top-story-and-asides {
    overflow: hidden;
  }
}

section.section:first-of-type {
  margin-top: 0 !important;
}

section.section.in-motion {
  // z-index: 2;
}

section.height-full {
  min-height: calc(100vh - #{$admin-bar-height});

  @include respond-above(md) {
    min-height: calc(100vh - #{$admin-bar-height});
  }
}
section.height-full-desktop,
section.height-full-desktop > .box,
section.height-full-desktop > div > .box {
  min-height: calc(1000px - #{$admin-bar-height});
}
section.height-full-tablet,
section.height-full-tablet > .box,
section.height-full-tablet > div > .box {
  min-height: calc(820px - #{$admin-bar-height});
}
section.height-full-mobile,
section.height-full-mobile > .box,
section.height-full-mobile > div > .box {
  min-height: calc(667px - #{$admin-bar-height});
}

// Unstack ContentEditables
// ----------------------------------------------------------------------------

// Override default browser focus style for all contenteditables
// (includeing QuillJS).
[contenteditable='true']:focus {
  outline: none;
}

.spark-contenteditable {
  cursor: text;
}

// Create placeholders with :before elements based on the standard placeholder
// attribute.
.spark-contenteditable:empty:before {
  content: attr(placeholder);
  display: block; // For Firefox
}

// Text color the placeholders for light and dark backgrounds.
.spark-contenteditable:empty:before {
  opacity: 0.35 !important;
}
.dark-bg .spark-contenteditable:empty:before {
  opacity: 0.35 !important;
}

// contenteditables must be blocks for the text cursor to appear.
.spark-contenteditable {
  cursor: text;
}

// But override with inline-block when needed for a particular layout or
// context.
.text-action.spark-contenteditable,
.text-action.spark-contenteditable:empty::before {
  display: inline-block !important;
}

// Image placeholder divs
// ----------------------------------------------------------------------------

section.section picture {
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.14159s ease;
}

section.section picture:hover {
  opacity: 0.7;
}

.center picture.icon {
  margin-left: auto;
  margin-right: auto;
}

img.image-placeholder {
  transition: opacity 0.14159s;

  &:hover {
    opacity: 0.7;
  }
}

.icon img,
.full-image img {
  width: 100%;
}

section.logos-section .logo {
  position: relative;
  img {
    margin: 0;
    vertical-align: middle;
    width: 100%;
  }
}

section.quotes-section .profile.quote-placeholder {
  background-color: #fbfbfb;
  border: dashed 1px rgba(0, 0, 0, 0.12);
  position: relative;

  &:after {
    content: 'Profile';
    color: #b8b8b8;
    font-size: 1.2rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Add actions
// ----------------------------------------------------------------------------

.actions {
  position: relative;
}

.add-actions {
  width: 100%;
  margin: 2rem 0 0;
}

.add-actions-centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.add-actions .collapse-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  fill: #000;
  opacity: 0.2;
  transition: opacity 0.12s;
}
.add-actions .collapse-button:hover {
  opacity: 0.5;
}
.dark-bg .add-actions .collapse-button {
  fill: #fff;
  opacity: 0.5;
}
.dark-bg .add-actions .collapse-button:hover {
  opacity: 0.8;
}

.add-actions .expand-button {
  width: 30px;
  height: 30px;
  color: rgba(9, 11, 69, 0.24);
  transition: color 0.14159s;
}
.dark-bg .add-actions .expand-button {
  color: rgba(255, 255, 255, 0.4);
}
.add-actions .expand-button:hover {
  color: $purple;
}

.add-actions h4 {
  margin-bottom: 3rem;
  position: relative;
  text-align: center;
}

.add-action-buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.add-action-button {
  width: 25%;
  text-align: center;
}

.add-action-button img {
  width: 50px;
  height: 40px;
  transition: all 0.14159s;
  border-radius: 2px;
  margin: 0 auto;
}
.add-action-button div {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: $purple;
  }
}
.add-action-button:not([disabled]):not(.unavailable):hover img {
  transform: scale(1.1);
  // box-shadow: 2px 4px 6px rgba(0,0,0,0.12);
}

.add-action-buttons p {
  font-weight: 500;
  font-size: 12px;
  margin: 0;
}

.add-action-button[disabled]:not(.unavailable) {
  opacity: 0.2;
}

.add-action-button.unavailable p {
  color: #bbb;
}

.add-action-button.unavailable img {
  filter: grayscale(100%);
}

.text-center .add-actions {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.text-left .add-actions {
  margin-left: 0.75rem;
}
.text-right .add-actions {
  margin-left: -0.75rem;
}
.text-right .form-embed {
  margin-left: auto;
  margin-right: 0.75rem !important;
}

// Quill overrides
// ----------------------------------------------------------------------------

// Give quill tooltips a backing mask to improve mouse interactions
.ql-tooltip:after {
  content: '';
  // background: #f0f8;
  position: absolute;
  top: 0;
  left: -15px;
  right: -15px;
  height: 42px;
  z-index: -1;
}

.dark-bg {
  .ql-editor.ql-blank::before,
  .ql-editor:not(.ql-blank) [data-placeholder]::before {
    color: rgba(255, 255, 255, 0.35);
  }
}

section.quotes-section.quote-sm {
  .quote {
    // Move quotation marks to the <span> tag because of the ContentEditable
    // component.
    &:before,
    &:after {
      content: none;
    }

    .ql-blank:before {
      transform: none;
    }

    .ql-editor:not(.ql-blank) p:before {
      content: open-quote;
    }
    .ql-editor:not(.ql-blank) p:after {
      content: close-quote;
    }
  }
}

// Disable anchor hover effect to prevent its ::before tooltip from also
// losing opacity
.ql-container a:hover {
  opacity: 1 !important;
}

// Form embeds

.form-embed {
  transition: opacity 0.14159s;
  &:hover {
    opacity: 0.85;
  }

  .form-embed-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
  }
}

.form-embed-card {
  padding: calc(var(--form-label-margin-y) / 2) var(--form-label-margin-x);
}

// Blogs

.blog-container {
  margin-top: 4rem !important;

  @include respond-above(lg) {
    > aside.blog-aside-sidebar .container {
      z-index: 2;
      top: 8rem;
    }
  }
}

.blog-content {
  position: relative;
  margin-bottom: 0;

  > .ql-container > .ql-editor {
    min-height: 100px;
  }

  article {
    .ql-editor {
      // > * { outline: solid thin #0ff; }

      padding-bottom: 150px;

      figure {
        cursor: pointer;
      }

      img {
        display: block;

        &.ql-align-left {
          margin-left: 0 !important;
          margin-right: auto !important;
        }
        &.ql-align-center {
          margin-left: auto !important;
          margin-right: auto !important;
        }
        &.ql-align-right {
          margin-left: auto !important;
          margin-right: 0 !important;
        }
      }
    }
  }

  .ql-toolbar {
    margin: 0;
    * {
      margin: 0;
    }
  }
}

.blog-listing-zero-state-msg {
  padding-bottom: calc(7 / 12 * 100%);
  position: relative;
  background-color: #fbfbfb;
  color: #b8b8b8;
  border-radius: 2px;
  border: dashed 1px rgba(0, 0, 0, 0.12);
  font-size: 15px;
  letter-spacing: 0.08rem;
  font-weight: 300;

  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
  }
}

// Drag & drop

section.callouts-section .callout {
  position: relative;
}

.sortable-chosen {
  opacity: 0.5;
}

.sortable-ghost {
  opacity: 0;
}

// Primary nav
// ----------------------------------------------------------------------------

header.primary-header {
  nav#primary-nav {
    li {
      position: relative;

      a:not(.spark-contenteditable) {
        &[href] {
          cursor: pointer;
        }
        &:not([href]) {
          cursor: default;
          &:hover,
          &:hover + .dropdown-icon {
            opacity: 0.6;
          }
        }
      }
    }
  }

  .add-child-icon {
    display: none;
    position: absolute;
    top: 25px;
    left: calc(50% - 8px);
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  &:hover .add-child-icon,
  a:focus + .add-child-icon {
    display: inline;
  }

  &.dark-bg .add-child-icon {
    color: rgba(255, 255, 255, 0.6);
  }
}
