@import '../assets/styles/admin/variables';
@import '../assets/styles/admin/mixins';

:global(.admin) {
  main.AdminPanel {
    background-color: $background-color;

    // Sticky bottom with fix for margin-collapse
    box-sizing: border-box;
    padding: 1px 0;
    min-height: 100vh;
    &.notificationBarIsIn {
      min-height: calc(100vh - 4rem);
      max-height: calc(100vh - 4rem);
    }
  }

  section.panelSection {
    margin-left: $nav-width;
    padding: 0 $nav-gutter;
    padding-bottom: 5rem;

    :global(.spark-spinner.fixed) {
      position: fixed;
      margin-top: 100px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    > section,
    > section > div,
    > div > section {
      margin: 20px 0 30px;

      > header {
        margin: 20px 0 15px;

        h1 {
          display: inline-block;
          font-size: 1.8rem;
          margin: 0;
          color: $header-color-gray;
          font-weight: 500;
        }
        small {
          display: inline-block;
          font-size: 1.4rem;
          margin-left: 15px;
          color: $text-color-light;
        }
        svg {
          margin-top: -6px;
        }
      }

      // Material card
      > ul,
      > article {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        overflow: hidden;
      }
    }

    > footer {
      margin: 40px 0 30px;
      text-align: center;
      font-size: 1.4rem;
      color: $dark-gray-blue;
    }
  }
  section.removeBottomPadding {
    padding-bottom: 0rem;
  }
  article {
    padding: 2rem;

    // > :last-child,
    // > :last-child > :last-child {
    //   margin-bottom: 0;
    // }
  }
}

:global(.dragging-helper-class) {
  background-color: #f8fafe;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  font-size: 1.4rem;
  height: 38px;
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  div {
    *:not(:first-child) {
      margin-left: 1rem;
    }

    :global(.tag-helper) {
      background-color: #dce4f4;
      padding: 4px 7px;
      font-size: 10px;
      font-weight: 500;
      border-radius: 2px;
      text-transform: uppercase;
    }
  }

  .options-helper > *:not(:first-child) {
    margin-left: 1rem;
  }
}

:global(.drag-products) {
  z-index: 999;
}
