@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter');

:global(.admin) .Login {
  background-color: #f8fafe;

  // Sticky bottom while preventing margin-collapse issues
  min-height: 100vh;
  padding-bottom: 1px;
  box-sizing: border-box;

  :global(.spark-spinner) {
    margin: 4rem auto;
  }

  form {
    $form-border-radius: 6px;
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    padding: 32px;

    .fieldWithErrors {
      background-color: red;
    }

    fieldset {
      margin-top: 0px;
      margin-bottom: 16px;

      input[type='password'],
      input[type='email'] {
        margin-bottom: 0px;
        height: 48px;
        background-color: #f7f9fc;
        border: 1px solid #dee4f3;
        color: #475266;

        &::placeholder {
          color: #7d8fb2;
        }

        &:focus {
          border: 1px solid #4040d9;
          background-color: #ffffff;
          color: #102040;
        }
      }

      small {
        margin-top: 6px !important;
      }
    }

    small {
      margin-bottom: 6px;
    }

    .terms {
      font-size: 1.3rem;
      color: #667799;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;

      a {
        color: #4040d9;
        text-decoration: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        transition: all 0.14159s;
        cursor: pointer;
      }

      a:hover {
        color: #6666e1;
        opacity: 1 !important;
      }
    }

    .forgotPassword {
      @extend .terms;
      margin-bottom: 20px;
    }

    input[type='submit'] {
      justify-content: center;
      height: 48px;
      background: #4040d9;
      margin-top: 0px;
      margin-bottom: 16px;
      border-radius: 4px;
      transition: all 0.14159s;
      font-weight: 500;
      font-size: 16px;
      &:hover {
        background: #6666e1;
      }
    }
  }

  .centerText {
    margin-top: 4rem;
    text-align: center;
  }

  span.success {
    color: $success-color;
    margin: 0;
    font-size: inherit;
  }

  footer {
    margin: 40px;
    text-align: center;
    color: $text-color-light;
    font-size: 1.5rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  width: 420px;
  margin: 0 auto;
}

.shopifyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;

  text-align: center;
  background-color: #eff8dd;
  width: 100%;
  max-width: 420px;
  height: 104px;
  margin: 0 auto;
  border-radius: 0px 0px 8px 8px;

  .shopifyText {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #030d17;
  }

  .shopifyButton {
    border: none;
    border-radius: 4px;
    margin: 0px;
    width: 103px;
    height: 32px;
    background: #00805f;
    font-size: 14px;
    color: #ffffff;
    transition: all 0.14159s;
    &:hover {
      background: #00664c;
    }
    &:focus {
      background: #00664c;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }
    &:active {
      background: #004d39;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.loginFooter {
  a {
    color: #4040d9 !important;
  }
}
