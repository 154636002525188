.UpDownCounter {
  display: flex;
  align-items: center;
}

.TextInput {
  width: 30px !important;
  height: 28px !important;
  margin-right: 4px !important;
  text-align: center;
  padding: 0.2em;
  font-family: inherit !important;
}
