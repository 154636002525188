@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.customizeBrandingContent {
  color: rgba(20, 33, 61, 0.8);
  display: flex;
  padding: 20px 48px;
  position: relative;
  justify-content: space-between;

  @include respond-below(xl) {
    flex-wrap: wrap-reverse;
    & p {
      width: 60%;
      min-width: 390px;
    }
  }
  & p {
    margin-bottom: 8px;
  }

  .navLinkBackground {
    @include respond-below(xl) {
      left: 110px;
      top: 20px;
    }

    color: #eff3fb;

    position: absolute;
    right: 50px;
    top: 30px;
    z-index: 0;
  }

  .navContainer {
    display: flex;
    flex-flow: column nowrap;
    min-width: 220px;
    position: relative;
    z-index: 2;

    margin-top: 10px;
    margin-right: 2px;
    margin-left: 80px;

    padding-bottom: 30px;

    @include respond-below(xl) {
      margin-top: 0px;
      margin-left: 62px;
    }

    .navArrowIcon {
      position: absolute;
      left: -60px;
      top: 50px;

      @include respond-below(xl) {
        display: none;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;

  svg {
    color: #647fb4;
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  $padding-left: 18px;
  $border-width: 2px;
  margin-left: -$nav-gutter;
  padding-left: $nav-gutter - $border-width;
  border-left: solid $border-width rgba(0, 0, 0, 0);
  padding-top: 8px;
  padding-bottom: 8px;

  a {
    color: #090b45;
    font-size: 1.4rem;
    opacity: 0.6;
  }
}
