.container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  .tableContainer {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    width: fit-content;
    cursor: pointer;

    .imagePlaceholder {
      height: 225px;
      min-width: 350px;
      background-size: cover;
      background-position: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      border-top: 1px solid rgba(0, 0, 0, 0.05);

      small {
        color: #647fb4;
      }
    }
  }
}
