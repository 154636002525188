@import '../../assets/styles/admin/variables.scss';

:global(.admin) {
  .header {
    position: sticky;
    z-index: 5;
    top: 0;
    &.notificationBarIsOut {
      top: 40px;
    }

    height: $fixed-header-height;
    margin: -1px 0 (50px - $nav-gutter);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: $background-color;

    font-size: 1.4rem;
    color: #14213d;

    box-shadow: 6px 0 0 0 $background-color, -6px 0 0 0 $background-color, 0 2px 6px 0 rgba(0, 0, 0, 0.12);

    &.useColumnFlex {
      height: 115px;
      flex-direction: column;
    }

    &.withSearchField {
      box-shadow: none;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 20px;
      justify-content: space-between;
      position: inherit;
      div {
        display: flex;
      }
    }

    &.tabs {
      box-shadow: none;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 20px;
      justify-content: center;
      position: inherit;
      div {
        display: flex;
      }
    }
  }
}
