.menu {
  background-color: white;
  width: 240px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 6px 0px;

  & > svg {
    margin: 0px 3px;
    filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.01));
  }
}
