@import '../../../../assets/styles/admin/variables.scss';

.sourceLink {
  position: absolute;
  right: 6rem;
  top: 2rem;
  font-size: 12px;
  color: $gray-blue;
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.icons8container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 2rem 2rem;
  background-color: #1f2258;

  &.addPadding {
    padding-right: 240px;
  }

  .content {
    border-radius: 2px;
    max-width: 230px;
    min-width: 230px;
    padding: 1rem;
    transition: all 0.1s;
    border: 2px transparent solid;

    &.selected {
      border: 2px solid $primary-color;
    }

    figure {
      margin: 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      overflow-x: hidden;
      img {
        height: 80px;
        width: inherit;
        background-color: #fff;
        padding: 10px;
        border-radius: 2px;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
      cursor: pointer;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      img {
      }
    }
  }
}

.spinner {
  margin: 2rem auto;
}
