@import '../../../../assets/styles/admin/variables.scss';
@import '../../../../assets/styles/admin/mixins.scss';

$padding: $toolbar-padding / 2;

:global(.admin) .BorderPicker {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .BorderWidthInput {
    display: flex;
    align-items: center;
    margin-left: 8px;

    .WidthIcon {
      height: 20px;
      width: 20px;
      margin-right: 6px;
    }
  }

  .BorderStyleInput {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 52px;
    margin-left: 8px;

    &:hover {
      background-color: rgba(128, 130, 179, 0.4);
      cursor: pointer;
    }

    .ArrowDown {
      height: 14px;
      transform: rotate(-90deg);
    }

    .Drawer {
      position: absolute;
      top: 100%;
      // left: 128px;
      right: 74px;
      background-color: rgba(57, 59, 113, 1);
      border-top: solid thin rgba(255, 255, 255, 0.14159);
      width: 135px;
      max-height: 135px;
      overflow-y: auto;
      padding-bottom: 4px;

      > ul {
        list-style: none !important;

        > li.BorderChoiceContainer {
          margin-left: unset !important;
          display: flex;
          justify-content: space-between;
          padding: 14px !important;
          cursor: pointer;
          align-items: center;
          height: 32px;

          span {
            margin-left: $padding;
            &.inSwatchLabel {
              text-transform: capitalize;
              font-size: $toolbar-font-size;
              text-overflow: ellipsis;
            }
          }

          background-color: $toolbar-tray-background !important;
          transition: background-color 0.14159s;
          &:hover {
            background-color: rgba(128, 130, 179, 0.25) !important;
          }

          .BorderChoice {
            display: flex;
            align-items: center;
          }
        }
      }

      &.enter {
        opacity: 0;
      }
      &.enterActive {
        opacity: 1;
        transition: opacity 0.14159s;
      }
      &.exit {
        opacity: 1;
      }
      &.exitActive {
        opacity: 0;
        transition: opacity 0.14159s;
      }
    }
  }
  .IsOpen {
    background-color: rgba(128, 130, 179, 0.4);
  }

  .BorderRadiusInput {
    display: flex;
    align-items: center;
    margin-left: 8px;

    .RadiusIcon {
      height: 20px;
      width: 20px;
      margin-right: 6px;
    }
  }
}
