@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

.imageUploader {
  cursor: pointer;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 8px 12px;
    background-color: #f8fafe;
    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border: 1px solid #d7dae2;
    border-radius: 4px;
    font-size: 1.5rem;
    box-sizing: border-box;
    transition: border 0.12s;

    span.imageViewer {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      height: 2rem;
      width: 3.5rem;
      border: 1px solid $purple;
      background-color: $gray-blue;
    }
    span.fileName {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  form {
    display: none;
  }
}

.radioGroup {
  margin-top: 1rem;

  label {
    margin: 0 !important;
    padding: 1.5rem;
    border: 1px solid $border-color;
    transition: background 0.14159s;
    cursor: pointer;

    &.selected {
      background: #f8fafe;
    }

    &:not(:first-child) {
      border-top: none;
    }

    input[type='radio'] {
      float: left;
      margin: 0.45rem 0 0 0;
    }

    input[type='text'] {
      background-color: #e9effc;
      margin-top: 1rem;
    }

    .title {
      margin: 0 0 0 2rem;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.5rem;
      display: grid;
    }
  }
}
form small.helpText {
  color: rgba(125, 143, 178, 1);
}
