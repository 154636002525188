.domainContainer {
  padding: 20px 33px;
  overflow-x: auto;
  & header {
    display: none;
  }

  fieldset {
    margin-bottom: 0px;

    > div:nth-child(2) {
      label {
        display: flex;
        align-items: center;

        > span {
          white-space: nowrap;
        }
      }
    }
  }

  form > div {
    margin-left: 18px;
    border-left: none !important;
  }
}
