.optionsContainer {
  /* Container */
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  isolation: isolate;

  width: 1280px;
  height: 254px;

  /* Border/Dark */

  border: 2px dashed #b5c0dc;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}
.miniFrame {
  /* Frame 16 */
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 10px;

  width: 308px;
  height: 70px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}
.labeledControl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 220px;
  height: 69px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.labelControl {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 220px;
  height: 69px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.label {
  /* Label */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 220px;
  height: 25px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.dropDown {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4px 0px 9px;
  gap: 8px;

  width: 220px;
  height: 40px;

  /* Palette / White Off */

  background: #f7f9fc;
  /* Border/Light */

  border: 1px solid #dee4f3;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.showButton {
  cursor: pointer;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 12px;

  width: 78px;
  height: 40px;

  /* Border/Light */

  border: 1px solid #dee4f3;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.showButtonText {
  width: 38px;
  height: 17px;

  /* Small/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Brand/Purple */

  color: #4040d9;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
