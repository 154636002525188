@import '../../assets/styles/admin/variables.scss';

.container {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 50px;
  width: 100%;
}

.viewAll {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 0;

  font-size: 1.25rem;
  font-weight: 500;
  color: $link-color;

  background-color: $light-background-color;
  // background: #EEFAFF;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.08);

  cursor: pointer;

  transition: opacity 0.14159s;
  &:hover {
    opacity: 0.7;
  }
}
