.withOverlay {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  a {
    height: 100%;
    width: 100%;

    background: rgba(14, 16, 61, 0.6);
    opacity: 0;
    &:hover {
      opacity: 1 !important;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    /* You can change your: */
    font-size: 14px;
    color: #ffffff !important;
    letter-spacing: 0;
    text-align: center;
    line-height: 27px;

    svg {
      height: 10px;
      width: 10px;
      margin-left: 0.5em;
    }
  }
}
