@import '../../assets/styles/admin/variables.scss';

.container {
  margin-top: 3rem;
  + .container {
    margin-top: 5rem;
  }
  text-align: center;

  h2 {
    font-size: 2.6rem;
    line-height: 1.3;
    font-weight: normal;
  }

  p {
    font-size: 1.6rem;
    color: $text-color-light;
    line-height: 1.5;
  }
}

.cardContainer {
  display: flex;
  margin: auto;
  max-width: 800px;
  margin-top: 4rem;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);

  .thumbnailContainer {
    &.polygon {
      background: url(/assets/images/signup/wiggle.svg), #005aed;
    }
    &.koali {
      background: url(/assets/images/signup/wiggle.svg), #ed958a;
    }
    background: url(/assets/images/signup/wiggle.svg), #cdb3ff;
    display: flex;
    padding: 4rem 0 3rem;
    border-radius: 6px 0 0 6px;
    width: 50%;
    align-items: center;
    justify-content: center;
    position: relative;

    .imageFrame {
      label {
        cursor: pointer;
        position: absolute;
        top: 45%;
        left: 34%;
        margin: 0;
        border-radius: 30px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
        color: #fff;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        padding: 0.6rem 1.2rem;
        background: rgba(9, 11, 69, 0.7);
        border: 1px solid rgba(9, 11, 69, 0.5);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
        letter-spacing: 0.1rem;
        opacity: 0;
        transition: all 0.14159s;
        transform: translateY(-10px);

        svg {
          margin: 0 8px 2px 0;
        }
      }

      img {
        max-width: 220px;
        border-radius: 10px;
        box-shadow: -8px 6px 20px rgba(0, 0, 0, 0.12);
        transition: all 0.14159s;
      }

      a {
        opacity: 1 !important;

        &:hover > * {
          opacity: 1;
          transform: scale(1.04);
          box-shadow: -8px 6px 30px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .aboutTheme {
    width: 50%;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      width: 100%;
      label {
        background-color: orange;
        color: white;
        padding: 2px 6px;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.08rem;
        border-radius: 2px;
        margin-left: 1rem;
        margin-bottom: 1rem;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      }
    }
    h2 {
      font-size: 2.6rem;
      font-weight: 500;
      color: $text-color;
      margin-bottom: 1rem;
      text-transform: capitalize;
    }
    p {
      font-size: 1.4rem;
      color: $text-color-light;
      line-height: 2.4rem;
      margin-bottom: 2rem;
      width: 100%;
    }
    .listContainer {
      display: block;
      width: 100%;
      .listItem {
        padding-bottom: 10px;
        span {
          margin-left: 1rem;
          font-size: 1.4rem;
          font-weight: 400;
          color: $text-color;
        }
        svg {
          color: $purple;
          height: 18px;
          width: 18px;
        }
      }
    }
    input {
      margin-top: 20px !important;
    }
  }
}
