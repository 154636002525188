@import '../../assets/styles/admin/variables.scss';

:global(.admin) {
  .header h1 {
    margin: 4rem 0;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
  }

  .templates {
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto 1rem;
    max-width: 1300px;
  }

  .templateContainer {
    flex: 0 0 (100% / 5);
    box-sizing: border-box;
    padding: 0 2rem 4rem;
    position: relative;

    .remove {
      position: absolute;
      right: 3px;
      padding: 3px 8px;
      border: 1px solid $blue;
      border-radius: 20px;
      background-color: #fff;
      top: -13px;
      cursor: pointer;
      display: none;

      svg {
        vertical-align: text-bottom;
        height: 17px;
        width: 17px;
        color: $blue;
      }
    }

    &:hover {
      .remove {
        display: inline;
      }
    }
  }

  .template {
    height: 353px;
    width: 202px;
    border-radius: 2px;
    text-align: center;

    &:not(.disabled) {
      cursor: pointer;
    }
  }

  .thumbnailContainer {
    position: relative;
    background: #fbfbfb;
    width: 202px;
    height: 312px;
    border-radius: 8px;
    overflow: hidden;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));
    transition: box-shadow 0.14159s, transform 0.2s ease-in-out;

    .staticThumbnail,
    .animatedThumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;

      &.generatingMessage {
        top: 34%;
        text-align: center;
        font-size: 2rem;
        color: #303438;
      }
    }

    .animatedThumbnail {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  .template:hover .animatedThumbnail {
    opacity: 1;
  }

  .template:hover .thumbnailContainer {
    transform: scale(1.25);
  }

  .blankPage {
    position: relative;
    text-align: center;
    background: #fbfbfb;
    width: 202px;
    height: 312px;
    border-radius: 8px;
    // overflow: hidden;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08));
    transition: transform 0.2s ease-in-out;

    .content {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
    }

    .border {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 170px;
      height: 280px;
      border-radius: 8px;
      border-style: dashed;
      border-width: 2px;
      border-color: rgba(0, 0, 0, 0.15);
    }
  }
  .template:hover .blankPage {
    transform: scale(1.25);
  }

  .caption {
    align-items: center;
    margin-top: 8px;
    height: 33px;
  }

  .name {
    font-weight: 400;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #14213dcc;
    opacity: 0.8;
    transition: opacity 0.14159s;
  }

  .template:hover .name {
    opacity: 0;
  }

  .upgradeTag {
    display: inline-block;
    margin-left: 1rem;
    padding: 0.2rem 0.6rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: $orange;
    vertical-align: 1px;
    border-radius: 2px;
  }

  .previewLink {
    color: rgba(0, 0, 0, 0.4);
    font-size: 1.2rem;

    svg {
      margin-left: 0.5em;
    }
  }

  .contactUs {
    margin-bottom: 5rem;
    font-size: 1.4rem;
    text-align: center;
  }

  .spinner {
    position: fixed;
    top: 33%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }
}
