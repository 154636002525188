.category-header {
  margin-top: 2rem;
  border-bottom: 2px solid #00162D;
}

.blog-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 4rem;
}
@media only screen and (max-width: 767px) {
  .blog-container #toc {
    display: none;
  }
}
.blog-container > aside.blog-aside-content-links {
  flex: 0 0 200px;
}
.blog-container > aside.blog-aside-content-links div.container {
  position: sticky;
  top: 3rem;
}
.blog-container > aside.blog-aside-content-links ul {
  list-style: none;
}
.blog-container > aside.blog-aside-content-links ul div {
  color: rgb(89, 114, 163);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 24px;
}
.blog-container > aside.blog-aside-content-links ul li {
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  list-style: none;
  display: flex;
}
.blog-container > aside.blog-aside-content-links ul li a {
  color: rgb(89, 114, 163);
}
.blog-container > aside.blog-aside-content-links ul li a:hover {
  text-decoration: underline;
}
.blog-container > aside.blog-aside-content-links ul li a.active {
  border-bottom: 1px dotted #000;
  text-decoration: none;
  font-style: italic;
}
.blog-container > aside.blog-aside-content-links ul.blog-social {
  display: flex;
}
.blog-container > aside.blog-aside-content-links ul.blog-social li {
  margin: 10px 5px 0 0;
}
.blog-container > aside.blog-aside-sidebar {
  flex: 0 0 100%;
  padding: 0 2rem 5rem;
  box-sizing: border-box;
}
.blog-container > aside.blog-aside-sidebar .container {
  max-width: 500px;
}
.blog-container > aside.blog-aside-sidebar .form-embed {
  width: 100%;
  margin: 0 auto 1rem 0;
}
.blog-container > aside.blog-aside-sidebar .form-embed .form-embed-placeholder, .blog-container > aside.blog-aside-sidebar .form-embed .form-embed-placeholder-with-content {
  width: 100%;
  display: block;
  padding: 0;
}
.blog-container > aside.blog-aside-sidebar .actions {
  margin-top: 2rem;
}
.blog-container > aside.blog-aside-sidebar .actions > * + * {
  margin-top: 1rem;
}
@media only screen and (min-width: 992px) {
  .blog-container {
    flex-wrap: nowrap;
  }
  .blog-container > aside.blog-aside-sidebar {
    flex: 0 0 320px;
    width: 320px;
    padding: 0 0 2rem 2rem;
  }
  .blog-container > aside.blog-aside-sidebar .container {
    position: sticky;
    top: 4rem;
  }
  .blog-container > aside.blog-aside-sidebar .button {
    width: 100%;
  }
}

.blog-content, .blog-listing {
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .blog-content {
    flex: 0 0 100%;
  }
}
.blog-content, .blog-listing {
  padding: 0 2rem;
  font-family: --blog-paragraph-font-family;
}
@media only screen and (min-width: 576px) {
  .blog-content, .blog-listing {
    padding: 0 2rem;
  }
}
.blog-content header h1, .blog-content header h2, .blog-listing header h1, .blog-listing header h2 {
  line-height: 1.4;
}
.blog-content article, .blog-listing article {
  font-size: 1.8rem;
  line-height: 1.7;
}
.blog-content article * + h1, .blog-content article * + h2, .blog-content article * + h3, .blog-content article * + h4, .blog-content article * + h5, .blog-content article * + h6, .blog-listing article * + h1, .blog-listing article * + h2, .blog-listing article * + h3, .blog-listing article * + h4, .blog-listing article * + h5, .blog-listing article * + h6 {
  line-height: 1.4;
}
.blog-content article hr, .blog-listing article hr {
  width: 50px;
  height: 2px;
  margin: 4rem auto;
  border: none;
  background: #DCDEE2;
  border-radius: 9999px;
}
.blog-content article img, .blog-listing article img {
  display: block;
  width: auto;
  max-width: 100%;
}
.blog-content article video, .blog-listing article video {
  display: block;
  width: 100%;
}
.blog-content article blockquote, .blog-listing article blockquote {
  border-left: solid 4px var(--color-primary);
  padding-left: 2rem;
}
.blog-content article ul, .blog-content article ol, .blog-listing article ul, .blog-listing article ol {
  padding-left: 2rem;
}
.blog-content article ul li, .blog-content article ol li, .blog-listing article ul li, .blog-listing article ol li {
  padding-left: 0rem;
}
.blog-content article ul, .blog-listing article ul {
  list-style: disc outside;
}
.blog-content article ol, .blog-listing article ol {
  list-style: decimal outside;
}
.blog-content .ql-youtube, .blog-listing .ql-youtube {
  width: 100%;
  padding: 0 0 56.25%;
  position: relative;
  background: rgba(0, 0, 0, 0.0666666667);
}
.blog-content .ql-youtube iframe, .blog-listing .ql-youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.blog-content .ql-twitter .twitter-tweet,
.blog-content .twitter-embed .twitter-tweet, .blog-listing .ql-twitter .twitter-tweet,
.blog-listing .twitter-embed .twitter-tweet {
  margin: auto;
}

.blog-authored_on {
  color: #9b9b9b;
  font-weight: 300;
}

p.tags {
  font-weight: bold;
}
p.tags span.tag {
  display: inline;
}
p.tags span.tag a {
  background: #eee;
  padding: 4px 10px;
  font-size: 1.3rem;
  color: #444;
  font-weight: bold;
}

.blog-content {
  margin: 0 auto 6rem;
  width: 100%;
  word-break: break-word;
}
.blog-content > header {
  box-sizing: border-box;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  text-align: center;
}
.blog-content > header .blog-subtitle {
  margin-top: 2rem;
  font-size: 1.5rem;
}
.blog-content > header .blog-subtitle * + * {
  margin-left: 1rem;
}
.blog-content > header .blog-subtitle .article-category {
  margin-left: 0;
}
.blog-content > article {
  box-sizing: border-box;
  width: 100%;
  max-width: 72rem;
  margin: 4rem auto !important;
  line-height: 1.58;
}
.blog-content > article * {
  margin: 0;
}
.blog-content > article * + * {
  margin-top: 2.5rem;
}
.blog-content > article * + figure {
  margin-top: 4rem;
}
.blog-content > article figure + * {
  margin-top: 5rem;
}
.blog-content > article figure {
  margin-bottom: 5rem;
}
.blog-content > article figure img {
  margin: auto;
}
.blog-content > article ul[data-checked] + ul[data-checked] {
  margin-top: 0;
}
.blog-content > article li + li {
  margin-top: 0;
}
.blog-content > footer {
  box-sizing: border-box;
  width: 100%;
  max-width: 72rem;
  margin: 0 auto;
}

.blog-grid {
  margin: 0 auto 5rem;
  width: 100%;
}
.blog-grid .blog-grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.blog-grid .blog-grid-snippet {
  flex-basis: 33.3333%;
  -ms-flex: auto;
  position: relative;
  padding: 25px;
  box-sizing: border-box;
}
.blog-grid .blog-grid-snippet article {
  height: 100%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog-grid .blog-grid-snippet article header {
  margin: 24px 0px;
  text-align: center;
  padding: 0 20px;
}
.blog-grid .blog-grid-snippet article header h2 {
  font-size: 2rem;
}
.blog-grid .blog-grid-snippet article header .category {
  text-align: center;
  margin: 0 auto;
  display: block;
  font-size: 1.4rem;
  text-transform: uppercase;
  padding: 10px;
  color: #bbbbbb;
}
.blog-grid .blog-grid-snippet article div.cover {
  height: 224px;
  overflow: hidden;
}
.blog-grid .blog-grid-snippet article div.cover picture img {
  width: 100% !important;
  height: auto;
}
.blog-grid .blog-grid-snippet article div.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.blog-grid .blog-grid-snippet article div.content footer {
  border-top: 1px solid #eee;
  padding: 16px 20px;
  font-size: 1.4rem;
  color: #bbbbbb;
}
.blog-grid .blog-grid-snippet article div.content footer .date {
  float: right;
}
@media (max-width: 1333px) {
  .blog-grid .blog-grid-snippet {
    flex-basis: 33.33%;
  }
}
@media (max-width: 1073px) {
  .blog-grid .blog-grid-snippet {
    flex-basis: 33.33%;
  }
}
@media (max-width: 815px) {
  .blog-grid .blog-grid-snippet {
    flex-basis: 50%;
  }
}
@media (max-width: 555px) {
  .blog-grid .blog-grid-snippet {
    flex-basis: 100%;
  }
  .blog-grid .blog-grid-snippet article div.cover {
    height: 224px;
    overflow: hidden;
  }
  .blog-grid .blog-grid-snippet article div.cover picture img {
    width: 100% !important;
    height: auto;
  }
}
.blog-grid .blog-grid-latest article {
  display: flex;
  padding: 25px;
  transition: opacity 0.14159s;
}
.blog-grid .blog-grid-latest article:hover {
  opacity: 0.75;
}
.blog-grid .blog-grid-latest article .category,
.blog-grid .blog-grid-latest article footer {
  display: none;
}
.blog-grid .blog-grid-latest article div.image picture {
  display: block;
  position: relative;
  padding: 56.25% 0 0 0 !important;
  box-sizing: border-box;
}
.blog-grid .blog-grid-latest article div.image picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  margin: 0;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 991px) {
  .blog-grid .blog-grid-latest article {
    display: block;
    margin: 25px;
    padding: 0;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .blog-grid .blog-grid-latest article .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
  .blog-grid .blog-grid-latest article .content header {
    margin: 24px 0px;
    text-align: center;
    padding: 0 20px;
  }
  .blog-grid .blog-grid-latest article .content header .category {
    text-align: center;
    margin: 0 auto;
    display: block;
    font-size: 1.4rem;
    text-transform: uppercase;
    padding: 10px;
    color: #bbbbbb;
  }
  .blog-grid .blog-grid-latest article .content p,
  .blog-grid .blog-grid-latest article .content span.blog-listing-snippet-continue-reading {
    display: none;
  }
  .blog-grid .blog-grid-latest article .content footer {
    display: block;
    border-top: 1px solid #eee;
    padding: 16px 20px;
    font-size: 1.4rem;
    color: #bbbbbb;
  }
  .blog-grid .blog-grid-latest article div.image picture img {
    border-radius: 0;
    box-shadow: none;
  }
}
@media only screen and (min-width: 992px) {
  .blog-grid .blog-grid-latest article div.image {
    flex: 2 1;
    margin-right: 2rem;
  }
  .blog-grid .blog-grid-latest article div.content {
    flex: 1 1;
  }
}

.blog-listing {
  box-sizing: border-box;
  width: 100%;
  max-width: 72rem;
  margin: 0 auto 5rem;
}
.blog-listing .author-details h1, .blog-listing .author-details p {
  margin-bottom: 0;
}
.blog-listing .author-details p {
  font-size: 2rem;
  line-height: 19px;
}
.blog-listing .author-details a {
  font-size: 1.3rem;
}
.blog-listing .author-details img {
  border-radius: 50%;
}
.blog-listing .blog-listing-snippet {
  display: block;
  color: unset;
  padding-bottom: 4rem;
}
.blog-listing .blog-listing-snippet + .blog-listing-snippet {
  padding-top: 4rem;
  border-top: solid thin #eee;
}
.blog-listing .blog-listing-snippet > header, .blog-listing .blog-listing-snippet header * {
  margin: 0;
}
.blog-listing .blog-listing-snippet > header a, .blog-listing .blog-listing-snippet header * a {
  display: block;
  color: inherit;
}
.blog-listing .blog-listing-snippet > header a *, .blog-listing .blog-listing-snippet header * a * {
  cursor: pointer;
}
.blog-listing .blog-listing-snippet > article {
  margin-top: 3rem;
}
.blog-listing .blog-listing-snippet > article * {
  margin: 0;
}
.blog-listing .blog-listing-snippet > article * + * {
  margin-top: 2rem;
}
.blog-listing .blog-listing-snippet > article .responsive-media img {
  margin-top: 0;
}
.blog-listing .blog-listing-snippet-continue-reading {
  display: block;
  margin-top: 2rem;
}
.blog-listing img {
  max-width: 100%;
}
.blog-listing footer {
  margin-top: 3rem;
}

.blog-social a {
  display: block;
}
.blog-social img {
  display: block;
  height: 32px;
  width: 32px;
}

aside.blog-content-aside {
  position: fixed;
  top: 8rem;
  margin-left: -10rem;
  transition: opacity 0.14159s;
}
@media only screen and (max-width: 991px) {
  aside.blog-content-aside {
    display: none;
  }
}
aside.blog-content-aside ul.blog-social {
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
  list-style: none;
}
aside.blog-content-aside ul.blog-social img {
  height: 26px;
  width: 26px;
}
aside.blog-content-aside ul.blog-social li + li {
  margin-top: 1rem;
}
aside.blog-content-aside.hide {
  opacity: 0;
  pointer-events: none;
}

.blog-content > footer .blog-social {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  list-style: none;
}
.blog-content > footer .blog-social li + li {
  margin-left: 1.5rem;
}

.category-text {
  border-bottom: 2px solid;
  margin-bottom: 2rem;
  text-align: center;
}

.blog-recommended-posts {
  padding: 6rem 0;
  background: #f8fafe;
}
.blog-recommended-posts .container {
  max-width: 1000px;
}
.blog-recommended-posts ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  list-style: none;
}
.blog-recommended-posts ul li {
  flex: 0 0 100%;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 576px) {
  .blog-recommended-posts ul li {
    flex: 0 0 50%;
  }
}
@media only screen and (min-width: 992px) {
  .blog-recommended-posts ul {
    margin: 0 -1rem;
  }
  .blog-recommended-posts ul li {
    flex: 0 0 33.3333333333%;
  }
}
.blog-recommended-posts li a {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 1rem;
  color: inherit;
  transition: opacity 0.14159s;
  height: 100%;
  overflow: hidden;
}
.blog-recommended-posts li a:hover {
  opacity: 0.75;
}
.blog-recommended-posts li .card-image {
  flex: 0 0 auto;
  height: 100px;
  background-color: #b9e6fa;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2362b6db' fill-opacity='0.3'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.blog-recommended-posts li .card-title {
  flex: 1 0 auto;
  margin-top: 1.5rem;
  padding: 0 1.5rem;
  font-weight: 700;
  font-size: 2rem;
}
.blog-recommended-posts li .card-footer {
  margin: 1.5rem 0;
  padding: 0 1.5rem;
  display: flex;
}
.blog-recommended-posts li .card-author {
  font-size: 1.5rem;
}
.blog-recommended-posts li .card-date {
  margin-left: auto;
  font-size: 1.5rem;
}

.blog-listing, .blog-content {
  /* Blog headers
  ************************************/
}
.blog-listing p, .blog-content p {
  font-family: var(--blog-paragraph-font-family);
  font-size: var(--blog-paragraph-font-size);
  font-weight: var(--blog-paragraph-font-weight);
  color: var(--blog-paragraph-color);
  line-height: var(--blog-paragraph-line-height);
  margin: var(--blog-paragraph-margin);
}
.blog-listing article > aside.blog-content-aside, .blog-content article > aside.blog-content-aside {
  padding-top: var(--header-padding);
}
.blog-listing > header .blog-subtitle, .blog-content > header .blog-subtitle {
  font-family: var(--blog-paragraph-font-family);
}
.blog-listing > article pre, .blog-content > article pre {
  font-family: monospace;
  font-size: 0.8em;
}
.blog-listing h1, .blog-content h1 {
  color: var(--blog-h1-color);
  font: var(--blog-h1-font-style) var(--blog-h1-font-variant) var(--blog-h1-font-weight) var(--blog-h1-font-size)/var(--blog-h1-line-height) var(--blog-h1-font-family);
  text-transform: var(--blog-h1-text-transform);
  letter-spacing: var(--blog-h1-letter-spacing);
  margin: var(--blog-h1-margin);
}
.blog-listing h2, .blog-content h2 {
  color: var(--blog-h2-color);
  font: var(--blog-h2-font-style) var(--blog-h2-font-variant) var(--blog-h2-font-weight) var(--blog-h2-font-size)/var(--blog-h2-line-height) var(--blog-h2-font-family);
  text-transform: var(--blog-h2-text-transform);
  letter-spacing: var(--blog-h2-letter-spacing);
  margin: var(--blog-h2-margin);
}
.blog-listing h3, .blog-content h3 {
  color: var(--blog-h3-color);
  font: var(--blog-h3-font-style) var(--blog-h3-font-variant) var(--blog-h3-font-weight) var(--blog-h3-font-size)/var(--blog-h3-line-height) var(--blog-h3-font-family);
  text-transform: var(--blog-h3-text-transform);
  letter-spacing: var(--blog-h3-letter-spacing);
  margin: var(--blog-h3-margin);
}
.blog-listing h4, .blog-content h4 {
  color: var(--blog-h4-color);
  font: var(--blog-h4-font-style) var(--blog-h4-font-variant) var(--blog-h4-font-weight) var(--blog-h4-font-size)/var(--blog-h4-line-height) var(--blog-h4-font-family);
  text-transform: var(--blog-h4-text-transform);
  letter-spacing: var(--blog-h4-letter-spacing);
  margin: var(--blog-h4-margin);
}
.blog-listing h5, .blog-content h5 {
  color: var(--blog-h5-color);
  font: var(--blog-h5-font-style) var(--blog-h5-font-variant) var(--blog-h5-font-weight) var(--blog-h5-font-size)/var(--blog-h5-line-height) var(--blog-h5-font-family);
  text-transform: var(--blog-h5-text-transform);
  letter-spacing: var(--blog-h5-letter-spacing);
  margin: var(--blog-h5-margin);
}
.blog-listing h6, .blog-content h6 {
  color: var(--blog-h6-color);
  font: var(--blog-h6-font-style) var(--blog-h6-font-variant) var(--blog-h6-font-weight) var(--blog-h6-font-size)/var(--blog-h6-line-height) var(--blog-h6-font-family);
  text-transform: var(--blog-h6-text-transform);
  letter-spacing: var(--blog-h6-letter-spacing);
  margin: var(--blog-h6-margin);
}

.video-embed {
  width: 100%;
  padding: 0 0 56.25%;
  position: relative;
  background: rgba(0, 0, 0, 0.0666666667);
  box-sizing: border-box;
}
.video-embed > * {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.video-embed iframe {
  border: none;
}
.video-embed .video-embed-overlay {
  cursor: pointer;
}

.podcast-embed {
  width: 100%;
  height: 200px;
  position: relative;
  box-sizing: border-box;
}
.podcast-embed > * {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.podcast-embed iframe {
  border: none;
}
.podcast-embed .podcast-embed-overlay {
  cursor: pointer;
}

.form-embed {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
}
.form-embed .form-embed-placeholder, .form-embed .form-embed-placeholder-with-content {
  height: 150px;
  color: var(--button-action-link-text-color);
  border: dashed 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-embed .form-embed-loading {
  margin: 5rem auto;
}
.form-embed .form-embed-placeholder-with-content {
  color: var(--form-field-text-color);
}

.form-embed-card {
  background-color: #fff;
  box-shadow: 4px 6px 30px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 30px;
}
.form-embed-card .form-embed-placeholder, .form-embed-card .form-embed .form-embed-placeholder-with-content, .form-embed .form-embed-card .form-embed-placeholder-with-content {
  border: none;
  border-radius: 0;
  padding: 0;
}

.text-center .form-embed {
  margin-left: auto;
  margin-right: auto;
}

.ql-editor .form-embed-placeholder, .ql-editor .form-embed .form-embed-placeholder-with-content, .form-embed .ql-editor .form-embed-placeholder-with-content {
  white-space: normal;
  position: relative;
  user-select: none;
}

.blog-content > article .form-embed * + * {
  margin-top: 0;
}
.blog-content > article .form-embed fieldset {
  margin-bottom: 1.5rem;
}
.blog-content > article .form-embed small.recaptcha {
  margin-top: 1rem;
}

/*# sourceMappingURL=blog.css.map */
