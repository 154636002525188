@import '../assets/styles/admin/variables.scss';
@import '../assets/styles/admin/mixins.scss';

.cover {
  position: fixed;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background: rgba(20, 33, 61, 0.8);
  opacity: 0.7;
}
.container {
  position: fixed;
  z-index: 1001;
  width: 978px;
  height: 684px;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 48px 48px 32px;
  gap: 24px;
  isolation: isolate;
  width: inherit;
  height: 196px;
  border-bottom: 1px solid #dee4f3;

  span {
    height: 44px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #14213d;
    z-index: 1;
  }
  .iconButton {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 24px;
    top: 24px;
    border-radius: 8px;
    cursor: pointer;
    z-index: 2;
    .icon {
      position: absolute;
      width: 20px;
      height: 20px;
      left: calc(50% - 20px / 2);
      top: calc(50% - 20px / 2);
    }
    &:active {
      background: #f7f9fc;
      border-radius: 8px;
    }
    &:hover {
      background: #f7f9fc;
      border-radius: 8px;
    }
    &:focus {
      border: 2px solid #4040d9;
      border-radius: 8px;
    }
  }
}
.columns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 8px;
  width: inherit;
  height: 342px;
  background: #ffffff;
  border-bottom: 1px solid #dee4f3;
  .step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 16px;
    width: 320px;
    height: 302px;
    .head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 240px;
      height: 122px;
      .title {
        width: 240px;
        height: 58px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #14213d;
      }
    }
    .description {
      width: 240px;
      height: 100px;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(20, 33, 61, 0.8);

      b {
        color: black;
      }
    }
    &:nth-child(-n + 2) {
      border-right: 1px solid #dee4f3;
      height: 342px;
    }
  }
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px;
  gap: 16px;
  width: 978px;
  height: 144px;
  .checkboxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    gap: 8px;
    width: 206px;
    height: 40px;
    cursor: pointer;
    .checkbox {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      background: #dee4f3;
      border: 2px solid #dee4f3;
      border-radius: 4px;
      cursor: pointer;
      padding: 0;
      appearance: auto;
    }
    .checkboxLabel {
      width: 162px;
      height: 25px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #475266;
      cursor: pointer;
    }
  }
  .button {
    padding: 0px 32px;
    height: 48px;
    background: #4040d9;
    border: 1px solid rgba(0, 0, 0, 0.2);

    border-radius: 4px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    &:hover {
      background: #292bcf;
    }

    &:focus {
      background: #292bcf;
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }

    &:active {
      background: #0202a1;
    }
  }
}
