// ----------------------------------------------------------------------------
// Overrides for embedded HubSpot forms
// ----------------------------------------------------------------------------

.hs-form {
  margin-bottom: -2rem !important;
}

.hs-form label {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}

.hs-form input {
  width: 100%;
}

.hs-form-required {
  display: none !important;
}

.hs-input {
  width: 100% !important;
}

.hs-error-msgs {
  list-style: none !important;
  margin: -1.5rem 0 2rem !important;
}

.hs-error-msgs label {
  display: block;
  color: #d56ca5 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: 'Roboto', sans-serif !important;
}

.hs-button.primary {
  text-transform: none;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.02rem;
  border-radius: 2px !important;
  border: none !important;
  color: $primary-color;
  padding: 0.2rem 0 0.3rem;
  margin-top: 1.5rem;
  height: inherit;
  color: #fff;
  background-color: $primary-color;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.hs-button.primary:hover,
.hs-button.primary:focus {
  filter: brightness(105%);
  color: #fff;
}
