@import '../../../../assets/styles/admin/variables.scss';

.templateBlock {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: rgba(20, 33, 61, 0.8);

  a {
    margin-left: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;

    text-decoration-line: underline;

    color: #1495cd;
  }
}

.contentContainer {
  justify-content: flex-start;
  gap: 24px;
  margin-top: 16px;

  & > div {
    margin-left: 0px;
  }
}

.selectFileTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;

  color: #14213d;
}

.fileContainer {
  display: flex;
  align-items: center;

  height: 56px;
  background: #eff3fb;
  border-radius: 8px;

  & span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: rgba(20, 33, 61, 0.8);
  }
}

.uploadButton {
  background-color: #8499c2 !important;
  color: white !important;
  border: 1px solid #7486ab !important;
  box-sizing: border-box;
  border-radius: 4px;

  height: 32px !important;
  margin: 0px 16px 0px 12px !important;

  line-height: 3rem !important;
  padding: 0 1.5rem !important;
}

.warningIcon {
  height: 32px;
  width: 32px;
  color: #e8833a;
}

.warningQuestion {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #14213d;
}

.warningInfo {
  margin-top: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(20, 33, 61, 0.8);
}

.warningContainer {
  background-color: rgba(255, 178, 102, 0.08);
  border: 1px solid #ffb266;
  border-radius: 4px;
  height: 80px;
  margin-top: 8px;

  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 16px;
  font-size: 1.5rem;
  margin-left: 0px;
}

.actionButtonContainer {
  a {
    margin-bottom: 0px !important;
  }

  a:last-child {
    margin-left: 16px;
    margin-right: 0px;
  }
}

.confirmButton {
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal {
  padding: 2rem 0;
  width: 640px;

  h3 {
    border-bottom: 1px solid #dce4f4;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 504px;

    & div:last-child {
      margin-left: auto;

      & button:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.uploadIcon {
  background-color: #dce4f2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  border-radius: 100%;

  svg {
    width: 32px;
    height: 32px;
  }
}

.root {
  display: flex;
  gap: 26px;
  margin: 0 26px;
}
