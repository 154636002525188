@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

$header-height: 5rem;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  // background: rgba(41, 50, 65, 0.25);
  //
  // .enter & {
  //   opacity: 0;
  // }
  //
  // .enterActive & {
  //   transition: opacity 0.2s;
  //   opacity: 1;
  // }
  //
  // .exit & {
  //   opacity: 1;
  // }
  //
  // .exitActive & {
  //   transition: opacity 0.1s;
  //   opacity: 0;
  // }
}

.BottomDrawer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  &.isAdminPanel {
    left: 240px;
  }

  &.notificationBarActive {
    top: 40px;
  }

  .enter & {
    transform: translateY(100%);
  }

  .enterActive & {
    transition: transform 0.2s;
    transform: translateY(0);
  }

  .exit & {
    transform: translateY(0);
  }

  .exitActive & {
    transform: translateY(100%);
    transition: transform 0.1s;
  }

  &.verticalAligned {
    height: 100%;
    width: 360px;
    top: 45px;

    .enter & {
      transform: translateX(-100%);
    }

    .enterActive & {
      transition: transform 0.2s;
      transform: translateX(0);
    }

    .exit & {
      transform: translateX(0);
    }

    .exitActive & {
      transform: translateX(-100%);
      transition: transform 0.1s;
    }
  }
}

.header {
  height: $header-height;
  padding: 0 2rem;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  background-color: #090b45;

  .title {
    font-weight: 500;
    font-size: 1.6rem;
    color: #fff;
    letter-spacing: 0;
  }
  &.isAdminPanel {
    box-shadow: none;
  }
  &.verticalAligned {
    box-shadow: none;
    .title {
      font-size: 1.2rem;
    }
  }

  .closeButton {
    @include unstyled-button;
    margin: 0;
    margin-left: auto;
    opacity: 0.5;
    color: #fff;
    transition: opacity 0.14159s;

    &:hover {
      opacity: 0.8;
    }

    svg {
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  &.extendBody {
    height: 6rem;
  }
}

.body {
  background-color: #1f2258;
  color: #fff;
  overflow-x: auto;
  height: 100%;

  &.padding {
    > div {
      width: fit-content;
      padding: 2rem;

      &.verticalAligned {
        width: auto;
      }
    }
  }

  &.extendBody {
    height: 400px; // falback if needed
    height: calc(100vh - 56px);

    &.padding {
      > div {
        width: 100%;
        padding: 0;
      }
    }
  }
  &.notificationBarActive {
    height: calc(100vh - 96px);
  }
}

.moreToTheRight {
  position: absolute;
  top: $header-height + 3rem;
  right: 0;
  height: 10rem;
  width: auto !important;
  margin: 0 !important;
  pointer-events: none;

  opacity: 0;
  transition: opacity 0.2s;

  &.isVisible {
    opacity: 1;
  }
}

.moreToTheLeft {
  @extend .moreToTheRight;
  right: auto;
  left: 0;
  transform: rotateY(180deg);
}
