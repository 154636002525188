@import '../assets/styles/admin/variables.scss';

.tooltip {
  &:global(.type-dark) {
    background-color: $dark-background-color !important;
    border-radius: 2px;
    font-size: 11px;
    padding: 2px 6px;
    transition: opacity 0.14159s;
    z-index: 9999;

    &:global(.place-left) {
      margin-top: -2px;

      &:after {
        border-left-color: #222;
        border-left-style: solid;
        border-left-width: 4px;

        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        right: -4px;
        top: 50%;
        margin-top: -5px;
      }
    }

    &:global(.place-top) {
      margin-top: -5px;

      &:before {
        background-color: transparent;
      }

      &:after {
        background-color: $dark-background-color !important;
        border-top-color: #222;
        border-top-style: solid;
        border-top-width: 4px;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        bottom: -4px;
        left: 50%;
        margin-left: -8px;
      }
    }
  }
}

.customTheme {
  color: #ff6e00 !important;
  background-color: orange !important;

  &.place-top {
    &:after {
      border-top-color: orange !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
}
