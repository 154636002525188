@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

.integrationContent {
  color: rgba(20, 33, 61, 0.8);
  width: 100%;
  padding: 20px 48px;

  > p {
    font-size: 14px;
  }

  & a {
    cursor: pointer;
    font-size: 14px;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.integrationContainer {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin: 12px 0;
  @include respond-below(xl) {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond-below(lg) {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

.integration {
  height: 110px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  padding: 21px;
  border: 1px solid #dee4f3;
  position: relative;

  &.completedIntegration {
    border: 3px solid #2ec5b6;
  }

  .incompleteCheckIcon {
    display: none;
  }

  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid #dee4f3;
    }

    .incompleteCheckIcon {
      display: block;
    }
  }

  > img,
  svg {
    height: 80%;
    width: auto;
    display: flex;
    margin: 0 auto;
  }

  > p {
    text-align: center;
    font-size: 14px;
    margin-right: -10px;
    margin-left: -10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkIconContainer {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 4px;
    right: 4px;
  }
  &:hover .upgradeTooltip {
    display: block;
  }

  .upgradeTooltip {
    display: none;
    position: absolute;
    top: 48px;
    left: 12px;
    padding: 24px;
    color: black;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 249px;
    height: auto;
    background-color: white;
    font-size: 14px;
    color: rgba(20, 33, 61, 0.8);
    font-weight: normal;
    text-align: left;
    z-index: 2;

    > p {
      color: $purple;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: 500;

      > svg {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}
.upgradeBanner {
  width: 57px;
  height: 20px;
  padding: 2px 6px;
  position: absolute;
  text-align: center;
  border-radius: 2px;
  top: 24px;
  left: -8px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  background-color: $purple;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 1;

  > span {
    text-transform: uppercase;
  }
}
