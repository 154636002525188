.UpDownButtons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  svg {
    height: 14px;
  }
}

.up,
.down {
  padding: 0 !important;

  &:focus,
  &:active,
  &:visited {
    &:not(:hover) {
      opacity: 1 !important;
    }
  }
}

.down {
  margin-top: -4px !important;
}

.up svg {
  transform: rotate(90deg);
}

.down svg {
  transform: rotate(-90deg);
}
