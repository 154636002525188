@import '../assets/styles/admin/variables.scss';

.SiteList {
  padding: 0;

  background-color: #fff;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 3px;

  overflow: hidden;
  list-style: none;

  font-size: 1.5rem;
  font-weight: 400;

  li {
    min-height: 36px;

    a {
      display: flex;
      align-items: center;
      padding: 7px 40px;
      color: inherit;
      text-decoration: none;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      transition: background-color 0.14159s;

      &:hover {
        background-color: #eff3fb;
        opacity: 1 !important;
      }

      &.addSiteLink {
        color: #4040d9 !important;
        padding: 7px 40px !important;
      }

      &.active {
        padding-left: 18px;
        font-weight: 600;
      }
    }
  }

  .addSiteLinkDivider {
    margin: 8px;
    height: 1px;
    background-color: #dee4f3;
  }

  .faviconContainer {
    display: inline-block;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    flex-shrink: 0;
    margin: 0 0.7em 0 0;
  }

  .favicon {
    height: 100%;
    width: 100%;
    object-fit: contain;
    vertical-align: middle;
  }

  .checkmarkIcon {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    margin-right: 8px;
  }
}
