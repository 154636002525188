@import '../../assets/styles/admin/variables.scss';

$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$expandSize: 7px;

@mixin input-select-style() {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }

  .rt-table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
  }

  .rt-thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.-headerGroups {
      background: rgba(black, 0.03);
      border-bottom: 1px solid rgba(black, 0.05);
    }

    &.-filters {
      border-bottom: 1px solid rgba(black, 0.05);

      input,
      select {
        @include input-select-style();
      }

      .rt-th {
        border-right: 1px solid rgba(black, 0.02);
      }
    }

    &.-header {
      border-bottom: 1px solid $table-head-border-color;
    }

    .rt-tr {
      // text-align: center;
    }

    .rt-td,
    .rt-th {
      position: relative;
      display: flex;

      color: $table-head-text-color;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;

      border-left: 1px solid $table-head-border-color;
      background-color: $table-head-background-color;

      > * {
        flex: 1;
      }

      &.-sortable:after {
        content: url(../../assets/images/icon-sort-none.svg);
        flex: 0;
      }

      &.-sort-asc:after {
        content: url(../../assets/images/icon-sort-ascending.svg);
      }

      &.-sort-desc:after {
        content: url(../../assets/images/icon-sort-descending.svg);
      }

      &.-cursor-pointer {
        cursor: pointer;
      }

      &:first-child {
        border-left: none;
      }
    }

    .rt-th:focus {
      outline: none;
    }

    .rt-resizable-header {
      overflow: visible;

      &:last-child {
        overflow: hidden;
      }
    }

    .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rt-header-pivot {
      border-right-color: #f7f7f7;
    }

    .rt-header-pivot:after,
    .rt-header-pivot:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .rt-header-pivot:after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #fff;
      border-width: 8px;
      margin-top: -8px;
    }

    .rt-header-pivot:before {
      border-color: rgba(102, 102, 102, 0);
      border-left-color: #f7f7f7;
      border-width: 10px;
      margin-top: -10px;
    }
  }

  .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .rt-tr-group {
      border-bottom: solid 1px $table-body-border-color;

      &:last-child {
        border-bottom: none;
      }
    }

    .rt-td {
      color: $table-body-text-color;
      font-size: 1.4rem;
      border-left: 1px solid $table-body-border-color;

      &:first-child {
        border-left: none;
      }

      &.-highlight {
        background-color: $table-highlight-color;
      }
    }

    .rt-expandable {
      cursor: pointer;
      text-overflow: clip;
    }
  }

  .rt-tr-group {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .rt-tr {
    flex: 1 0 auto;
    display: inline-flex;
    transition: 0.14159s ease;
    transition-property: background-color;
  }

  .rt-td,
  .rt-th {
    flex: 1 0 0;
    padding: 1.2rem 1rem;
    transition: 0.14159s ease;
    transition-property: width, min-width, padding, opacity, background-color;

    &.-muted {
      color: $text-color-light;
    }

    &.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }

    &.-wrap {
      word-break: break-all;
    }

    &:not(.-wrap) {
      &,
      * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .rt-expander {
    display: inline-block;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      border-left: $expandSize * 0.72 solid transparent;
      border-right: $expandSize * 0.72 solid transparent;
      border-top: $expandSize solid rgba(black, 0.8);
      transition: all 0.3s $easeOutBack;
      cursor: pointer;
    }

    &.-open:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
  }

  .rt-tfoot {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(black, 0.15);

    .rt-td {
      border-left: 1px solid $table-body-border-color;

      &:first-child {
        border-left: none;
      }
    }
  }

  &.-striped {
    .rt-tr.-odd {
      background: rgba(black, 0.03);
    }
  }

  &.-highlight {
    .rt-tbody {
      .rt-tr:not(.-padRow):hover {
        background: $table-highlight-color;
      }
    }
  }

  .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(black, 0.1);
    border-top: 2px solid rgba(black, 0.1);

    input,
    select {
      @include input-select-style();
    }

    .-btn {
      appearance: none;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(black, 0.6);
      background: rgba(black, 0.1);
      transition: all 0.1s ease;
      cursor: pointer;
      outline: none;

      &[disabled] {
        opacity: 0.5;
        cursor: default;
      }

      &:not([disabled]):hover {
        background: rgba(black, 0.3);
        color: white;
      }
    }

    .-next,
    .-previous {
      flex: 1;
      text-align: center;
    }

    .-center {
      flex: 1.5;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }

    .-pageInfo {
      display: inline-block;
      margin: 3px 10px;
      white-space: nowrap;
    }

    .-pageJump {
      display: inline-block;

      input {
        width: 70px;
        text-align: center;
      }
    }

    .-pageSizeOptions {
      margin: 3px 10px;
    }
  }

  .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(white, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(black, 0.5);
  }

  .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(white, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    > div {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(black, 0.6);
      transform: translateY(-52%);
      transition: all 0.3s $easeOutQuad;
    }

    &.-active {
      opacity: 1;
      z-index: 2;
      pointer-events: all;

      > div {
        transform: translateY(50%);
      }
    }
  }

  .rt-resizing {
    .rt-td,
    .rt-th {
      transition: none !important;
      cursor: col-resize;
      user-select: none;
    }
  }
}
