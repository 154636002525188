@import '../../../assets/styles/admin/variables.scss';

// Page tag

// Intended to look like a text input that holds the tag
.pageTagContainer {
  background: rgba(0, 0, 0, 0.03);
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.pageTag {
  background: $primary-color;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 1px 5px;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
}

.pageTagIcon {
  height: 10px;
  width: 10px;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  * {
    stroke: #fff;
    fill: #fff;
  }
}

.pageTagName {
  margin: 0 8px 0 4px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 40ch;
}

a.pageTagClose {
  margin: 0px -2px 0 -2px;
  padding: 2px;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  transition: color 0.14159s;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }

  svg {
    vertical-align: middle;
    height: 8px;
    width: 8px;
  }
}

// Autosuggest styling

.container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.suggestionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  background: #f0f2f6;
  border-radius: 0 0 2px 2px;
  overflow: scroll;
  max-height: 200px;
  opacity: 0;
  transition: opacity 0.14159s;
}

.suggestionsContainerOpen {
  opacity: 1;
}

.suggestionsList {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.suggestion {
  display: table-row;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.14159s;
  cursor: pointer;
}

.suggestionHighlighted {
  // background-color: rgba(0,0,0,0.05);
  background-color: #f8f9fb;
}

.suggestion + .suggestion > * {
  border-top: solid thin rgba(0, 0, 0, 0.05);
}

.suggestionIconContainer {
  padding: 10px 5px 10px 10px;
  width: 1%;
}

.suggestionIcon {
  display: block;
  height: 14px;
  width: 14px;
  color: $text-color;
}

.suggestion > * {
  display: table-cell;
  font-size: 1.2rem;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestionName {
  padding: 10px 5px 10px 5px;
  color: $text-color;
  width: 30%;
  max-width: 120px;
}

.suggestionSlug {
  padding: 10px 10px 10px 5px;
  color: $text-color;
  max-width: 100px;
}

.input {
  width: 100%;
  // margin: 0 !important;
}

.inputIcon {
  height: 14px;
  width: 14px;
  color: $text-color;
  margin-right: 5px;
}

.browserTab {
  margin: 0 6px 0 10px;
  transition: opacity 0.14159s, color 0.14159s;
  color: $text-color;

  svg {
    height: 15px;
    width: 15px;
  }
}
