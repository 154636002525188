@import 'assets/styles/admin/variables';

.editingName {
  display: flex;
  align-items: center;
  input {
    background-color: #fff !important;
    border-color: $primary-color !important;
    margin-right: 9px;
    font-size: 18px !important;
    font-weight: 500;
  }
  span {
    color: $dark-gray-blue;
    cursor: pointer;
    margin-right: 9px;
    font-size: 1.5rem;

    &.disabled {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }
}

.nameField {
  display: flex;
  align-items: center;
  cursor: pointer;
  & > * {
    margin-right: 9px;
  }

  span {
    font-size: 18px;
    font-weight: 500;
  }
  &:hover {
    color: $primary-color;
  }
}
.errorBorder {
  border-color: black !important;
}
