@import './reset';

.admin,
.sriracha .admin,
.sriracha .admin .sriracha .admin {
  @import './variables';
  @import './mixins';
  @import './utilities';
  @import './base';
}
