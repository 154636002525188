@import '../../../assets/styles/admin/variables.scss';

.addItemMenu {
  position: absolute;
  z-index: 1;
  width: fit-content;
}

.toggleButton {
  color: rgba(0, 0, 0, 0.2);
  .darkBg & {
    color: rgba(255, 255, 255, 0.5);
  }
  cursor: pointer;

  position: absolute;
  left: -3rem;
  // top: 1.2rem;

  transition: color 0.14159s;
  &:hover {
    color: $primary-color;
  }
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;

  svg {
    display: block;
  }

  .enter & {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  .enterActive & {
    transform: translate(-50%, -50%) rotate(45deg);
    transition: transform 300ms;
  }
  .enterDone &,
  .exit & {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .exitActive & {
    transform: translate(-50%, -50%) rotate(0deg);
    transition: transform 300ms;
  }
}

.itemButtons {
  display: flex;
  position: relative;
  transform: translateY(-50%);

  .enter & {
    opacity: 0;
  }
  .enterActive & {
    opacity: 1;
    transition: opacity 0.3s;
  }
  .exitActive & {
    display: none;
  }

  button {
    /* component-specfic styles */
    display: block;
    margin: 0 1rem 0 0;
    padding: 0;
    background: transparent;

    /* Standard button overrides */
    height: auto;
    border: none;
    box-shadow: none;

    /* Overrides for Sriracha.css */
    text-transform: none;
    line-height: normal;

    transition: color 0.14159s;

    color: $dark-gray-blue-faded;

    &:hover {
      opacity: 1;
      color: #6f7fe2;
    }
  }

  button svg {
    transition: all 0.14159s ease;
    display: block;
    height: 30px;
    width: 30px;
  }

  // button:not([disabled]):hover svg {
  //   transform: scale(1.05);
  // }

  button[disabled] {
    cursor: default;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }

  button[disabled] img {
    filter: grayscale(1);
  }

  // Enter transition

  // @for $i from 1 through 6 {
  //   button:nth-child(#{$i}) { transition-delay: #{$i/24}s }
  // }
  //
  // .isOpening & button {
  //   transform: translateY(20px);
  //   opacity: 0;
  // }
}

.videoInput {
  border: none;
  border-bottom: 1px solid #aaa;
  padding: 0 0 0.25em;

  outline: none;
  &:focus {
    outline: none;
  }
}
