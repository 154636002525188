@import '../assets/styles/admin/variables.scss';

h3.dangerHeader {
  font-size: 1.5rem;
  color: $danger-color;
  margin: 0 0 0.5rem;
}
div.dangerDiv {
  border: 1px solid $danger-color;
  background: #fff7fc;
  border-radius: 4px;
  padding: 1rem 1rem 0;
  margin-bottom: 1rem;
  fieldset {
    margin: 0 0 1rem;
    label {
      margin: 0px !important;
    }
  }
}
hr.dangerHr {
  margin: 1rem -1rem;
  border-top-color: $light-gray-blue;
}

div.notifyDiv {
  border: 1px solid $purple;
  background: rgba(111, 127, 226, 0.1);
  border-radius: 4px;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  p {
    font-size: 14px;

    &.noMargin {
      margin-bottom: 0 !important;
    }
    label {
      color: $dark-blue;
      font-weight: bold;
      small {
        font-weight: normal;
        color: $dark-gray;
      }
    }
  }

  fieldset {
    padding-left: 5px;
    margin-bottom: 0;

    span {
      font-weight: bold;
    }

    &.disabled {
      label {
        cursor: not-allowed;
        color: $disabled-color;
      }
    }
  }
}

.dynamicPageCheck {
  padding: 1.2rem !important;
  transition: background 0.14159s;
  box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid #d7dae2 !important;
  border-radius: 4px;
}
