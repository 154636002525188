@import '../assets/styles/admin/variables.scss';

.searchContainer {
  background-color: #fff;
  border: 1px solid $light-gray-blue;
  border-radius: 20px;
  width: 238px;
  padding: 0 1rem;
  height: 32px;
  margin-top: -0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    cursor: pointer;
  }

  .inputField {
    flex: 1;
    background-color: transparent;
    border: none;
    margin: 0;
    margin-left: 8px;
    color: $text-color;
    font-weight: normal;
    font-size: 13px;
    width: 174px;

    &:focus {
      outline: none;
    }
  }

  svg {
    min-width: 14px;
    color: #647fb4;
    transition: all 0.14159s;

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
    }
  }

  &.darkMode {
    background-color: rgba(255, 255, 255, 0.12);
    border: none;

    .inputField {
      color: #fff;

      &::placeholder {
        color: #b3b3b3;
      }
    }
  }
}
