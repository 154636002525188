@import '../../../../assets/styles/admin/variables.scss';

.rightGroup {
  margin-left: auto;
}

.labelledButton svg {
  margin-right: 0.5em;
}

.foregroundIcon {
  height: 19px;
  width: 19px;
}

.TextInput {
  max-width: 40px;
  // padding-right: 0.5em !important;
  text-align: center;
}
.BackgroundContainer {
  max-width: 548px;
  width: 100%;
  display: flex;
}
.BackgroundContainer > * {
  padding: 0 $toolbar-padding / 2;
}
