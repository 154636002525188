@import '../assets/styles/admin/variables.scss';
@import '../assets/styles/admin/mixins.scss';

.cover {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background: rgba(20, 33, 61, 0.8);
  opacity: 0.7;
}

.container {
  position: fixed;
  z-index: 1000;
  height: 428px;
  width: 416px;
  margin: auto;

  background-color: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  text-align: center;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  button {
    background: #4040d9;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    height: 40px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;

    &:hover {
      background: #292bcf;
    }

    &:focus {
      background: #292bcf;
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }

    &:active {
      background: #0202a1;
    }

    &:disabled {
      background: rgba(204, 212, 226, 0.5);
      color: rgba(20, 33, 61, 0.4);
      border: none;
    }
  }

  .secondary {
    background: none !important;
    color: #4040d9 !important;
    border: none;

    &:hover {
      background: none;
      border: 1px solid #cfd7ec;
    }

    &:focus {
      background: none;
      background: rgba(255, 255, 255, 0.01);
      border: 1px solid #cfd7ec;
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }

    &:active {
      background: none;
      background: rgba(242, 245, 253, 0.5);
      border: 1px solid #bfc8e0;
    }
  }
}

.circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 10px;
  width: 96px;
  height: 96px;
  background: #eff3fb;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: 48px;
}
