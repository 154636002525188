@import '../../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .help {
    height: 1.1rem;
    width: 1.1rem;
    margin: 0 0 0 0.5em;
    vertical-align: middle;
  }

  .label {
    font-size: 1.2rem;
    color: $header-color-gray;
  }

  .value {
    font-size: 1.5rem;
    font-weight: 500;
    color: $header-color-gray;
  }

  .rate {
    margin-left: 0.2em;
    font-size: 1.2rem;
    font-weight: 500;
    color: $text-color-light;
  }

  .iconUsers {
    height: 1.1rem;
    width: 1rem;
    margin: 0;
    vertical-align: 0;
  }
}
