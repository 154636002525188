.header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 15px;

  div {
    h1 {
      display: inline-block;
      font-size: 1.6rem;
      margin: 0;
      color: #14213d;
      font-weight: 500;
    }
    small {
      display: inline-block;
      font-size: 1.4rem;
      margin-left: 15px;
      color: #647fb4;
    }
  }
}

.container {
  padding-top: 20px;
}
