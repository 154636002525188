@import '../assets/styles/admin/variables.scss';

@function prefix($name) {
  @return '.prefix:global(__#{$name})';
}

:global(.admin) {
  .Select {
    #{prefix(control)} {
      background-color: transparent;
      border: none;
      // border-bottom: solid thin $primary-color;
      background-image: linear-gradient(to right, $primary-color 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 3px 1px;
      background-repeat: repeat-x;

      box-shadow: none;
      border-radius: 0px;
      min-height: auto;
      cursor: pointer;

      opacity: 1;
      transition: opacity 0.14159s;
      &:hover {
        opacity: 0.75;
      }
    }

    #{prefix(value-container)} {
      flex: 0 0 auto;
      align-items: center;
      padding: 0;
    }

    #{prefix(indicator-separator)} {
      display: none;
    }

    #{prefix(dropdown-indicator)} {
      padding: 0;
      color: $primary-color;

      svg {
        height: 11px;
        width: 11px;
      }
    }

    #{prefix(single-value)} {
      text-overflow: unset;
      max-width: none;
      position: static;
      top: unset;
      transform: unset;
      color: $primary-color;
      font-weight: 500;
      cursor: pointer !important;
    }

    div[class*='Input'] {
      margin: 0 2px;
      padding: 0;
    }

    #{prefix(single-value)} + div[class*='Input'] {
      position: absolute;
      left: 0;
    }

    input {
      margin: 0;
      height: unset;
      color: $primary-color !important;
      box-shadow: none;
    }

    #{prefix(menu)} {
      margin: 4px 0 0;
      border: none;
      border-radius: 5px;
      min-width: 250px;
      overflow: hidden;
      box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.12);
    }

    #{prefix(menu-list)} {
      padding: 0;
    }

    #{prefix(option)} {
      white-space: nowrap;
      cursor: pointer !important;
    }

    &.isInline {
      display: inline-block;
    }
  }
}
