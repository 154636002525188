.UnstackSplash {
  box-sizing: border-box;
  background: #f8fafe;
}

.UnstackSplash .content {
  margin-top: 100px;
  width: 100%;
}

.brand {
  display: block;
  margin: 0 auto 30px;
}

.spinner {
  margin: 30px auto;
}
