.placeholder {
  padding: 0.6rem 1rem;
  border-radius: 2px;
  border: dashed 1px rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.4rem;

  svg {
    height: 0.8em;
    margin-right: 0.2em;
  }
}

:global(.dark-bg) .placeholder {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}
