@import '../../assets/styles/admin/variables.scss';
// @import externalLinkIcon from '../../assets/images/icon-external-link.svg';

$favicon-size: 20px;
$favicon-margin: 10px;

:global(.admin) .SitePicker {
  position: relative;
  margin: 0 -12px;
  padding: 12px;

  &.multiSite:hover {
    background-color: #eff3fb;
  }

  .favicon {
    float: left;
    width: $favicon-size;
    margin: 0 $favicon-margin 0 0;
    border-radius: 2px;
  }

  .dropdownWrapper {
    background: #e6e9ed80;
    cursor: pointer;
    border-radius: 4px;
    padding: 10px;
    position: relative;
    font-size: 14px;
  }

  .dropdownWrapper:hover {
    background: #c7d2e380;
  }
  &.listOpen {
    .dropdownWrapper {
      background: #c7d2e380;
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: $darkest-blue;
    margin: 0 0 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #eeeff3;
  }

  .domainLink {
    margin: 0.75rem 0;
    font-size: 1.3rem;
    font-weight: 400;
    color: $text-color-light;

    &:hover {
      text-decoration: underline;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .chevron {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: $favicon-size;
    color: $secondary-color;
  }

  .SiteList {
    position: absolute;
    margin-top: 2px;
    z-index: 2;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    font-size: 1.4rem;
    padding: 8px 0 8px 0;
    width: 90.5%;

    &.enter {
      opacity: 0;
    }
    &.enterActive,
    &.enterDone,
    &.exit {
      opacity: 1;
    }
    &.exitActive,
    &.exitDone {
      opacity: 0;
    }
    &.enterActive,
    &.exitActive {
      transition: opacity 200ms;
    }
  }
}
