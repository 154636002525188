.UpDownButtons {
  display: flex;
  flex-flow: column nowrap;

  svg {
    height: 14px;
  }
}

.down {
  margin-top: -4px !important;
}

.up svg {
  transform: rotate(90deg);
}

.down svg {
  transform: rotate(-90deg);
}
