@import '../../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .button {
    position: relative;
    height: $toolbar-height !important;
    transition: opacity 0.14159s, color 0.14159s;

    &:not(.active) {
      color: $toolbar-label-color;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 $toolbar-padding;
      margin: 0 calc(#{$toolbar-padding} - 2px);
      border-left: solid 2px transparent;
      border-right: solid 2px transparent;
      border-bottom: solid 3px transparent;
      transition: border 0.14159s;
    }

    &.active::after {
      border-bottom-color: $primary-color;
    }
  }
}
