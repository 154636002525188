@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

.ChartControls,
.datasetButtons,
.dateDomainButtons {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.datasetButtons {
  .buttonContainer {
    box-sizing: border-box;

    margin: -2rem 0 0;
    padding: 1rem 0 1rem; // fallback
    padding: calc(1rem - 2px) 0 1rem;

    transition: border-color 0.14159s;
    border-top: solid 2px rgba(0, 0, 0, 0);

    button {
      @include unstyled-button;

      text-align: left;
      padding: 1rem;
      opacity: 1;
      transition: background-color 0.14159s;

      .datasetLabel,
      .datasetValue {
        opacity: 0.5;
        transition: opacity 0.14159s;
      }

      &:hover {
        background-color: rgba(220, 228, 244, 0.3);
        opacity: 1;
        border-radius: 2px;

        .datasetLabel,
        .datasetValue {
          opacity: 1;
        }
      }
    }

    &:global(.active) {
      border-color: $blue;
      opacity: 1;

      button {
        background-color: transparent;
        cursor: default;
        .datasetLabel,
        .datasetValue {
          opacity: 1;
        }
      }
    }
  }

  .datasetLabel {
    font-size: 1.2rem;
  }

  .datasetValue {
    font-size: 1.9rem;
    font-weight: 500;
    color: #14213d;
  }

  .percentChange {
    margin-left: 0.5em;
    font-size: 1.4rem;
    color: #2ec4b6;
    &:global(.less) {
      color: #cb52b0;
    }

    img {
      height: 0.7em;
      width: auto;
      margin: 0 -0.1rem 0 0;
    }
  }
}

.dateDomainButtons {
  margin-left: auto;

  button {
    @include unstyled-button;

    padding: 2px 10px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #14213d;

    opacity: 0.5;
    transition: opacity 0.14159s;

    &:hover {
      opacity: 0.7;
    }

    &:global(.active) {
      background-color: #dce4f4;
      border-radius: 11px;
      opacity: 1;
    }
  }
}
