@import '../../assets/styles/admin/variables.scss';

.main {
  margin: 10rem 0 5rem;

  > header {
    max-width: 42em;
    margin: 4rem auto;
    padding: 0 $gutter;
    text-align: center;

    h1 {
      font-size: 3rem;
      font-weight: 300;
      margin: 0;
    }

    p {
      margin-top: 2rem;
      color: $text-color-light;
    }
  }

  :global {
    .form-card {
      max-width: 440px;
      margin: 0 auto;
      padding: 2rem;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    }

    .next {
      display: block;
      margin: 3rem auto;
      width: 100%;
      max-width: 350px;
    }
  }
}

.email {
  margin-right: 8px;
}
