@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .container {
    @include material-card;

    flex: 0 0 300px;
    width: 300px;

    position: sticky;
    top: 90px;

    dl.list {
      padding: 2rem;
      margin: 0;

      dd + dt {
        margin-top: 1.4rem;
      }

      dt {
        font-size: 1.2rem;
        color: $text-color-light;

        .icon {
          margin-bottom: -2px;
        }
      }

      dd {
        margin: 0;
        font-size: 1.4rem;
        color: $text-color;
        overflow: auto;
      }
    }
  }

  .muted {
    color: $text-color-light;
  }
}
