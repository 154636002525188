@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

$header-height: 5rem;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
}

.container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  display: flex;

  .categoriesContainer {
    margin-top: 7rem;
    margin-left: 2rem;
    max-width: 200px;
    min-width: 200px;
    .categoriesList {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      background-color: $dark-background-color;
      color: rgba(255, 255, 255, 0.5);
      height: fit-content;
      border-radius: 10px;
      padding: 8px 0;
      width: 200px;
      font-size: 1.4rem;

      & > span {
        cursor: pointer;
        padding: 8px 24px;

        &:hover {
          color: #fff;
        }
        &.activeCategory {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.2);
          text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      &.disableList {
        background-color: $disabled-color;
        & > span {
          cursor: not-allowed;
          &:hover {
            font-weight: normal;
            color: rgba(255, 255, 255, 0.65);
            text-shadow: none;
          }
        }
      }
    }
  }

  .componentsContainer {
    width: calc(100% - 200px - 2rem + 45px);
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }

    .componentsList {
      overflow-y: scroll;
      margin-left: 24px;
      height: 100vh;
      overflow-x: hidden;

      .componentCardContainer {
        // height: 175px;
        display: flex;
        align-items: center;

        .enter {
          transform: translateX(-100%);
          opacity: 0;
        }

        .enterActive {
          opacity: 1;
          transition: all 0.25s;
          transform: translateX(0);
        }

        div.componentCard {
          z-index: 1;
          cursor: pointer;
          min-width: 175px;
          max-width: 175px;
          border-radius: 10px;
          &:not(:first-child) {
            margin-top: 1rem;
          }

          &.stacked {
            position: relative;
            button:first-child {
              position: absolute;
              left: 24px;
              top: 27px;
              height: 90px;
            }

            button:nth-child(2) {
              position: absolute;
              top: 15px;
              left: 12px;
              height: 115px;
            }

            button:last-child {
              position: relative;
            }

            &.hovered,
            &:hover {
              button:first-child {
                display: none;
              }

              button:nth-child(2) {
                display: none;
              }
            }
          }
        }

        .iconExtend {
          background-color: $purple;
          padding: 3px 10px 0;
          z-index: 1;
          height: min-content;
          border-radius: 5px;
          margin: auto 0 auto -15px;

          &.transition {
            margin-left: 50px;
          }
          transition: all 0.2s;
        }
        .elementsContainer {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: visible;
          align-items: center;
          z-index: 0;
          grid-column-gap: 2rem;
          left: -45px;
          position: relative;
          margin: 0;

          & > div {
            width: 175px;
            flex: 0 0 auto;

            &:first-child {
              margin-left: 18px;
            }

            &:hover {
              margin: 0 18px;
              &:last-child {
                margin-right: 38px;
              }
            }
            &:hover > button {
              transform: scale(1.2);
              transition: all 0.14159s;
            }
            &:last-child {
              margin-right: 38px;
            }
            transition: all 0.14159s;
          }
        }
      }
    }
  }

  .enter & {
    transform: translateX(-100%);
  }

  .enterActive & {
    transition: transform 0.2s;
    transform: translateX(0);
  }

  .exit & {
    transform: translateX(0);
  }

  .exitActive & {
    transform: translateX(-100%);
    transition: transform 0.1s;
  }
}
