@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

.container {
  width: 100%;
  max-width: 800px;
  min-height: 420px;
  margin: 5rem auto;

  background: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;

  display: flex;
  overflow: hidden;

  > * {
    flex: 1;
    box-sizing: border-box;
  }

  @include respond-below(md) {
    max-width: 500px;
  }

  @include respond-below(500px) {
    border-radius: 0px;
  }
}

.form {
  margin: 0;
  padding: 3rem 3rem 1rem;

  & fieldset {
    margin-bottom: 1.25rem;
  }
}

.formRow {
  display: flex;
  flex-direction: row;
  gap: 12.5px;
}

.terms {
  display: block;
  margin-top: 1.5rem;
  font-size: 1.3rem !important;

  a {
    color: inherit !important;
    text-decoration: underline;
    cursor: pointer;
  }
}

.twoFields {
  display: flex;

  > * {
    flex: 1;
  }

  > :not(:first-child) {
    margin-left: 2rem;
  }
}
@include respond-below(md) {
  .twoFields {
    display: block;

    > :not(:first-child) {
      margin-left: 0rem;
    }
  }
}
