.LogoSimpleBanner {
  width: 100%;
  padding: 5rem 0 2rem;
  text-align: center;

  .link {
    transition: all 0.12s;
  }

  .link:hover {
    opacity: 0.8;
  }

  .logo {
    height: auto;
    width: 180px;
  }
}
