.drawer {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  height: 100%;
  position: fixed;
  right: 0;
  width: 240px;
  background-color: #090b45;

  &.addMargin {
    margin-top: -2rem;
  }

  h4 {
    color: #fff;
  }

  label {
    margin-left: 0;
    font-weight: 400;
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
  }

  input,
  select {
    height: 4rem !important;
    width: 100%;

    &[type='text'],
    &[type='number'] {
      color: #ffffff;
      background-color: #2a2d5e;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  :global(.SmartUrlInput) {
    height: 4rem !important;
    margin-bottom: 2.5rem;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.07);

    input {
      margin: 0;
    }
  }

  .newTab {
    cursor: pointer;
    &.active {
      color: #1495cd;
    }
  }

  .spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    span {
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 0.05rem;
      margin-bottom: 1rem;
    }
  }

  .videoOptions {
    color: #fff;

    svg {
      margin-left: 5px !important;
      margin-bottom: -1px !important;
      path {
        fill: rgba(255, 255, 255, 0.5);
      }
      &:hover {
        path {
          fill: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .tooltip {
      position: absolute;
      top: -72px;
      border-radius: 5px;
      right: -7px;
      background-color: #090b45;
      max-width: 145px;
      font-size: 12px;
      padding: 5px;
      filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.75)) drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.75));

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        right: 10px;
        width: 0;
        border-top: 5px solid #090b45;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }
  }
}
