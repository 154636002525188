.react-datepicker-popper div .react-datepicker {
  button.react-datepicker__navigation {
    height: 10px;
    padding: 0;
    border: 0.45rem solid transparent;
    box-shadow: none;

    &.react-datepicker__navigation--previous {
      border-right-color: #ccc;
    }
    &.react-datepicker__navigation--next {
      border-left-color: #ccc;
    }
  }
}

.admin fieldset .react-datepicker-wrapper,
.admin fieldset .react-datepicker__input-container {
  width: 100%;
  height: 48px;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  max-height: 150px;
  overflow: scroll;
}

.react-datepicker-time__input input {
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  width: auto !important;
}

.react-datepicker {
  h2 {
    font-size: 0.944rem !important;
    font-weight: 700 !important;
  }
  .react-datepicker__navigation--next--with-today-button,
  .react-datepicker__navigation--previous {
    margin-top: 6px !important;
  }
}
