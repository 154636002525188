.contentContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 1.5rem;
  background-color: #1f2258;
  flex: 4 1;

  .item {
    background-color: #090b45;
    border-radius: 2px;
    width: 150px;
    height: 140px;
    padding: 1rem;
    margin: 0.5rem;
    transition: all 0.1s;
    border: 2px transparent solid;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    &.selected {
      border: 1px solid #1495cd;
    }
  }
}
