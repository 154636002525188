.Fill {
  border-right: solid thin rgba(255, 255, 255, 0.14159);
  height: 40px;
  padding-left: 16px !important;
}

.Border {
  border-right: solid thin rgba(255, 255, 255, 0.14159);
  padding: 0px 16px !important;
}

.Shadow {
  padding-left: 16px !important;
}
