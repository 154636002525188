@import url('https://fonts.googleapis.com/css?family=Catamaran:400,700|Merriweather:400,400i,700,700i');

// Skeleton

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}
h1 {
  font-size: 4rem;
  line-height: 1.2;
}
h2 {
  font-size: 3.6rem;
  line-height: 1.25;
}
h3 {
  font-size: 3rem;
  line-height: 1.3;
}
h4 {
  font-size: 2.4rem;
  line-height: 1.35;
}
h5 {
  font-size: 1.8rem;
  line-height: 1.5;
}
h6 {
  font-size: 1.5rem;
  line-height: 1.6;
}

/* Larger than phablet */
@media (min-width: 550px) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 3.6rem;
  }
  h4 {
    font-size: 3rem;
  }
  h5 {
    font-size: 2.4rem;
  }
  h6 {
    font-size: 1.5rem;
  }
}

// Base

p {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $header-color-gray;
  font-family: $font-family;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 1.3;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 1.9rem;
  font-weight: 500;
}

h4 {
  font-size: 1.6rem;
  font-weight: 400;
}
