.ErrorWrapper {
  background-color: #f8fafe;
  width: 100%;
  padding: 30px;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  color: #14213d;
}
.ErrorCard {
  display: grid;
  grid-template-columns: 32px 1fr;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: white;
  width: fit-content;
  gap: 10px;
  position: fixed;
  top: 33%;
}
.ErrorTitle {
  font-weight: 500;
}
.ErrorText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
