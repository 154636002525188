// button,
// input[type="submit"],
// input[type="reset"],
// input[type="button"] {
.button {
  display: inline-block;
  height: inherit;
  padding: 0 2rem;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;

  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-family: inherit;
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0.02rem;
  text-align: center;
  line-height: 3.8rem;

  background: $secondary-color;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  transition: opacity 0.12s;

  width: auto;

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.75;
  }

  &[disabled]:not(.disable-silently) {
    color: $disabled-color;
    border-color: $disabled-color;
    cursor: default;
    box-shadow: none;

    &:hover {
      opacity: 1;
    }
  }

  &.button-primary {
    background: $purple;
    font-family: inherit;
    width: auto;
    &:hover {
      background: $purple;
    }
  }

  &.button-secondary {
    border-color: $purple;
    background-color: #fff;
    color: $purple;
    text-shadow: none;
    box-shadow: none;

    svg {
      margin-bottom: -4px;
      margin-left: -4px;
      margin-right: 4px;
    }
  }

  &.button-success {
    background: $green;
  }

  &.button-danger {
    background: $danger-color;
  }

  &[disabled]:not(.disable-silently) {
    background-color: $disabled-background-color;
    border-color: transparent;
    // color: rgba(0,0,0,.4);
    text-shadow: none;
  }

  &.button-full {
    width: 100%;
    height: 40px;
    margin: 1rem 0 0;

    &:not(.button-primary) {
      background: $secondary-color;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      font-family: inherit;
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.02rem;
      text-align: center;
      line-height: 3.8rem;

      &:hover:not([disabled]) {
        opacity: 0.75;
        background: $secondary-color;
      }
    }
  }

  // &.button-sm.button-full,
  &.button-sm {
    font-size: 1.2rem;
    line-height: 2.6rem;
    padding: 0 1rem;
    font-family: inherit;
    width: auto;

    &.button-primary {
      &:hover:not([disabled]) {
        opacity: 0.75;
        background: $purple;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    &:not(.button-primary) {
      background: $secondary-color;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      color: #fff;

      &:hover:not([disabled]) {
        opacity: 0.75;
        color: #fff;
        background: $secondary-color;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  //button with icons
  &.button-icon {
    display: flex;
    align-items: center;
    height: 0;
    padding: 1.5rem 2rem;
    margin: 0 10px 0 auto;
    font-family: inherit;
    width: auto;

    svg {
      color: white;
      margin: 0;
      margin-right: 1rem;
    }

    &:hover:not([disabled]) {
      opacity: 0.75;
      background: $purple;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:not(.button-primary) {
      background: $secondary-color;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      color: #fff;

      &:hover:not([disabled]) {
        opacity: 0.75;
        color: #fff;
        background: $secondary-color;
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}

button.button-text {
  @include unstyled-button;
}
