@import '../../assets/styles/admin/variables.scss';

$nav-dark-background: rgba(9, 11, 69, 0.05);

@mixin nav-items {
  $padding-left: 12px;
  $top-bottom-gutter: 10px;
  $border-width: 2px;

  opacity: 0.8;
  position: relative;
  cursor: pointer;
  margin: 1px 0;
  border-radius: 4px;

  padding-left: $padding-left;
  display: block;
  padding-top: $top-bottom-gutter;
  padding-bottom: $top-bottom-gutter;

  color: #0e1521;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  transition: background-color 0.6s cubic-bezier(0.16, 1, 0.3, 1) 0s, color 0.6s cubic-bezier(0.16, 1, 0.3, 1) 0s;

  &.links {
    display: flex;
    align-items: baseline;
    padding-left: 36px;
    padding-top: $top-bottom-gutter - 2.5;
    padding-bottom: $top-bottom-gutter - 2.5;
    color: #5c6778;
    width: calc(100% - 23px);
    font-weight: normal;
    left: 12px;
  }

  &.active {
    background: #eeedfc;
    color: #0e1521;
    display: flex;
    align-items: center;
    gap: 14px;
    padding-left: 15px;
    font-weight: 500;

    &::before {
      content: '';
      position: relative;
      width: 7px;
      height: 7px;
      background-color: #4040d9;
      border-radius: 2rem;
    }
  }

  &:hover {
    background-color: #eeeff3;
    color: #0e1521;
  }

  &[disabled] {
    color: $disabled-color;
    background: none;
    cursor: default;
  }

  svg {
    margin-right: 9px;
    height: 20px;
    width: 20px;
    vertical-align: -4px;
    transition: none;
    color: #7b8595;
    // color: rgb(100, 127, 180);
  }

  transition: margin 0.25s;
  .tag {
    padding: 4px 8px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    position: absolute;
    background: #5c6778;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: white;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    line-height: 1;
  }

  &.activePanel {
    opacity: 1;
    font-weight: 600;
    .tag {
      background-color: #5050d8;
    }
    &:not(.activeLink) {
      margin-top: 12px;
    }
    &:hover {
      background: transparent;
      cursor: default;
    }
    &.activeLink {
      background: #eeedfc;
      color: #0e1521;
    }
    svg {
      color: #4040d9;
      opacity: 1;
    }
  }

  &:global(.active) svg {
    // filter: drop-shadow(-2px 2px 0px $blue);
    // transform: translate(1px, -1px);
    opacity: 1;
  }

  &[disabled] svg {
    filter: none;
    transform: none;
    opacity: 0.3;
  }
}

:global(.admin) {
  .Nav {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $nav-width;
    overflow-y: scroll;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
    }

    display: flex;
    flex-flow: column nowrap;

    background-color: #ffffff;

    .NavHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      h4 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 0;
      }

      .SiteLinkContainer {
        position: relative;
        display: inline-block;

        .SiteLink {
          color: #4040d9;
          font-weight: 500;
          font-size: 12px;
          margin: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 2px;
        }

        .SiteLinkTooltip {
          position: absolute;
          top: 130%;
          right: 0;
          background-color: #000233;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          padding: 4px 8px;
          border-radius: 4px;
          white-space: nowrap;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease-in-out;
          z-index: 999;

          .SiteLinkTooltipText {
            display: block;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .SiteLinkTooltip::after {
          content: '';
          position: absolute;
          top: -11px;
          right: 16px;
          border-width: 6px;
          border-style: solid;
          border-color: transparent transparent #000233 transparent;
          z-index: -1;
        }
      }
      .SiteLinkContainer:hover .SiteLinkTooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 2rem;
    }

    &.withNotifications {
      top: $notification-bar-height;
    }

    nav {
      flex: 1 0 auto;
      padding: 0 12px;

      > a,
      > div a {
        @include nav-items;
      }
      & > a:last-of-type {
        margin-top: 16px;
      }

      span.mediaAccess {
        $padding-left: 18px;
        $border-width: 2px;
        margin-left: -$nav-gutter;
        padding-left: $nav-gutter - $border-width;
        border-left: solid $border-width rgba(0, 0, 0, 0);

        display: block;
        padding-top: 8px;
        padding-bottom: 8px;

        color: #090b45;
        font-size: 1.4rem;

        opacity: 0.6;
        cursor: pointer;
        svg {
          margin-right: 8px;
          height: 20px;
          width: 20px;
          vertical-align: -4px;
          transition: none;
        }
      }
    }
  }

  .external:hover:after {
    content: '';
    display: inline-block;
    background: url('../../assets/images/icon-external-link.svg') no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    margin: 0 0 0 8px;
  }
}

.animatedDiv {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;
  > div {
    display: flex;
    flex-direction: column;

    > a {
      opacity: 1 !important;
    }
    ::after {
      content: '';
      display: block;
      transform: scaleY(0%);
      transition: transform 1s;
    }
  }
  &.active > div::after {
    height: 1rem;
    transform: scaleY(100%);
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1rem;
  }
}

.quickStartItems {
  > a,
  > div a {
    @include nav-items;
    margin-left: 8px;
    width: 192px !important;

    &:hover {
      background-color: #dde2f8 !important;
    }
  }

  & .activePanel {
    width: 204px !important;
    margin: 8px 8px 4px 8px !important;
    padding-left: 15px !important;
    font-size: 14px;
    color: #14213d;

    &:hover {
      background-color: transparent !important;
      opacity: 1 !important;
    }
  }
}
