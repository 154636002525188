@import 'assets/styles/admin/variables.scss';
@import 'assets/styles/admin/mixins.scss';

.options {
  @include unstyled-button;
  box-sizing: content-box;
  height: 20px;
  width: 20px;
  flex: 0 0 auto;
  padding: 0;
  margin: 0 !important;
  vertical-align: middle;
  color: $gray-blue;

  box-shadow: none;
  transition: opacity 0.14159s;

  &.isActive,
  &:hover {
    color: #000;
  }

  svg {
    height: 20px;
  }

  // &:hover {
  //   opacity: 0.5;
  // }
}
