@import '../../../../assets/styles/admin/variables.scss';

:global(.admin) {
  .variants {
    margin: 0;
    width: 100%;
    td,
    th {
      padding: 0.25rem 1rem;
    }
    th {
      font-size: 0.9em;
      font-weight: 500;
      color: #14213d;
      background-color: #f8fafe;
    }
    td.check {
      font-size: 1.1em;
      font-weight: bold;
      color: #2ec4b6;
    }
    tr:first-child {
      td {
        border-top: none;
      }
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
