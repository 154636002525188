@import '../../../../../assets/styles/admin/variables.scss';

.SparkForm {
  padding-bottom: 1rem;

  * + fieldset {
    margin: 2rem 0 0;
  }
}

.radioGroup {
  margin-top: 1rem;

  label {
    margin: 0 !important;
    padding: 1.5rem;
    border: 1px solid $border-color;
    transition: background 0.14159s;
    cursor: pointer;

    &.selected {
      background: #f8fafe;
    }

    &:not(:first-child) {
      border-top: none;
    }

    input[type='radio'] {
      float: left;
      margin: 0.45rem 0 0 0;
    }

    input[type='text'] {
      background-color: #e9effc;
      margin-top: 1rem;
    }

    .title {
      margin: 0 0 0 2rem;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.5rem;
      display: grid;
    }
  }
}

.add {
  color: $primary-color;
  label {
    font-size: 1.5rem;
    font-weight: 300;
    margin-left: 0;
    vertical-align: middle;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 20px;
  }
  svg {
    height: 28px;
    width: 28px;
    vertical-align: middle;
    transition: all 0.14159s;
    margin-right: 1rem;
  }

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.fields {
  margin-bottom: 2rem;
  label {
    margin: 0 0 0.5rem;
    font-weight: normal;
    font-size: 1.5rem;
  }

  .field {
    background-color: #f8fafe;
    border-radius: 2px;
    border: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    font-size: 1.4rem;
    height: 38px;
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      color: #859ac2;
      cursor: pointer;
    }

    .fieldDrag {
      cursor: move;
    }

    .tag {
      background-color: #dce4f4;
      padding: 4px 7px;
      font-size: 10px;
      font-weight: 500;
      border-radius: 2px;
      text-transform: uppercase;
    }

    .options > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.required {
  svg {
    height: 11px;
    width: auto;
    color: white;
    padding: 4px;
    margin-right: 1rem;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
  }
}

.deleteActionLink {
  margin-bottom: 6rem;

  p {
    color: $text-color-light;
    font-size: 1.4rem;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  a.confirm {
    color: $danger-color;
    text-decoration: underline;
  }
}

.helpText {
  margin-bottom: 0;
  p {
    margin-bottom: 0;
  }
}

.editOverlay {
  position: absolute !important;
  padding: 8px 15px !important;
  background: rgba(9, 11, 69, 0.5) !important;
  z-index: 1 !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.1rem !important;
  border-radius: 35px !important;
  color: white !important;
  cursor: pointer;

  svg {
    margin-right: 1rem !important;
    height: 10px !important;
    width: 10px !important;
    g {
      g {
        fill: white !important;
      }
    }
  }
}
