@import '../../assets/styles/admin/variables.scss';

.container {
  margin-top: 3rem;
  + .container {
    margin-top: 5rem;
  }
  text-align: center;

  h2 {
    font-size: 2.6rem;
    line-height: 4rem;
    font-weight: normal;
  }

  p {
    font-size: 1.6rem;
    color: $text-color-light;
    line-height: 2.4rem;
  }
}

.cards {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}

.card {
  width: 20rem;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 3rem;
  }
}

.top {
  position: relative;

  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;

  color: $purple;
  .active & {
    color: #ffffff;
    background: $purple;
  }
}

.checkmark {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  color: #ffffff;
}

.bottom {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}

.button {
  width: 34rem;
}
