// From Skeleton

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid #e1e1e1;
}

// Unstack

img {
  width: 100%;
}

[disabled] {
  cursor: not-allowed;
}

.clickable {
  cursor: pointer;
}
