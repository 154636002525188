@import '../../../../assets/styles/admin/variables.scss';

.modal {
  padding-top: 2rem;
  width: 60vw;
  max-width: 568px;
  min-width: 425px;
  height: 350px;

  h3 {
    border-bottom: 1px solid #dce4f4;
    padding-bottom: 2rem;
    padding-left: 2rem;
    font-size: 1.7rem;
    font-weight: 500;
  }

  .contentContainer {
    .inputContainer {
      display: flex;
      justify-content: space-between;
      padding-bottom: 3rem;
      p {
        font-size: 1.6rem;
      }
      span {
        margin-left: 10px;
        font-size: 1.5rem;
        color: #14213dcc;
      }

      & > :last-child {
        flex: 0 0 35%;
      }

      .disconnectStatement {
        color: $text-color;
        font-size: 14px;
      }
    }
    .disconnectInput {
      margin-top: 10px;
      margin-bottom: 5px;

      & span {
        color: $text-color;
        font-size: 1.2rem;
        max-width: 60%;
      }
    }
    padding: 1.5rem 2rem;
    height: 195px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & div:last-child {
      margin-left: auto;

      & button:first-child {
        margin-right: 1rem;
      }
    }
  }
}
.syncContainer {
  display: flex;
  small,
  > div {
    margin-left: 30px;
  }
}

.optionContainer {
  span {
    margin-left: 10px;
    font-size: 1.5rem;
    color: #14213dcc;
  }
  input {
    margin-bottom: 0px;
    width: 2rem;
    height: 2rem;
  }
  & div {
    margin-top: 14px;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}
