@import '../../assets/styles/admin/variables.scss';

.EditorCanvasContainer {
  box-sizing: border-box;
  padding: 72px 0 0;
  width: 100%;
  background-color: #fff;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }

  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;

  @media only screen and (max-width: 767px) {
    & {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.DeviceMode {
  margin: 0 auto;
  background: #a9b3c9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44'%3E%3Crect height='3' width='3' x='21' y='21' style='fill: white' /%3E%3C/svg%3E");
  background-position: center top;
  background-size: 22px;
  min-height: calc(100vh - 72px);

  display: flex;
  justify-content: center;

  :global body.sriracha {
    min-height: calc(100vh - #{$admin-bar-height});
  }
  .sriracha {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
