a {
  opacity: inherit;
  &:hover {
    opacity: 1 !important;
  }
}
.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  width: 240px;
  height: 55px;
  background: rgba(100, 127, 180, 0.088);
  span {
    flex: none;
    order: 1;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #102040;
    opacity: 0.9;
  }
  &:hover {
    background: rgba(100, 127, 180, 0.16);
    span {
      color: #4040d9;
      opacity: 1 !important;
    }
  }
  &:active {
    background: rgba(100, 127, 180, 0.24);
    color: #102040;
  }
  .icon {
    height: 23px;
    width: 23px;
  }
}
