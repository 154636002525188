@import '../../assets/styles/admin/variables.scss';

.pagination {
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex;
  margin: 3rem auto 1rem !important;
  justify-content: center;
  font-size: 14px !important;
  font-weight: 500 !important;

  .activeClassName {
    background-color: $blue;
    padding: 0 7px;
    cursor: pointer;
    background: #1495cd;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 13px;
    color: #ffffff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  a.activeLinkClassName {
    color: #fff !important;

    &:focus {
      outline: none;
    }
  }

  a:not(.activeLinkClassName) {
    color: $secondary-color !important;
  }

  .pageClassName {
    cursor: pointer;
    margin: 0 1rem;
  }

  .previousClassName {
    margin-right: 15px;
    cursor: pointer;
  }

  .nextClassName {
    margin-left: 15px;
    cursor: pointer;
  }

  .previousLinkClassName {
    &:focus {
      outline: none;
    }
  }

  .nextLinkClassName {
    &:focus {
      outline: none;
    }
  }

  .breakClassName {
    cursor: pointer;
  }
}
