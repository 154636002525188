button.card {
  // Standard button overrides
  margin: 0;
  padding: 0;
  height: auto;
  border: none;
  text-transform: none;
  line-height: normal;
  margin: 1rem 0;

  // Card styles
  display: inline-grid;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 -1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;

  transition: all 0.14159s;
  transform-origin: left;
  opacity: 0.95;

  &.increaseWidth,
  &:not(.isListView):hover {
    transform: scale(1.2);
    opacity: 1;
    margin: 3rem 0;
  }
  &.isListView {
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }
  &.isElement {
    transform-origin: center;
  }
}

.thumb {
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
}

.label {
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #143e8b;
  background-color: #f5f8ff;
  letter-spacing: 0;
  text-align: center;
  margin-top: 0;
  padding: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
