@import '../../assets/styles/admin/variables.scss';
@import '../../assets/styles/admin/mixins.scss';

.main {
  .message {
    margin-top: 15rem;
    text-align: center;
    > div {
      margin: 0 auto;
    }
  }

  .logo {
    text-align: center;
    margin-top: 3em;
    img {
      width: auto;
      height: 2em;
    }
  }

  .form {
    max-width: 350px;
    margin: 2rem auto;
    padding: 3rem;

    background: #ffffff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    overflow: hidden;
  }

  .terms {
    display: block;
    margin-top: 2rem;

    a {
      color: inherit !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .twoFields {
    display: flex;

    > * {
      flex: 1;
    }

    > :not(:first-child) {
      margin-left: 2rem;
    }
  }
}
@include respond-below(md) {
  .twoFields {
    display: block;
    > :not(:first-child) {
      margin-left: 0rem;
    }
  }
}
