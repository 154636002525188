.headingContainer {
  width: 542px;
  margin: auto;
  text-align: center;
  margin-top: 84px;

  .brand {
    width: 160px;
    height: 40px;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 50px;
    color: #000000;
  }

  .subtitle {
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #647fb4;
    width: 100%;
  }
}
.containers {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  margin-top: 46px;
  justify-content: center;
  gap: 32px;
  padding-bottom: 90px;
}
.container {
  margin: auto;
  text-align: center;
  height: 742px;
  background-color: #f8fafe;

  .header {
    height: 144px;
    text-align: center;

    .price {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #647fb4;
    }
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #14213d;
      margin-top: 8px;
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(20, 33, 61, 0.8);
      width: 224px;
      margin: auto;
      margin-top: 16px;
    }
  }
  .tile {
    height: 403px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding-top: 32px;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    background: white;

    .banner {
      padding: 2px;
      gap: 10px;
      position: absolute;
      width: 141.26px;
      height: 21px;
      left: 190.85px;
      top: 30px;
      background: #ca0b4a;
      transform: rotate(45deg);
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      font-weight: 500;
    }
    button {
      background: #4040d9;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      width: 236px;
      height: 40px;
      color: white;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      &.secondary {
        border-color: #4040d9;
        background: #ffffff;
        border-width: 2px;
        color: #4040d9;

        &:hover {
          box-shadow: 0px 0px 0px #00000000;
          opacity: 0.9 !important;
        }
      }
    }
    button:not(.secondary):hover {
      background: #6666e1;
    }
    button:not(.secondary):focus {
      background: #4040d9;
      box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(64, 64, 217, 0.2);
    }
    button:disabled {
      background: rgba(204, 212, 226, 0.5);
      color: rgba(20, 33, 61, 0.4);
      border: none;
    }
    .perksContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 32px 20px;
      gap: 8px;
      width: 300px;
      height: 155px;
      margin-top: 32px;

      div:not(.divider) {
        width: 236px;
        height: 22px;
        font-size: 16px;
        line-height: 140%;

        text-align: center;
        color: #647fb4;
      }
    }
  }
  .benefits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 32px 32px;
    gap: 16px;
    width: 300px;

    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      color: rgba(20, 33, 61, 0.8);
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      font-size: 14px;
      line-height: 140%;
      color: rgba(20, 33, 61, 0.8);
      text-align: left;

      .benefitText {
        width: 181px;
      }
      svg {
        height: 17px;
        width: 17px;
      }
      .iconHelp {
        cursor: pointer;
      }
    }
  }
}
