@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

:global(.admin) {
  .container {
    display: flex;
    align-items: flex-start;

    flex-wrap: wrap;
    max-width: 1400px;
    justify-content: center;
    margin: 0 auto;
    @media screen and (max-width: 1550px) {
      flex-direction: row;
      > aside {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: sticky;
        top: 20px;

        > div {
          margin-top: 20;
        }
      }
    }
    section.quickstart {
      width: 780px;
      margin-top: 32px !important;
      header {
        margin-top: 0px;
      }
    }
    @media screen and (max-width: 1442px) {
      section.quickstart {
        width: 100%;
      }
      justify-content: flex-start;
    }

    > aside {
      padding: 40px 20px 20px 40px;

      @include respond-below(1442px) {
        flex-direction: column;
        width: 100%;
        padding: 0px;
        > section {
          margin-right: 0;
        }
        > div {
          margin-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }

  .dismissQuickstart {
    font-size: 14px;
    text-decoration-line: underline;
    color: $dark-gray-blue;
    cursor: pointer;
    margin: 24px auto;
    text-align: center;
    display: block;

    overflow: visible;
    opacity: 1;
    transition: opacity 0.6s ease-in, max-height 0.3s ease-in;
    height: auto;

    &.collapsed {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.6s ease-out, max-height 0.35s ease-out;
    }
  }
}
