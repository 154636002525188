@import '../assets/styles/admin/variables.scss';
@import '../assets/styles/admin/mixins.scss';

main.simplePage {
  background-color: $background-color;
  position: relative;

  // Sticky bottom while preventing margin-collapse issues
  min-height: 100vh;
  padding-bottom: 1px;
  box-sizing: border-box;
}

.banner {
  position: relative;
}

section.centeredContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  text-align: center;
}
