@import '../../assets/styles/admin/variables.scss';

.tab {
  padding: 4px 12px;
  background: rgba(220, 228, 244, 0.5);
  border-radius: 20px;
  border: 1px solid $light-gray-blue;
  color: $text-color-light;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.14159s;

  &:hover {
    &:not(.isSelected) {
      background: rgba(220, 228, 244, 0.8);
    }
  }
}

.isSelected {
  background: $blue;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: white;
}
