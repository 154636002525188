@import '../../assets/styles/admin/variables.scss';

:global(.admin).container {
  position: fixed;
  bottom: 12px;
  right: 192px;
  perspective: 300px;
  z-index: 10;
}

.spinner {
  height: 50px;
  width: 190px;

  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  white-space: nowrap;

  transform-origin: center;
  transform-style: preserve-3d;
  transition: transform 0.3s;

  &.HIDDEN,
  .container:global(-enter) &,
  .container:global(-exit-active) &,
  .container:global(-exit-done) & {
    transform: rotateX(90deg);
  }
  .container:global(-enter-active) & {
    transform: rotateX(0deg);
  }
  &.SUCCESS {
    transform: rotateX(180deg);
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;

    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 9999px;

    svg {
      height: 16px;
      margin-right: 0.5em;
      filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
    }
  }
}

.publish {
  background: $purple;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.14159s;

  &:hover {
    background: #96a2ef;
  }

  .PUBLISHING & {
    background-image: repeating-linear-gradient(90deg, $primary-color, $primary-color 20%, #8f5beb, $primary-color);
    background-size: 800% 100%;

    animation: PublishingAnimation 3s linear infinite;

    @keyframes PublishingAnimation {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 115% 50%;
      }
    }
  }
}

.success {
  background: $green;
  transform: rotateX(180deg);
}

.discard {
  background: $dark-gray-blue;
  height: 50px;
  width: 180px;
  cursor: pointer;

  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  white-space: nowrap;

  transform-origin: center;
  transform-style: preserve-3d;
  transition: transform 0.3s;

  position: absolute;
  top: 0;
  left: -190px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;

  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 9999px;

  transition: all 0.14159s;

  &:hover {
    background: $dark-gray-blue-faded;
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 0.5em;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  }
}
