@import '../../../assets/styles/admin/variables.scss';

.filtersContainer {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem;

  .filter {
    font-size: 14px;

    .filterIcon {
      margin-right: 0.8rem;
      display: inline;
      vertical-align: -0.05rem;
    }
  }

  .searchFieldContainer {
    display: flex;
    align-items: center;

    .newContactButton {
      display: flex;
      align-items: center;
      height: 0;
      padding: 1.5rem 2rem;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      box-sizing: border-box;

      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.02rem;
      text-align: center;
      line-height: 3.8rem;

      background: $purple;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      transition: opacity 0.12s;

      &:hover,
      &:focus {
        outline: 0;
        opacity: 0.75;
      }

      svg {
        color: #ffffff;
        margin-right: 1rem;
      }
    }
  }
}
