@import '../../../../../assets/styles/admin/variables.scss';
@import '../../../../../assets/styles/admin/mixins.scss';

.CustomView {
  .textareaContainer {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 46em;
    flex-basis: 100%;
    display: flex;

    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }

  .textareaHeader {
    border: 1px solid #b2c1df;
    background-color: #dce4f4;
  }

  .textareaControl {
    border: 1px solid #b2c1df;
    border-top: 0px;
  }

  .customCodeText {
    font-family: $font-family;
    font-weight: 500;
    margin: 12px 0px 12px 20px;

    font-size: 12px;
    color: #14213d;
    letter-spacing: 0.05rem;
  }

  .instructionText {
    font-family: $font-family;
    color: #777;
    margin: 12px 20px 12px 20px;
    opacity: 0.6;
    font-size: 12px;
    color: #14213d;
  }

  textarea {
    width: 100%;
    height: 24rem;
    margin: 0;
    background-color: transparent;
    border: none !important;

    font-size: 14px;
    color: #14213d !important;
    font-family: monospace;
  }
}
