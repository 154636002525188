@import '../../assets/styles/admin/variables.scss';

:global(.admin) {
  .account {
    form {
      margin-bottom: 0;
    }

    .links a {
      margin-right: 2rem;
      font-size: 1.5rem;
    }
  }
}
