@import '../../../assets/styles/admin/variables.scss';

.container {
  max-width: 650px;
  margin: 0 auto;
}

.attribution {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .profile {
    flex: 0 0 auto;
    margin: 0 2rem 0 0;
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .details {
    flex: 1;

    .name {
      font-size: 1.6rem;
    }

    .title {
      font-size: 1.4rem;
      opacity: 0.5;
    }
  }

  .iconQuote {
    height: 42px;
    width: 42px;
    color: $purple;
    align-self: flex-start;
    transform: rotate(180deg);
  }
}
