@import 'assets/styles/admin/variables';

:global(.admin) {
  .container {
    margin-right: -$nav-gutter;
  }

  .header {
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;

    position: sticky;
    background: #f8fafe;
    z-index: 2;
    top: 0;
    &.notificationBarIsOut {
      top: 40px;
    }

    * {
      margin-bottom: 0 !important;
    }
  }

  .backIcon {
    height: 30px;
    width: 30px !important;
    vertical-align: middle;
  }

  .statusMessage {
    font-size: 1.5rem !important;
  }

  .successMessage {
    @extend .statusMessage;
    color: $success-color !important;
  }

  .discardButton {
    @extend .statusMessage;
  }

  .discardIcon {
    height: 0.75em !important;
    width: 0.75em !important;
    vertical-align: baseline;
    margin-top: 0 !important;
    margin-right: 0.25em;
  }

  .headerLeft {
    flex: 0 0 (100% / 3);
    display: flex;
    align-items: center;
  }

  .headerCenter {
    flex: 0 0 (100% / 3);
    text-align: center;
  }

  .headerRight {
    flex: 0 0 (100% / 3);
    text-align: right;
  }

  .errorMessages {
    margin: 0 auto;
    max-width: 46rem;
    font-size: 1.5rem;
  }
  .errorBorder {
    border-color: #cb52a0 !important;
  }
}
