@import '../../../../../../assets/styles/admin/variables.scss';

.container {
  margin-bottom: 3rem;

  label {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0 !important;
    padding: 0;
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 1rem;

    .card {
      cursor: pointer;
      background-color: #f8fafe;
      border-radius: 2px;
      border: 0;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      font-size: 1.4rem;
      font-weight: 200;
      height: 38px;
      padding: 6px 10px;
      transition: border 0.12s;
      line-height: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        color: #859ac2;
      }
    }

    .selected {
      background-color: #e9eef8;
    }

    .disabled {
      background-color: #dce4f4;
      cursor: not-allowed;
    }
  }
}
