@import '../../../assets/styles/admin/variables.scss';
@import '../../../assets/styles/admin/mixins.scss';

section.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  header {
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
  }

  .searchContainer {
    display: flex;
    align-items: center;

    & > div {
      svg {
        margin-top: 0 !important;
      }
    }

    & > span {
      display: flex;
      align-items: center;
      height: 0;
      padding: 1.5rem 2rem;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      box-sizing: border-box;

      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.02rem;
      text-align: center;
      line-height: 3.8rem;

      background: $purple;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      transition: opacity 0.12s;

      &.disabled {
        background-color: #dce4f4;
        border-color: transparent;
        text-shadow: none;
      }

      &.success {
        background-color: $green;
        cursor: default;
      }

      svg {
        margin-right: 1rem;
        margin-top: 0 !important;
      }

      &:last-child {
        margin-left: 1rem;
      }
    }
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }

  .options {
    @include unstyled-button;
    box-sizing: content-box;
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 !important;
    vertical-align: middle;
    color: $gray-blue;

    box-shadow: none;
    transition: opacity 0.14159s;

    &,
    &:focus,
    &:active {
      opacity: 1;
    }

    svg {
      height: 20px;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.actionLink {
  color: #647fb4 !important;
  font-size: 1.4rem;
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem;

  .filter {
    font-size: 14px;

    .filterIcon {
      margin-right: 0.8rem;
      display: inline;
      vertical-align: -0.05rem;
    }
  }

  .searchFieldContainer {
    display: flex;
    align-items: center;

    .newContactButton {
      display: flex;
      align-items: center;
      height: 0;
      padding: 1.5rem 2rem;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      box-sizing: border-box;

      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.02rem;
      text-align: center;
      line-height: 3.8rem;

      background: $purple;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      transition: opacity 0.12s;

      &:hover,
      &:focus {
        outline: 0;
        opacity: 0.75;
      }

      svg {
        color: #ffffff;
        margin-right: 1rem;
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
