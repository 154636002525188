@import '../../assets/styles/admin/variables';
@import '../../assets/styles/admin/mixins';

// Layout
// ----------------------------------------------------------------------------

$header-height: 70px;

.header {
  position: fixed;
  height: $header-height;
  width: 100%;
  top: 0;
  background: $admin-bar-background-color;
  color: $text-color-inverted;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 100;

  &:not(.complete) {
    @include respond-below(sm) {
      .center {
        display: none;
      }
    }
  }

  &.complete {
    background: $success-color;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .left,
  .center,
  .right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .left {
    left: $gutter;
  }

  .center {
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
  }

  .right {
    right: $gutter;
    font-size: 1.4rem;

    a {
      font-weight: 500;
    }
  }

  .sparkLogo {
    height: 4rem;
    width: auto;
    margin: 0;
    display: block;
    transition: opacity 0.14159s;

    &:hover {
      opacity: 0.75;
    }
  }

  .sparkles {
    color: white;
    margin-right: 1rem;
    height: 25px;
    width: auto;
    vertical-align: -4px;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  }

  a.muted {
    color: inherit;
    font-size: 1.5rem;
    opacity: 0.8;
    &:hover {
      opacity: 1 !important;
    }
  }

  .skip {
    @extend a, .muted;
    cursor: pointer;
    white-space: nowrap;

    .cheveron {
      height: 1.2em;
      vertical-align: text-bottom;
      transform: rotateY(180deg);
    }
  }
}

.progressBar {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 100%;

  .progressMade {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background: $success-color;
    border-radius: 0 2px 2px 0;
    box-shadow: 0 0 3px $success-color;
    transition: width 0.3s;
  }
}

.navigateToDashboard {
  a {
    color: #fff !important;
    font-size: 1.4rem;
    font-weight: 400 !important;
    opacity: 0.7;

    svg {
      transform: rotate(180deg);
      color: #fff !important;
      width: 17px;
      height: 17px;
      vertical-align: sub;
    }

    &:hover {
      opacity: 1 !important;
      color: #fff !important;
    }
  }
}
