.container {
  grid-template-columns: 64px 1fr;
  display: grid;
  gap: 32px;

  .thumbnailContainer {
    width: 100%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;

    .thumbnailWrapper {
      display: flex;
      gap: 8px;
      border-radius: 2px;
      overflow: hidden;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .imageGallery {
    overflow: hidden;
    width: 100%;

    .imageContainer {
      position: relative;
      display: flex;
      width: 100%;
      height: fit-content;
      margin-left: auto;
      margin-right: auto;
      z-index: 0;

      > img:not(:first-child) {
        display: none;
      }
    }
  }
}

// MOBILE
@media only screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-flow: row wrap;

    .thumbnailContainer {
      order: 1;

      .thumbnailWrapper {
        flex-direction: row;

        > img {
          overflow: hidden;
        }
      }
    }
  }
}

@media only screen and (min-width: 767px) {
  // FULL
  .container.isFull {
    grid-template-columns: none !important;
    position: relative;
    max-width: calc(50vw - 64px);
    width: calc(50vw - 64px);
    margin: 0;

    .thumbnailContainer {
      position: absolute;
      bottom: 32px;
      height: auto !important;
      width: calc(50vw - 16px);
      z-index: 2;
      order: 2;

      .thumbnailWrapper {
        position: relative;
        width: 50vw;
        max-width: 256px !important;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    .imageGallery {
      overflow: visible;
      .imageContainer {
        position: relative;
        max-width: 50vw;
        width: 50vw;
        margin: 0;
      }
    }
  }

  // GRID + OFFSET + COLLAGE
  .container.isGrid,
  .container.isOffset,
  .container.isCollage {
    grid-template-columns: none !important;
    position: relative;
    max-width: calc(50vw - 64px);
    width: calc(50vw - 64px);
    margin: 0;

    .thumbnailContainer {
      display: none;
    }

    .imageGallery {
      position: relative;
      max-width: 50vw;
      width: 50vw;
      margin: 0;

      .imageContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        position: relative;
        max-width: 50vw;
        width: 50vw;

        > img {
          width: calc(50% - 8px);
          flex-grow: 1;
          display: block;
        }
      }
    }
  }

  // OFFSET
  .container.isOffset {
    .imageGallery {
      .imageContainer {
        > img {
          height: 100%;
        }
        > img:nth-child(2) {
          margin-top: 25%;
        }
        > img:nth-child(odd):not(:first-of-type) {
          margin-top: -25%;
        }
      }
    }
  }

  // COLLAGE
  .container.isCollage {
    .imageGallery {
      .imageContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;

        > img {
          width: 100%;
        }
        > img:nth-of-type(3n + 1) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }

  // LIST
  .container.isList {
    grid-template-columns: none !important;
    width: initial;

    .thumbnailContainer {
      display: none;
    }

    .imageGallery {
      .imageContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        > img {
          height: auto;
          display: block;
        }
      }
    }
  }
}
