.dark-bg {
  color: $dark-bg-text-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  a {
    color: $primary-color;
  }

  #{$text-field-selectors},
  textarea,
  select {
    background-color: rgba(0, 0, 0, 0.15);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.85);
    border-radius: 2px;
    padding: 1rem;
    margin-bottom: -1rem;

    &:focus,
    &.active {
      border-left: 2px solid $primary-color;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &.fieldWithErrors {
      border-color: $dark-bg-danger-color;
    }
  }

  .errorMessage,
  .non_field_errors {
    color: $dark-bg-danger-color;
  }
}
