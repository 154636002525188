@import 'assets/styles/admin/variables';

.header {
  display: flex;
  align-items: center;

  .searchContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  svg {
    margin-top: 0 !important;
  }
}

.dropDownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -20px;
  top: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.05);
  height: 141px;
  width: 180px;
  padding: 8px 0;
  justify-content: space-between;
  border-radius: 4px;

  span {
    padding: 5px 0 5px 30px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: $background-medium;
    }
  }
  .divider {
    width: 90%;
    margin: 0 auto;
    border-bottom: solid thin $light-gray-blue;
  }

  .optionsLoader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
    width: 100%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
